import { useState } from "react";
import "../HeroFeild/EmailSection/emailSection.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ModalDynamic } from "../ModalComponent/ModalComponent";

export const TwitterSection = ({
  top,
  type,
  handleDetail,
  setInputs,
  setDetail,
  detail,
  inputs,
}) => {
  const [typeSelected, setTypeSelected] = useState("static");
  const navigate = useNavigate();
  const location = useLocation();
  const userState = useSelector((state) => state.user);
  const [modalShow1, setModalShow1] = useState(false);

  const { currentUserId } = userState;
  const [twitterOption, setTwitterOption] = useState("post");

  const twitterInputHandler = (e) => {
    handleDetail(e);
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="emailSectionContainer">
      <ModalDynamic
        show={modalShow1}
        description="Please Login QR Sign Up for Dynamic Card QR Code"
        buttonText="LOGIN"
        link="/login"
        onHide={() => setModalShow1(false)}
      />
      {top && (
        <div className="top">
          <div
            className={typeSelected === "static" ? "selected item" : "item"}
            onClick={() => setTypeSelected("static")}
          >
            Static QR Code
          </div>
          <div
            className={typeSelected === "dynamic" ? "selected item" : "item"}
            onClick={() =>
              currentUserId ? navigate("/user/home") : setModalShow1(true)
            }
          >
            Dynamic QR Code{" "}
            <i className="fas fa-crown" style={{ marginLeft: "5px" }}></i>
          </div>
        </div>
      )}
      <div className="formsection">
        <div className="heading">Twitter QR code</div>
        {twitterOption === "link" ? (
          <>
            {location.pathname !== "/" && (
              <div className="inputItem">
                <label htmlFor="name">Name of your QR Code</label>
                <input
                  placeholder="QR Code Name"
                  className="input"
                  type="text"
                  required
                  name="qrName"
                  value={inputs?.qrName || ""}
                  onChange={twitterInputHandler}
                />
              </div>
            )}
            <div className="inputItem">
              <label htmlFor="username">Your username</label>
              <input
                className="input"
                type="text"
                required
                onChange={twitterInputHandler}
                value={inputs?.username || undefined}
                id="username"
                placeholder="@username"
                name="username"
              />
            </div>
          </>
        ) : (
          <div className="inputItem">
            <label htmlFor="message">Content</label>
            <textarea
              className="input"
              type="text"
              required
              maxLength="280"
              id="content"
              onChange={twitterInputHandler}
              value={inputs?.content || undefined}
              name="content"
            />
            {twitterOption === "post" && (
              <h6 className="mt-1">
              {detail?.message
                ? `${detail.message.length} / 200 characters`
                : "0 / 200 characters"}
            </h6>
            )}
          </div>
        )}
        <div className="inputItem">
          <label htmlFor="type">Choose an option</label>
          <div className="radioInput">
            <div
              className="radioInputItem"
              onClick={() => {
                setTwitterOption("link");
                setDetail({ ...detail, twitterOption: "link" });
              }}
            >
              <input
                type="radio"
                id="link"
                name="twitterOption"
                value="link"
                checked={twitterOption === "link" ? true : false}
                onClick={(e) => {
                  twitterInputHandler(e);
                }}
              />
              <label for="link">Link to your profile</label>
            </div>
            <div
              className="radioInputItem"
              onClick={() => {
                setTwitterOption("post");
                setDetail({ ...detail, content: null, twitterOption: "post" });
              }}
            >
              <input
                type="radio"
                id="post"
                name="twitterOption"
                value="post"
                checked={twitterOption === "post" ? true : false}
                onClick={(e) => {
                  twitterInputHandler(e);
                }}
              />
              <label for="post">Post a tweet</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
