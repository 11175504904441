const initialUserState = {
    sideBarSelected: "",
};


const sidebarReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case "SIDE_BAR_SELECTED":
            return {
                ...state,
                sideBarSelected: action.payload,
            };
        default:
            return state;
    }
}

export default sidebarReducer;