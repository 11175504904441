import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "./CouponModal.css";
import { useSelector } from "react-redux";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CloseIcon from "@mui/icons-material/Close";

export const CouponModal = ({
    sale,
    valid,
    show,
    setShow,
    input,
    setRedeem,
}) => {
    const { textColor, buttonColor } = useSelector((state) => state.url);
    const CopyText = () => {
        navigator.clipboard?.writeText(sale);
        toast.success("Coupon copied to clipboard", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    console.log(input);
    function ActivateModal() {
        show ? setShow(false) : setShow(true);
    }

    return (
        <>
            <button
                className="get-coupon-btn"
                onClick={ActivateModal}
                style={{
                    background:
                        buttonColor?.button ||
                        input?.button ||
                        "#ffffff !important",
                    color: textColor?.text || input?.colorPrimary || "#388aa2",
                }}
            >
                GET COUPON
            </button>

            {show && (
                <div className="couponModal">
                    <div className="top">
                        <div className="Cheading">
                            Coupon Code
                            <span
                                className="float-right"
                                onClick={ActivateModal}
                            >
                                <CloseIcon />
                            </span>
                        </div>
                        <div
                            className="discount"
                            style={{
                                color: "#000000",
                            }}
                        >{`Flat ${sale} Off`}</div>
                        <div className="duration">
                            <ScheduleIcon style={{ color: "#7c858c" }} />
                            {` Valid Until ${valid}`}
                        </div>
                        <div>
                            <button
                                className="copyBtn-Coupon"
                                onClick={CopyText}
                            >
                                Copy Code
                            </button>
                        </div>
                    </div>
                    <div
                        className="py-5"
                        style={{ borderTop: "1px solid rgb(18 18 18 / 33%)" }}
                    >
                        <Button
                            className="bottom-Cbtn"
                            style={{
                                backgroundColor:
                                    buttonColor?.button ||
                                    input?.button ||
                                    "#fff",
                                color:
                                    textColor?.text ||
                                    input?.colorPrimary ||
                                    "#388aa2",
                            }}
                            onClick={() => {
                                setShow(false);
                                setRedeem(true);
                            }}
                        >
                            {input?.webText}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
