import {useEffect,  useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "./flow.scss";
import v1 from "../../asset/v1.png";
import v2 from "../../asset/v2.png";
import v3 from "../../asset/v3.png";
import v4 from "../../asset/v4.png";
import v5 from "../../asset/v5.png";
import flw from "../../asset/flowright.png";
import { useNavigate } from "react-router-dom";
import ScrollTrigger from "react-scroll-trigger";
import { useLocation } from "react-router-dom";

export const Flow = () => {
    const [color, setColor] = useState("arrow-color-gray");
    const [color2, setColor2] = useState("arrow-color-gray2");
    const [circleColor, setCircleColor] = useState("circle-color-white");
    const [circleColor2, setCircleColor2] = useState("circle-color-white2");
    const [circleColor3, setCircleColor3] = useState("circle-color-white2");
    const location = useLocation();
    const [title, setTitle] = useState("");

    useEffect(() => {
        if (location.pathname === "/qr-code-dynamic") {
            setTitle("How to Generate dynamic QR Code?");
        } else {
            setTitle("How to Generate free QR Code?");
        }
    }, [location]);

    const onEnterViewport = () => {
        setCircleColor("circle-color-green");
    };
    const onExitViewport = () => {
        setCircleColor("circle-color-white");
    };
    const onEnterViewport2 = () => {
        setCircleColor2("circle-color-green2");
    };
    const onExitViewport2 = () => {
        setCircleColor2("circle-color-white2");
    };
    const onEnterViewport3 = () => {
        setCircleColor3("circle-color-green3");
    };
    const onExitViewport3 = () => {
        setCircleColor3("circle-color-white3");
    };

    const onEnterViewport4 = () => {
        setColor("arrow-color-green");
    };
    const onExitViewport4 = () => {
        setColor("arrow-color-gray");
    };
    const onEnterViewport5 = () => {
        setColor2("arrow-color-green2");
    };
    const onExitViewport5 = () => {
        setColor2("arrow-color-gray2");
    };

    const navigate = useNavigate();

    return (
        <div className="flowContainer" id="howitwork">
            <div className="flowWrapper">
                <div className="top">
                    <h2 className="heading">{title}</h2>
                    <div className="desc">
                        Learn with the following easy steps:
                    </div>
                </div>
                <div className="cardContainer">
                    <div className="flow-cardContainer-wrapper">
                        <ScrollTrigger
                            throttleScroll={200}
                            onEnter={onEnterViewport}
                            onExit={onExitViewport}
                        >
                            <div className={`flow-circle1 ${circleColor}`}>
                                1
                            </div>
                        </ScrollTrigger>

                        <div className="flow-cardContainer-left">
                            <h4 className="flowh4">Select QR type</h4>
                            <p className="flowp">
                                To create a QR code, first, select the QR code
                                type that you want to create. There are four
                                types of QR codes: URL, contact information,
                                text, and email
                            </p>
                        </div>
                        <div className="flow-cardContainer-right flow-bg">
                            <span className="flowspan flowone">
                                <img  loading="lazy"   className="flowimg" src={v1} alt="" /> URL
                                / Website
                            </span>
                            <span className="flowspan">
                                <img  loading="lazy"   className="flowimg" src={v2} alt="" /> Text
                            </span>
                            <span className="flowspan">
                                <img  loading="lazy"   className="flowimg" src={v3} alt="" />{" "}
                                VCard
                            </span>
                            <span className="flowspan">
                                <img  loading="lazy"   className="flowimg" src={v4} alt="" />{" "}
                                Email
                            </span>
                            <span className="flowspan">
                                <img  loading="lazy"   className="flowimg" src={v5} alt="" /> SMS
                            </span>
                        </div>
                    </div>
                    <ScrollTrigger
                        throttleScroll={1000}
                        onEnter={onEnterViewport4}
                        onExit={onExitViewport4}
                    >
                        <div
                            className={color}
                            style={{
                                height: "90px",
                                width: "4px",
                                backgroundColor: "lightgray",
                                position: "relative",
                                zIndex: 1,
                            }}
                        >
                            <span
                                className={color}
                                style={{
                                    position: "absolute",
                                    bottom: "-0.2px",
                                    right: "-6px",
                                    zIndex: 0,
                                    width: "4px",
                                    backgroundColor: "lightgray",
                                    height: "15px",
                                    transform: "rotate(45deg)",
                                }}
                            />
                            <span
                                className={color}
                                style={{
                                    position: "absolute",
                                    bottom: "-0.2px",
                                    zIndex: 0,
                                    left: "-6px",
                                    width: "4px",
                                    backgroundColor: "lightgray",
                                    height: "15px",
                                    transform: "rotate(-45deg)",
                                }}
                            />
                        </div>
                    </ScrollTrigger>
                    <div
                        className="flow-cardContainer-wrapper"
                        style={{ padding: "10px" }}
                    >
                        <ScrollTrigger
                            throttleScroll={200}
                            onEnter={onEnterViewport2}
                            onExit={onExitViewport2}
                        >
                            <div className={`flow-circle2 ${circleColor2}`}>
                                2
                            </div>
                        </ScrollTrigger>
                        <div
                            style={{ padding: "20px 10px" }}
                            className="flow-cardContainer-left flow-other flow-bg"
                        >
                            <div className="flow-cardContainer-left-top">
                                <h4 className="flowh4 flow-otherh4">
                                    Enter your website URL
                                </h4>
                                <p className="flowp">
                                    Next, enter the website details you want to
                                    encode into the QR code design
                                </p>
                            </div>

                            <p>
                                <span style={{ color: "#58A697" }}>
                                    <ArrowUpwardIcon />
                                    Upload any file
                                </span>
                                (.jpg, .mp3, .pdf etc)
                            </p>
                        </div>
                        <div
                            style={{ width: "40%" }}
                            className="flow-cardContainer-right flow-rightother"
                        >
                            <div className="flow-otherdiv">
                                <h4 className="flowh4 flow-otherh4">
                                    Enter Details
                                </h4>
                                <p className="flowp">
                                    Fill in all of the required information in
                                    the fields that display. Select "Generate"
                                    after you're finished.
                                </p>
                            </div>
                        </div>
                    </div>
                    <ScrollTrigger
                        throttleScroll={1000}
                        onEnter={onEnterViewport5}
                        onExit={onExitViewport5}
                    >
                        <div
                            className={color2}
                            style={{
                                height: "90px",
                                width: "4px",
                                backgroundColor: "lightgray",
                                position: "relative",
                                zIndex: 1,
                            }}
                        >
                            <span
                                className={color2}
                                style={{
                                    position: "absolute",
                                    bottom: "-0.2px",
                                    right: "-6px",
                                    zIndex: 0,
                                    width: "4px",
                                    backgroundColor: "lightgray",
                                    height: "15px",
                                    transform: "rotate(45deg)",
                                }}
                            />
                            <span
                                className={color2}
                                style={{
                                    position: "absolute",
                                    bottom: "-0.2px",
                                    zIndex: 0,
                                    left: "-6px",
                                    width: "4px",
                                    backgroundColor: "lightgray",
                                    height: "15px",
                                    transform: "rotate(-45deg)",
                                }}
                            />
                        </div>
                    </ScrollTrigger>
                    <div className="flow-cardContainer-wrapper">
                        <ScrollTrigger
                            throttleScroll={200}
                            onEnter={onEnterViewport3}
                            onExit={onExitViewport3}
                        >
                            <div className={`flow-circle2 ${circleColor3}`}>
                                3
                            </div>
                        </ScrollTrigger>
                        <div className="flow-cardContainer-left">
                            <h4 className="flowh4">Download the QR Code</h4>
                            <p className="flowp">
                                Finally, download your QR code
                            </p>
                        </div>
                        <div
                            className="flow-cardContainer-right"
                            style={{ width: "50%", marginRight: "-3em" }}
                        >
                            <img  loading="lazy"   src={flw} alt="flw " width="100%" />
                        </div>
                    </div>
                </div>
                <button
                    style={{
                        height: "76px !important",
                        width: "291px !important",
                    }}
                    id="createNowHome"
                    onClick={() => navigate("/signUp")}
                >
                    Create Now
                </button>
            </div>
        </div>
    );
};
