import { useState, useEffect } from "react";
import ShareIcon from "@mui/icons-material/Share";
import { SocialIcon } from "react-social-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import CardModal from "../../../Component/CardModal/CardModal";
import Message from "../../../asset/messageIco.png";
import "./ShareModal.scss";

export const ShareModal = ({ state}) => {
const [show, setShow] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const url = window.location.href;
  const handleClose = () => {
    setShow(false);
    setIsCopied(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (state) {
      setShow(true);
    }
  }, [state]);

  const ModalBody = (
    <div className="shareModal">
      <div className="Sharetitle text-center">
       Share Page</div>
       <hr className="share-modal-hr" />
      <div className="optionContainer">
        <div className="option">
          <FacebookShareButton
            url={url}
            quote={"フェイスブックはタイトルが付けれるようです"}
            // hashtag={"#hashtag"}
            description={"test"}
          >
            <SocialIcon network="facebook" className="socialIcon" /> Facebook
          </FacebookShareButton>
        </div>
        <div className="option">
          <TwitterShareButton title={"test"} url={url}>
            <SocialIcon network="twitter" className="socialIcon" /> Twitter
          </TwitterShareButton>
        </div>
        <div className="option">
          <EmailShareButton url={url} subject="test" body={`test body" ${url}`}>
            <SocialIcon network="email" className="socialIcon" /> Email
          </EmailShareButton>
        </div>
        <div className="option">
          <img  loading="lazy"   src={Message} alt="message" className="socialIcon" />
          <input value={url} className="messageInput" type="text" />
        </div>
      </div>
      <div className="copyButton">
        <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
          <button disabled={isCopied}>{isCopied ? "Copied" : "Copy"}</button>
        </CopyToClipboard>
      </div>
    </div>
  );

  return (
    <>
      <ShareIcon className="shareIcon" onClick={handleShow} />

      <CardModal show={show} onHide={handleClose} data={ModalBody} />

      {/* <Modal show={show} onHide={handleClose} size="sm">
        <div className="shareModal">
          <Modal.Header closeButton>
            <Modal.Title>Share Page</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="optionContainer">
              <div className="option">
                <FacebookShareButton
                  url={url}
                  quote={"フェイスブックはタイトルが付けれるようです"}
                  // hashtag={"#hashtag"}
                  description={"test"}
                >
                  <SocialIcon network="facebook" className="socialIcon" />{" "}
                  Facebook
                </FacebookShareButton>
              </div>
              <div className="option">
                <TwitterShareButton title={"test"} url={url}>
                  <SocialIcon network="twitter" className="socialIcon" />{" "}
                  Twitter
                </TwitterShareButton>
              </div>
              <div className="option">
                <EmailShareButton
                  url={url}
                  subject="test"
                  body={`test body" ${url}`}
                >
                  <SocialIcon network="email" className="socialIcon" /> Email
                </EmailShareButton>
              </div>
            </div>
            <div className="copyButton">
              <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                <button disabled={isCopied}>
                  {isCopied ? "Copied" : "Copy"}
                </button>
              </CopyToClipboard>
            </div>
          </Modal.Body>
        </div>
      </Modal> */}
    </>
  );
};
