import "./DynamicAndStatic.scss";
import { useNavigate } from "react-router-dom";
import dynamic from "../../asset/dynamic.png";
import staticI from "../../asset/static.png";

const data = [
    {
        heading: "Dynamic QR Code",
        icon: dynamic,
        points: [
            "Allows changing URL limitlessly.",
            "Allows quick scanning.",
            "Trackable scan performance.",
            "Short and neat URL.",
            "Great for multiple marketing campaigns.",
            "Password-protected and highly secure ",
            "Easy to customize",
            "Can be made to expire",
        ],
    },
    {
        heading: "Static QR Code",
        icon: staticI,
        points: [
            "Fixed, static URL which can not be changed",
            "Takes more time to scan",
            "Scan performance is not trackab",
            "Bulky and long URL",
            "Not suitable for multiple marketing campaigns",
            "Can be used with any device type",
            "Do not require a special app or software",
            "Easy and free to create",
        ],
    },
];

export const DynamicAndStatic = () => {
    const navigate = useNavigate();
    return (
        <div className="dynamicAndStaticContainer">
            <div className="dynamicAndStaticWrapper">
                <div>
                    <div className="dynamicAndStaticheading">
                        Dynamic Vs. Static QR Codes
                    </div>
                </div>

                <div className="dynamicAndStaticcard-container">
                    {data.map((item) => {
                        return (
                            <div
                                key={item?.heading}
                                onClick={() =>
                                    navigate("/qr-code-solution-card")
                                }
                                className="dynamicAndStaticcard-item"
                            >
                                <h4
                                    className="dynamicAndStatich4"
                                    style={
                                        item.heading === "Dynamic QR Code"
                                            ? { backgroundColor: "#58A697" }
                                            : { backgroundColor: "#DB9D48" }
                                    }
                                >
                                    {item?.heading}
                                </h4>
                                <div className="dynamicAndStaticcard-iconContainer">
                                    <img  loading="lazy"  
                                        className="dynamicAndStaticimg"
                                        src={item.icon}
                                        alt="Quick Dynamic QR Codes"
                                    />
                                </div>
                                <ul className="dynamicAndStaticul">
                                    {item.points.map((point) => {
                                        return (
                                            <li
                                                key={point}
                                                className="dynamicAndStaticli"
                                            >
                                                {point}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
                <button
                    className="dynamicAndStaticbutton"
                    id="tryItNowHome"
                    onClick={() => navigate("/signUp")}
                >
                    Try It Now
                </button>
            </div>
        </div>
    );
};
