import { useState } from "react";
import "../../../style/user/Create/CardDesign/CardDesign.scss";
import ColorIco from "../../../asset/colorIco.svg";
import { useLocation } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ColorPicker } from "../../../Component/ColorPicker/ColorPicker";
import ColorPlates from "../../../Component/ColorPicker/ColorPlates";
import { useSelector } from "react-redux";
import UploadImage from "./UploadImage/UploadImage";
export const CardDesign = (prop) => {
    const { dynamicColor, textColor, buttonColor } = useSelector(
        (state) => state.url
    );
    const location = useLocation();
    const [colorPrimary, setColorPrimary] = useState("#000000");
    const [colorSecondary, setColorSecondary] = useState("#000000");

    let activeUpload = prop.uploadIMG ? false : true;
    const checkFeedback = location?.pathname?.slice(-15) !== "feedbackDynamic";
    const checkVideo = location?.pathname?.slice(-12) !== "videoDynamic";
    const checkImage = location?.pathname?.slice(-12) !== "imageDynamic";

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className="heading m-0">
                    <img  loading="lazy"  
                        src={ColorIco}
                        alt="Cicon"
                        width="20px"
                        height="20px"
                    />
                    <span className="headtag">Design & Customizations</span>
                </div>
            </AccordionSummary>
            <hr />
            <AccordionDetails>
                <div className="dynamicCreateContainer">
                    <div className="dynamicCreateWrapper">
                        <div className="mainContainer">
                            <div className="mainLeft">
                                <div className="boxContainer">
                                    <div className="box2">
                                        <div className="title">Colors</div>
                                        <div className="optionContainer">
                                            <div className="option">
                                                <div className="colorOption">
                                                    <ColorPlates
                                                        setColorPrimary={
                                                            setColorPrimary
                                                        }
                                                        setColorSecondary={
                                                            setColorSecondary
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between color-container px-2 pb-5">
                                                <div className="d-flex flex-column field-div">
                                                    <h4 className="colorTitle">
                                                        Primary
                                                    </h4>
                                                    <ColorPicker
                                                        setColorPrimary={
                                                            setColorPrimary
                                                        }
                                                        colorPrimary={
                                                            colorPrimary
                                                        }
                                                        setColorSecondary={
                                                            setColorSecondary
                                                        }
                                                        colorSecondary={
                                                            colorSecondary
                                                        }
                                                        value={
                                                            dynamicColor?.color1
                                                        }
                                                        name="color1"
                                                    />
                                                </div>
                                                <div className="d-flex flex-column field-div">
                                                    <h4 className="colorTitle">
                                                        Secondary
                                                    </h4>
                                                    <ColorPicker
                                                        setColorPrimary={
                                                            setColorPrimary
                                                        }
                                                        colorPrimary={
                                                            colorPrimary
                                                        }
                                                        setColorSecondary={
                                                            setColorSecondary
                                                        }
                                                        colorSecondary={
                                                            colorSecondary
                                                        }
                                                        value={
                                                            dynamicColor?.color2
                                                        }
                                                        name="color2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between color-container px-2 pb-5">
                                                <div className="d-flex flex-column field-div">
                                                    <h4 className="colorTitle">
                                                        Button Color
                                                    </h4>
                                                    <ColorPicker
                                                        value={
                                                            buttonColor?.button
                                                        }
                                                        name="button"
                                                    />
                                                </div>
                                                <div className="d-flex flex-column field-div">
                                                    <h4 className="colorTitle">
                                                        Text Color
                                                    </h4>
                                                    <ColorPicker
                                                        value={textColor?.text}
                                                        name="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {activeUpload
                                        ? checkFeedback &&
                                        checkVideo &&
                                        checkImage && (
                                            <div>
                                                <UploadImage />
                                            </div>
                                        )
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
