import "./StaticCard.scss";
import arrowright from "./image/arrowright.png";
import Keyboard from "./image/keyboard.png";

export const Sms = ({ heading, number, message, subject, inputs }) => {
  return (
    // <div className="staticCardContainer">
    //   <div className="staticCardWrapper">
    //     <div className="staticCardTop">{heading} Send SMS</div>
    //     <div className="infoContainer">
    //       <div className="infoItem">
    //         <div className="label">Number:</div>
    //         <div className="info">
    //           {!inputs?.mobileNumber
    //             ? "8505018618"
    //             : inputs?.mobileNumber}
    //         </div>
    //       </div>
    //       <div className="infoItem">
    //         <div className="label">Message</div>
    //         {/* <div className="info">8505018618</div> */}
    //       </div>
    //     </div>
    //     <div className="restContainer">
    //       {!inputs?.description
    //         ? "Type the message here"
    //         : inputs?.description}
    //     </div>
    //   </div>
    // </div>

    <>
      <div className="sms-container">
        <div className="box">
          <div className="header-box">
            <div className="sms-bg">
              <h3 className="sendSms">Send Sms</h3>
            </div>
            <div className="number-bg">
              <h5 className="num-head ">Number:</h5>
              <p className="number-digit px-2">
                {!inputs?.mobileNumber ? "8505018618" : inputs?.mobileNumber}
              </p>
            </div>
            {/* <div className="to-bg">
              <h5 className="to">To:</h5>
              <p className="name px-2">Rohit</p>
            </div> */}
            <hr className="line" />
            <div className="sms-section">
              <p className="sms">
                {!inputs?.description
                  ? "Text to QR Codes can be scanned and read by any QR Code scanning device and open as text-only files with the help of any application. It does not require an internet connection to work.QR Code for text enables its users to display simple words,digits, and special characters. Text to QR code is a basic facilitator that requires no internet connection to scan andwork."
                  : inputs?.description}
              </p>
              <div className="typing-input">
                <input
                  type="text"
                  className="input-text"
                  placeholder="connection to scan and work."
                />
                <img  loading="lazy"   src={arrowright} className="arrowright" alt="arrow" />
              </div>
            </div>
          </div>

          <div>
            <img  loading="lazy"   className="keyboard" src={Keyboard} alt="keyboard" />
          </div>
        </div>
      </div>
    </>
  );
};
