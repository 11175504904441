import React from "react";
import "./PageLoader.css";
import Loader from "react-js-loader";

const PageLoader = () => {
    return (
        <div className="c-header">
            <Loader
                type="spinner-default"
                bgColor={"#db9d48"}
                color={"#db9d48"}
                size={100}
            />
        </div>
    );
};

export default PageLoader;
