import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Suspense } from "react";
import PageLoader from "../PageLoader/PageLoader";

const Protected = () => {
    const userId = localStorage.getItem("userId");
    const location = useLocation();
    const { currentUserId, loggedBy } = useSelector((state) => state.user);

    return currentUserId || loggedBy || userId ? (
        <Suspense fallback={<PageLoader />}>
            <Outlet />
        </Suspense>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default Protected;
