import { useState } from "react";
import "../HeroFeild/EmailSection/emailSection.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ModalDynamic } from "../ModalComponent/ModalComponent";

export const WifiSection = ({
    top,
    type,
    handleDetail,
    setInputs,
    inputs,
    setDetail,
    detail,
}) => {
    const [typeSelected, setTypeSelected] = useState("static");
    const navigate = useNavigate();
    const location = useLocation();
    const userState = useSelector((state) => state.user);
    const { currentUserId } = userState;
    const [modalShow1, setModalShow1] = useState(false);


    const wifiInputHandler = (e) => {
        handleDetail(e);
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <div className="emailSectionContainer">
            <ModalDynamic
                show={modalShow1}
                description="Please Login QR Sign Up for Dynamic Card QR Code"
                buttonText="LOGIN"
                link="/login"
                onHide={() => setModalShow1(false)}
            />
            {top && (
                <div className="top">
                    <div
                        className={
                            typeSelected === "static" ? "selected item" : "item"
                        }
                        onClick={() => setTypeSelected("static")}
                    >
                        Static QR Code
                    </div>
                    <div
                        className={
                            typeSelected === "dynamic"
                                ? "selected item"
                                : "item"
                        }
                        onClick={() =>
                            currentUserId
                                ? navigate("/user/home")
                                : setModalShow1(true)
                        }
                    >
                        Dynamic QR Code{" "}
                        <i
                            className="fas fa-crown"
                            style={{ marginLeft: "5px" }}
                        ></i>
                    </div>
                </div>
            )}
            <div className="formsection">
                <div className="heading">Wifi QR code</div>
                {location.pathname !== "/" && (
                    <div className="inputItem">
                        <label htmlFor="name">Name of your QR Code</label>
                        <input
                            placeholder="QR Code name"
                            className="input"
                            type="text"
                            required
                            name="qrName"
                            value={inputs?.qrName || ""}
                            onChange={wifiInputHandler}
                        />
                    </div>
                )}
                <div className="inputItem">
                    <label htmlFor="network">Network Name</label>
                    <input
                        className="input"
                        onChange={wifiInputHandler}
                        value={inputs?.networkName}
                        type="text"
                        required
                        id="network"
                        placeholder="SSID"
                        name="networkName"
                    />
                </div>
                <div className="inputItem">
                    <label htmlFor="password">Password</label>
                    <input
                        className="input"
                        onChange={wifiInputHandler}
                        value={inputs?.password}
                        type="password"
                        required
                        id="password"
                        placeholder="Your Password"
                        name="password"
                    />
                </div>
                <div className="inputItem">
                    <label htmlFor="encryption_type">Encryption Type</label>
                    <div className="radioInput">
                        <div className="radioInputItem">
                            <input
                                onChange={wifiInputHandler}
                                type="radio"
                                id="none"
                                name="encType"
                                value="none"
                            />
                            <label for="none">None</label>
                        </div>
                        <div className="radioInputItem">
                            <input
                                onChange={wifiInputHandler}
                                type="radio"
                                id="WPA/WPA2"
                                name="encType"
                                value="WPA"
                            />
                            <label for="WPA/WPA2">WPA/WPA2</label>
                        </div>
                        <div className="radioInputItem">
                            <input
                                onChange={wifiInputHandler}
                                type="radio"
                                id="WEP"
                                name="encType"
                                value="WEP"
                            />
                            <label for="WEP">WEP</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
