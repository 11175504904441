import "./cardModal.css";
import CloseIcon from "@mui/icons-material/Close";

const Body = ({ close, dataBody, closeIcon }) => {
  return (
    <div>
      {closeIcon === false ? (
        ""
      ) : (
        <div className="CcloseIcon" onClick={close}>
          <CloseIcon />
        </div>
      )}
      <div className="modalBody">{dataBody}</div>
    </div>
  );
};

const CardModal = (props) => {
  return (
    <div
      className="modalMain"
      style={{ display: props.show ? "block" : "none" }}
    >
      <Body
        close={props.onHide}
        dataBody={props.data}
        closeIcon={props.closeIcon}
      />
    </div>
  );
};

export default CardModal;
