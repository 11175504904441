import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "../src/style/toast.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { Suspense } from "react";
import PageLoader from "./Component/PageLoader/PageLoader";
import { Helmet } from "react-helmet";

const clientId = process.env.REACT_APP_CLIENT_ID_TWO;

ReactDOM.render(
  <GoogleOAuthProvider clientId={clientId}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Suspense fallback={<PageLoader />}>
            <Helmet>
              <link rel="icon" type="image/x-icon" href="./asset/favicon.ico" />
            </Helmet>
            <App />
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
