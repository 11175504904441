const __DEV__ = document.domain === "localhost";

const initialState = {
  url: "",
  qr: [],
  cards: [],
  id: "",
  folderName: "",
  imgURL: "",
  pdfURL: "",
  color: "",
  dynamicColor: null,
  textColor: null,
  backgroundColor: null,
  buttonColor: null,
  links: "",
  time: [],
  social: [],
  feedback: [],
  ratingStyle: "",
  featuresData: [],
};

const cardsURL = (state = initialState, action) => {
  switch (action.type) {
    case "QRURL":
      return {
        ...state,
        url: __DEV__
          ? `http://localhost:3000/card/${action.payload}`
          : process.env.REACT_APP_BASE_URL + "" + action.payload,
      };
    case "EDITQR":
      return {
        ...state,
        id: action.payload,
      };
    case "QRURLDESIGN":
      return {
        ...state,
        url: __DEV__
          ? process.env.REACT_APP_CARD_URL + "qr/" + action.payload
          : process.env.REACT_APP_CARD_URL + "qr/" + action.payload,
      };
    case "FILTER_BY_FOLDER":
      return {
        ...state,
        folderName: action.payload,
      };
    case "IMAGE_URL":
      return {
        ...state,
        imgURL: action.payload,
      };
    case "Links":
      return {
        ...state,
        links: action.payload,
      };
    case "PDF_URL":
      return {
        ...state,
        pdfURL: action.payload,
      };
    case "COLOR_SET":
      return {
        ...state,
        color: action.payload,
      };
    case "BUTTON_COLOR_SET":
      return {
        ...state,
        buttonColor: action.payload,
      };
    case "SET_COLOR_BG":
      return {
        ...state,
        dynamicColor: action.payload,
      };
    case "SET_COLOR_DYNAMIC":
      return {
        ...state,
        dynamicColor: action.payload,
      };
    case "SET_COLOR_BACKGROUND":
      return {
        ...state,
        backgroundColor: action.payload,
      };
    case "SET_COLOR_TEXT":
      return {
        ...state,
        textColor: action.payload,
      };
    case "SET_COLOR_BUTTON":
      return {
        ...state,
        buttonColor: action.payload,
      };
    case "SHOW_ALL_CARDS":
      return {
        ...state,
        qr: action.payload,
      };

    case "REMOVE_FROM_HOME": {
      const index = state.qr.findIndex((item) => item.id === action.payload);
      state.qr = state.qr.filter((_, i) => i !== index);
      return;
    }
    case "TIME_DATA": {
      return {
        ...state,
        time: state.time.concat([action.payload]).flat(),
      };
    }
    case "TIME_DATA_REMOVE": {
      return {
        ...state,
        time: state.time.filter((item) => item.day !== action.payload),
      };
    }
    case "TIME_DATA_UPDATE": {
      const updateDate = state.time.map((item) => {
        if (item.day === action.payload.id) {
          item.closeTime = action.payload.myTime.closeTime;
          item.openTime = action.payload.myTime.openTime;
        }
        return item;
      });
      return {
        ...state,
        time: updateDate,
      };
    }
    case "SOCIAL_DATA": {
      return {
        ...state,
        social: action.payload,
      };
    }
    case "FEEDBACK_DATA": {
      return {
        ...state,
        feedback: action.payload,
      };
    }

    case "SOCIAL_DATA_UPDATE": {
      const updateSocial = state.social.map((item) => {
        if (item.name === action.payload.social) {
          item.url = action.payload.url;
        }
        return item;
      });
      return {
        ...state,
        social: updateSocial,
      };
    }
    case "FEEDBACK_DATA_UPDATE": {
      const feedBackData = state.feedback.map((item) => {
        if (item.cId === action.payload.id) {
          item.link = action.payload.link;
        }
        return item;
      });
      return {
        ...state,
        feedback: feedBackData,
      };
    }
    case "SET_RATING_STYLE": {
      return {
        ...state,
        ratingStyle: action.payload,
      };
    }
    case "SET_FEATURES": {
      return {
        ...state,
        featuresData: action.payload,
      };
    }
    case "CLEAR_DATA_QR": {
      return {
        ...state,
        qr: [],
        cards: [],
        folderName: "",
        color: "",
        dynamicColor: null,
        textColor: null,
        backgroundColor: null,
        buttonColor: null,
        time: [],
        social: [],
        feedback: [],
        ratingStyle: "",
      };
    }

    default:
      return state;
  }
};

export default cardsURL;
