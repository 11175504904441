import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { publicRequest } from "../requestMethod";
import { Apps } from "./DynamicCard/Apps/Apps";
import { Business } from "./DynamicCard/Business/Business";
import Coupon from "./DynamicCard/Coupon/Coupon";
import { Event } from "./DynamicCard/Event/Event";
import { Facebook } from "./DynamicCard/Facebook/Facebook";
import { Feedback } from "./DynamicCard/Feedback/Feedback";
import { Images } from "./DynamicCard/Images/Images";
import { Mp4 } from "./DynamicCard/Mp4/Mp4";
import { Pdf } from "./DynamicCard/Pdf/Pdf";
import { Rating } from "./DynamicCard/Rating/Rating";
import { SocialMedia } from "./DynamicCard/SocialMedia/SocialMedia";
import Vcard from "./DynamicCard/Vcard/Vcard";
import { Video } from "./DynamicCard/Video/Video";
import { Email } from "./StaticCard/Email/Email";
import { Sms } from "./StaticCard/Sms";
import { Text } from "./StaticCard/Text";
import { Twitter } from "./StaticCard/TwitterCard";
import { UrlStatic } from "./StaticCard/UrlStatic";
import { VcardStatic } from "./StaticCard/VcardStatic";
import { Wifi } from "./StaticCard/Wifi";

export const Card = () => {
    const params = useParams();
    const qrId = params.qrId;
    const [qr, setQr] = useState({});
    useEffect(() => {
        const fetchQr = async () => {
            const res = await publicRequest("/qr/" + qrId);
            setQr(res.data.qr);
            const baseUrl = process.env.REACT_APP_BACKEND_URL;
            const url = baseUrl + "" + "/qr/" + "" + qrId;
            const data = {
                location: "delhi",
                scan: res?.data?.qr?.qrType.includes("Static") ? false : true,
            };
            data.scan && axios.patch(url, data).then((res) => {});
        };
        fetchQr();
    }, []);

    return (
        <div
            className={`CardContainer ${
                qr?.qrType === "facebookDynamic" &&
                "d-flex justify-content-center"
            }`}
        >
            {qr.qrType === "urlStatic" && <UrlStatic inputs={qr} />}
            {qr.qrType === "smsStatic" && <Sms inputs={qr} />}
            {qr.qrType === "wifiStatic" && <Wifi inputs={qr} />}
            {qr.qrType === "emailStatic" && <Email inputs={qr} />}
            {qr.qrType === "textStatic" && <Text inputs={qr} />}
            {qr.qrType === "twitterStatic" && <Twitter inputs={qr} />}
            {qr?.qrType === "vcardStatic" && <VcardStatic inputs={qr} />}

            {qr?.qrType === "vcardDynamic" && <Vcard inputs={qr} />}
            {qr?.qrType === "facebookDynamic" && <Facebook inputs={qr} />}
            {qr?.qrType === "pdfDynamic" && <Pdf inputs={qr} />}
            {qr?.qrType === "videoDynamic" && <Video inputs={qr} />}
            {qr?.qrType === "appDynamic" && <Apps inputs={qr} />}
            {qr?.qrType === "imageDynamic" && <Images inputs={qr} />}
            {qr?.qrType === "couponDynamic" && <Coupon inputs={qr} />}
            {qr?.qrType === "mp4Dynamic" && <Mp4 inputs={qr} />}
            {qr?.qrType === "businessDynamic" && <Business inputs={qr} />}
            {qr?.qrType === "ratingDynamic" && <Rating inputs={qr} />}
            {qr?.qrType === "socialMediaDynamic" && <SocialMedia inputs={qr} />}
            {qr?.qrType === "feedbackDynamic" && <Feedback inputs={qr} />}
            {qr?.qrType === "eventDynamic" && <Event inputs={qr} />}
        </div>
    );
};
