import Cross from "../../../asset/dynamicCard/emailCardCross.png";
import { BiPaperclip } from "react-icons/bi";
import { RiSendPlane2Fill } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import { MdKeyboardArrowDown, MdEmail } from "react-icons/md";
import "./Email.css";

export const Email = ({ inputs }) => {
  return (
    <div className="emailCardContainer">
      <div className="emailCardWrapper">
        <div className="email-main-content">
          <div className="email-top-card-icon">
            <MdEmail className="email-top-icon" />
          </div>
          <div className="utility-icons">
            <img  loading="lazy"   src={Cross} className="email-cancel-icon" />
            <BiPaperclip className="email-clip-icon" />
            <RiSendPlane2Fill className="email-send-icon" />
            <BsThreeDots className="email-dot-icon" />
            <hr />
          </div>
          <div className="email-recipient">
            <p>
              {!inputs?.emailSubject
                ? "OwenDen@chip.com"
                : inputs?.emailSubject}
            </p>
            <hr className="partition-line" />
          </div>
          <div className="sender-email">
            <p>
              From:
              {!inputs?.email ? "OwenDen@chip.com" : inputs?.email}
              <MdKeyboardArrowDown className="email-down-arrow" />
            </p>
            <hr className="partition-line" />
          </div>
          <div className="email-description-content">
            <p>
              {!inputs?.message
                ? "Text to QR Codes can be scanned and read by any QR Code scanning device and open as text-only files with the help of any application. It does not require an internet connection to work. QR Code for text enables its users to display simple words, digits, and special characters. Text to QR code is a basic facilitator that requires no internet connection to scan and work."
                : inputs?.message}
            </p>
            <hr className="partition-line" />
          </div>
          <div className="email-sent-button">
            <a href="" className="send-link">
              Send
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
