import { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "./navbar.scss";
import logo1 from "../../asset/logo.png";
import logoCanvas from "../../asset/logo canvas.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Accordion } from "react-bootstrap";

export const Navbar = ({ subHead }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [path, setPath] = useState("/");
  const [show, setShow] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-wrapper">
          <div className="navbar-left">
            <p
              onClick={() => window.location.replace("/")}
              className="text-link mb-0"
            >
              <img  loading="lazy"   src={logo1} alt="." />
            </p>
          </div>
          <div className="navbar-right">
            <div style={{ display: "flex" }} className="header-options">
              <div id="home_menu" className={`nav-item ${path === "/" && "active-link-nav"}`}>
                {" "}
                <p
                  onClick={() => window.location.replace("/")}
                  className="text-link mb-0"
                >
                  Home
                </p>{" "}
              </div>
              <div className={`nav-item2`}>
                {" "}
                <span
                  to="/products"
                  className="text-link product-nav-link"
                  onClick={() => setShow(!show)}
                >
                  Products
                  <KeyboardArrowDownIcon />
                  <div
                    className={
                      show
                        ? "product-nav-item-container2"
                        : "product-nav-item-container"
                    }
                  >
                    <ul className="product-nav-list-container">
                      <p
                        id="begginer_menu"
                        onClick={() =>
                          window.location.replace("/qr-code-beginners")
                        }
                        className="text-link"
                      >
                        <li
                          className={`product-nav-list-item ${path === "/qr-code-beginners" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Beginner's Guide</a>
                        </li>
                      </p>
                      <p
                        id="business_card_menu"
                        onClick={() =>
                          window.location.replace("/qr-code-business-card")
                        }
                        className="text-link"
                      >
                        <li
                          className={`product-nav-list-item ${path === "/qr-code-business-card" &&
                            "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Business Card</a>
                        </li>
                      </p>
                      <p
                        id="digital_media_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-digital-media")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/qr-code-digital-media" &&
                            "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Digital Media</a>
                        </li>
                      </p>

                      <p
                        id="marketing_card_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-marketing-card")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/marketing" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Marketing Card</a>
                        </li>
                      </p>
                      <p
                        id="solution_card_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-solution-card")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/solution" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Solution Card</a>
                        </li>
                      </p>
                      <p
                        id="personal_use_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-personal-use")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/qr-code-personal-use" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Personal Use</a>
                        </li>
                      </p>
                      <p
                        id="educational_institute_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-educational-institutions")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/education" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Educational Institutes</a>
                        </li>
                      </p>

                      <p
                        id="free_generator_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-free-with-logo")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/qr-code-free-with-logo" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Free Generator</a>
                        </li>
                      </p>

                      <p
                        id="dynamic_qr_codes_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-dynamic")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/qr-code-dynamic" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Dynamic QR Codes</a>
                        </li>
                      </p>

                      <p
                        id="product_packaging_menu"
                        className="text-link"
                        onClick={() =>
                          window.location.replace("/qr-code-product-packaging")
                        }
                      >
                        <li
                          className={`product-nav-list-item ${path === "/qr-code-product-packaging" && "active-link-nav"
                            }`}
                        >
                          <a className="text-link">Product Packaging</a>
                        </li>
                      </p>

                    </ul>
                  </div>
                </span>{" "}
              </div>
              <div
                className={`nav-item ${path === "/pricing" && "active-link-nav"
                  }`}
              >
                {" "}
                <p
                  id="pricing_menu"
                  onClick={() => window.location.replace("/pricing")}
                  className="text-link"
                >
                  Pricing
                </p>{" "}
              </div>
              <div
                className={`nav-item ${path === "/help" && "active-link-nav"}`}
              >
                {" "}
                <p
                  id="support_menu"
                  onClick={() => window.location.replace("/help")}
                  className="text-link"
                >
                  Support
                </p>{" "}
              </div>
              <div
                className={`nav-item ${path === "/about" && "active-link-nav"}`}
              >
                <p
                  id="aboutus_menu"
                  onClick={() => window.location.replace("/about")}
                  className="text-link"
                >
                  About Us
                </p>
              </div>
            </div>
            <div style={{ marginRight: "10px" }}>
              <p
                id="login_menu"
                onClick={() => window.location.replace("/login")}
                className="text-link-1 pointer mb-0 mb-md-3"
              >
                Login
              </p>
            </div>
            <p
              id="signup_menu"
              onClick={() => window.location.replace("/signUp")}
              className="text-link mb-0 mb-md-3"
            >
              <button className="signup-btn cursor-pointer"> Sign up</button>
            </p>
            <div className="navIconCont">
              {isOpen ? (
                <AiOutlineClose
                  onClick={handleClose}
                  className="navMobile_icon"
                />
              ) : (
                <AiOutlineMenu
                  onClick={handleShow}
                  className="navMobile_icon"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        className="d-block d-lg-none canvasNav"
        placement="end"
        show={isOpen}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <div className="canvasLeft">
            <p
              onClick={() => window.location.replace("/")}
              className="text-link mb-0"
            >
              <img  loading="lazy"   src={logoCanvas} alt="." />
            </p>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Accordion>
            <div
              className={`product-nav-list-item2 ${path === "/" && "active-link-nav"
                }`}
            >
              {" "}
              <p
                onClick={() => window.location.replace("/")}
                className="text-link mb-0"
              >
                Home
              </p>{" "}
            </div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Products</Accordion.Header>
                <Accordion.Body
                  onClick={() => { }}
                  className="d-flex flex-column"
                >
                  <p
                    onClick={() =>
                      window.location.replace("/qr-code-beginners")
                    }
                    className="text-link"
                  >
                    <a
                      className={`product-nav-list-item text-link ${path === "/qr-code-beginners" && "active-link-nav"
                        }`}
                    >
                      Beginner's Guide
                    </a>
                  </p>
                  <p
                    onClick={() =>
                      window.location.replace("/qr-code-business-card")
                    }
                    className="text-link"
                  >
                    <a
                      className={`product-nav-list-item text-link ${path === "/qr-code-business-card" && "active-link-nav"
                        }`}
                    >
                      Business Card
                    </a>
                  </p>
                  <p
                    onClick={() =>
                      window.location.replace("/qr-code-digital-media")
                    }
                    className="text-link"
                  >
                    <a
                      className={`product-nav-list-item text-link ${path === "/qr-code-digital-media" && "active-link-nav"
                        }`}
                    >
                      Digital Media
                    </a>
                  </p>
                  <p
                    onClick={() =>
                      window.location.replace("/qr-code-marketing-card")
                    }
                    className="text-link"
                  >
                    <a
                      className={`product-nav-list-item text-link ${path === "/marketing" && "active-link-nav "
                        }`}
                    >
                      Marketing Card
                    </a>
                  </p>
                  <p
                    onClick={() =>
                      window.location.replace("/qr-code-solution-card")
                    }
                    className="text-link"
                  >
                    <a
                      className={`product-nav-list-item text-link ${path === "/qr-code-solution-card" && "active-link-nav"
                        }`}
                    >
                      Solution Card
                    </a>
                  </p>
                  <p
                    className="text-link"
                    onClick={() =>
                      window.location.replace("/qr-code-personal-use")
                    }
                  >
                    <li
                      className={`product-nav-list-item ${path === "/qr-code-personal-use" && "active-link-nav"
                        }`}
                    >
                      <a className="text-link">Personal Use</a>
                    </li>
                  </p>
                  <p
                    className="text-link"
                    onClick={() =>
                      window.location.replace("/qr-code-educational-institutions")
                    }
                  >
                    <li
                      className={`product-nav-list-item ${path === "/education" && "active-link-nav"
                        }`}
                    >
                      <a className="text-link">Educational Institutes</a>
                    </li>
                  </p>
                  <p
                    className="text-link"
                    onClick={() =>
                      window.location.replace("/qr-code-free-with-logo")
                    }
                  >
                    <li
                      className={`product-nav-list-item ${path === "/qr-code-free-with-logo" && "active-link-nav"
                        }`}
                    >
                      <a className="text-link">Free Generator</a>
                    </li>
                  </p>

                  <p
                    className="text-link"
                    onClick={() =>
                      window.location.replace("/qr-code-dynamic")
                    }
                  >
                    <li
                      className={`product-nav-list-item ${path === "/qr-code-dynamic" && "active-link-nav"
                        }`}
                    >
                      <a className="text-link">Dynamic QR codes</a>
                    </li>
                  </p>

                  <p
                    className="text-link"
                    onClick={() =>
                      window.location.replace("/qr-code-product-packaging")
                    }
                  >
                    <li
                      className={`product-nav-list-item ${path === "/qr-code-product-packaging" && "active-link-nav"
                        }`}
                    >
                      <a className="text-link">Product Packaging</a>
                    </li>
                  </p>
          
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div
              className={`product-nav-list-item2 ${path === "/pricing" && "active-link-nav"
                }`}
            >
              {" "}
              <p
                onClick={() => window.location.replace("/pricing")}
                className="text-link"
              >
                Pricing
              </p>
            </div>
            <div
              className={`product-nav-list-item2 ${path === "/help" && "active-link-nav"
                }`}
            >
              {" "}
              <p
                onClick={() => window.location.replace("/help")}
                className="text-link"
              >
                Support
              </p>
            </div>
            <div
              className={`product-nav-list-item2 ${path === "/about" && "active-link-nav"
                }`}
            >
              {" "}
              <p
                onClick={() => window.location.replace("/about")}
                className="text-link"
              >
                About Us
              </p>
            </div>
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
