import { useState } from "react";
import "./emailSection.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ModalDynamic } from "../../ModalComponent/ModalComponent";

export const EmailSection = ({
    top,
    type,
    handleDetail,
    inputs,
    setDetail,
    detail,
    setInputs,
}) => {
    const [typeSelected, setTypeSelected] = useState("static");
    const navigate = useNavigate();
    const location = useLocation();
    const userState = useSelector((state) => state.user);
    const { currentUserId } = userState;
    const [modalShow1, setModalShow1] = useState(false);

    const emailInputHandler = (e) => {
        handleDetail(e);
        setDetail((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <div className="emailSectionContainer">
            <ModalDynamic
                show={modalShow1}
                description="Please Login QR Sign Up for Dynamic Card QR Code"
                buttonText="LOGIN"
                link="/login"
                onHide={() => setModalShow1(false)}
            />
            {top && (
                <div className="top">
                    <div
                        className={
                            typeSelected === "static" ? "selected item" : "item"
                        }
                        onClick={() => setTypeSelected("static")}
                    >
                        Static QR Code
                    </div>
                    <div
                        className={
                            typeSelected === "dynamic"
                                ? "selected item"
                                : "item"
                        }
                        onClick={() =>
                            currentUserId
                                ? navigate("/user/home")
                                : navigate("/login")
                        }
                    >
                        Dynamic QR Code{" "}
                    </div>
                </div>
            )}
            <div className="formsection">
                <div className="heading">Email QR code</div>
                {location.pathname !== "/" && (
                    <div className="inputItem">
                        <label htmlFor="name">Name of your QR Code</label>
                        <input
                            placeholder="QR Code Name"
                            className="input"
                            type="text"
                            required
                            name="qrName"
                            value={inputs?.qrName || ""}
                            onChange={emailInputHandler}
                        />
                    </div>
                )}
                <div className="inputItem">
                    <label htmlFor="email">Email</label>
                    <input
                        className="input"
                        onChange={emailInputHandler}
                        value={detail?.email || inputs?.email}
                        type="email"
                        required
                        id="email"
                        placeholder="From:OwenDen@chip.com"
                        name="email"
                    />
                </div>
                <div className="inputItem">
                    <label htmlFor="subject">Subject</label>
                    <input
                        className="input"
                        onChange={emailInputHandler}
                        value={detail?.emailSubject || inputs?.emailSubject}
                        type="text"
                        required
                        id="subject"
                        placeholder="OwenDen@chip.com"
                        name="emailSubject"
                    />
                </div>
                <div className="inputItem">
                    <label htmlFor="message">Message</label>
                    <textarea
                        required
                        className="input"
                        onChange={emailInputHandler}
                        value={detail?.message || inputs?.message}
                        id="message"
                        placeholder="Write a Message..."
                        name="message"
                        minLength={200}
                    />
                    <h6 className="mt-1">
            {detail?.message
              ? `${detail.message.length} / 200 characters`
              : "0 / 200 characters"}
          </h6>
                </div>
            </div>
        </div>
    );
};
