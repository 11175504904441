import { useRef, useEffect, useState } from "react";
import "./StaticCard.scss";
import wifiLogo from "./image/wifi-image.png";
import wifiBlack from "./image/wifi-black.jpg";
import wifiQr from "./image/qr-image.png";
import wifiHeart from "./image/heart.png";
import QRCodeStyling from "qr-code-styling";

export const Wifi = ({ heading, number, message, subject, inputs, detail }) => {
    const [qrText, setqrText] = useState("sample");
    const qrCode = new QRCodeStyling({
        width: 155,
        height: 155,
        image: wifiBlack,
        type: "png",
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 3,
        },
        dotsOptions: {
            color: "black",
            type: "round",
        },
    });
    const qrRef = useRef();

    useEffect(() => {
        qrCode.append(qrRef.current);
    }, [qrText, inputs?.networkName, inputs?.password, inputs?.encType]);

    useEffect(() => {
        qrCode.update({
            data: qrText,
        });
    }, [qrText, inputs?.networkName, inputs?.password, inputs?.encType]);

    useEffect(() => {
        setqrText(
            `WIFI:T:${inputs?.encType};S:${inputs?.networkName};${
                inputs?.encType !== "nopass" ? `P:${inputs?.password};` : ""
            }H:${inputs?.isHidden};`
        );
    }, [inputs]);

    return (
        <div className="container-wifi">
            <div className="wifi-box text-center">
                <div className="wifi-bg">
                    <img  loading="lazy"   className="wifi-img" src={wifiLogo} alt="wifiLogo" />
                    <img  loading="lazy"   className="wifi-heart" src={wifiHeart} alt="heart" />
                </div>

                <div className="wifi-statement">
                    <p className="statement">
                        Scan to connect or ask for <br /> Password
                    </p>
                </div>
                <div className="qr-code">
                    {inputs?.networkName?.length > 0 ? (
                        <div ref={qrRef}></div>
                    ) : (
                        <img  loading="lazy"   className="qr-img" src={wifiQr} alt="qr-code" />
                    )}
                </div>
                <div className="scanButton">
                    <button className="scan-btn">Scan Now</button>
                </div>
            </div>
        </div>
    );
};
