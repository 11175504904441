import { useDispatch } from "react-redux";
import { FolderName } from "../../redux/QrURL/qraction";

export const FolderItem = ({
    fol,
    rename,
    renameFol,
    renameFolder,
    input,
    setInput,
}) => {
    const dispatch = useDispatch();
    const filterByFolder = () => {
        if (fol) {
            dispatch(FolderName(fol));
            setInput(fol);
            console.log("hhhh");
        }
    };

    return (
        <div style={{ width: "100%" }} className="item" key={fol}>
            <div className="item-left">
                <i className="fas fa-folder" />
                {rename && renameFol === fol ? (
                    <div>
                        <input
                            type="text"
                            style={{
                                border: "0.5px solid gray",
                                marginLeft: "10px",
                                width: "75%",
                            }}
                            value={input}
                            disabled={!(rename && renameFol === fol)}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => renameFolder(e)}
                        />
                        <span onClick={() => renameFolder("submit")}>
                            <i
                                class="fa fa-check-circle "
                                style={{ marginLeft: "10px" }}
                                aria-hidden="true"
                            />
                        </span>
                    </div>
                ) : (
                    <span style={{ marginLeft: 5 }} onClick={filterByFolder}>
                        {fol}
                    </span>
                )}
            </div>
        </div>
    );
};
