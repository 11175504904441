import "./StaticCard.scss";
import arrow from "./image/arrow.png";
import { toast } from "react-toastify";
export const Text = ({ inputs }) => {
  const CopyText = () => {
    navigator.clipboard?.writeText(inputs?.message);
    toast.success("Text copied to clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    // <div className="staticCardContainer">
    //   <div className="staticCardWrapper">
    //     <div className="staticCardTop">Text</div>
    //     <div className="infoContainer"></div>
    //     <div className="restContainer">
    //       {!inputs?.message ? "Type the message here" : inputs?.message}
    //     </div>
    //   </div>
    // </div>

    <>
      <div className="textCard m-auto">
        <div className="text-box">
          <div>
            <img  loading="lazy"   className="arrow" src={arrow} alt="arrow" />
          </div>
          <div>
            <h3 className="head-result">Result</h3>
          </div>
          <div className="text-logo">
            <h1 className="text-T text-center">T</h1>
          </div>
          <div className="qrDetails">
            <p className="txtDetailsHead">QR Code Details:</p>
            <p className="qrTxtDetails">
              {!inputs?.message
                ? "Text to QR Codes can be scanned and read by any QR Code scanning device and open as text-only files with the help of any application. It does not require an internet connection to work QR Code for text enables its users to display simple words,digits, and special characters. Text to QR code is a basic facilitator that requires no internet connection to scan and work."
                : inputs?.message}
            </p>
          </div>
          <div className="scanButton">
            <button className="txtCoppy-btn" onClick={CopyText}>
              Copy Text
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
