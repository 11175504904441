import "./Twitter.css";
import { BsTwitter } from "react-icons/bs";
import { ShareModal } from "../DynamicCard/ShareModal/ShareModal";
export const Twitter = ({ inputs }) => {
  return (
    <div className="twitter-container">
      <div className="iconContainer">
        <ShareModal />
      </div>
      <div className="twitter-Wrapper">
        <div className="main-content">
          <div className="twitter-logo">
            <BsTwitter className="twitter-icon" />
          </div>
          <div className="twitter-text">
            {!inputs?.message
              ? "Click on the follow button to follow us on twitter"
              : inputs?.message}
          </div>
          <div className="bottom-link">
            <a
              href={
                !inputs?.username
                  ? "#"
                  : `https://twitter.com/${inputs?.username}`
              }
              className="follow-link"
            >
              Follow
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
