import { combineReducers } from "redux";
import userReducer from "./User/userReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cardsReducer from "./Cards/cardsReducer";
import cardsURL from "./QrURL/qrreducer";
import colorsReducer from "./Colors/colorReducer";
import sidebarReducer from "./OthersReducer/sidebarReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userReducer,
  cards: cardsReducer,
  url: cardsURL,
  color: colorsReducer,
  sideBar: sidebarReducer,
});

export default persistReducer(persistConfig, rootReducer);
