import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
const Emoji = ({ onRate, emoji }) => {
    return (
        <>
            <SentimentVeryDissatisfiedIcon
                style={{
                    margin: "0 10px",
                    fontSize: "25px",
                    color: emoji === "Not Good" ? "#f1e153" : "",
                }}
                onClick={() => onRate("Not Good")}
                className="like-icon-emoji1"
            />
            <SentimentNeutralIcon
                style={{
                    margin: "0 10px",
                    fontSize: "25px",
                    color: emoji === "Okay" ? "#f1e153" : "",
                }}
                onClick={() => onRate("Okay")}
                className="like-icon-emoji2"
            />
            <SentimentSatisfiedAltIcon
                style={{
                    margin: "0 10px",
                    fontSize: "25px",
                    color: emoji === "Happy" ? "#f1e153" : "",
                }}
                onClick={() => onRate("Happy")}
                className="like-icon-emoji3"
            />
        </>
    );
};

export default Emoji;
