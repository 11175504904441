import "./WhyUs.scss";
import { useNavigate } from "react-router-dom";
import whyus1 from "../../asset/whyus1.png";
import whyus2 from "../../asset/whyus2.png";
import whyus3 from "../../asset/whyus3.png";

const data = [
    {
        img: whyus1,
        heading: "High-Quality QR Codes",
        para: "We use advanced QR code generators that produce high-quality codes with error correction. Our codes are designed to work flawlessly, no matter where you use them.",
    },
    {
        img: whyus2,
        heading: "Customizable Designs",
        para: "Branding is essential, so we offer customizable designs for your QR codes. You can choose from various styles, colors, and sizes to ensure your QR codes fit your branding guidelines.",
    },
    {
        img: whyus3,
        heading: "Excellent Customer Service",
        para: "We're available 24/7 to answer any questions and work hard to ensure your experience with us is seamless. We highly value our customers and are committed to providing you with the best possible service.",
    },
];

export const WhyUs = () => {
    const navigate = useNavigate();
    return (
        <div className="WhyUsContainer" id="whyus">
            <div className="WhyUsWrapper">
                <div className="WhyUscont">
                    <div className="WhyUsheading">Why choose us?</div>
                    <h3 className="WhyUsdesc">
                        With our professional QR Code generator software, you
                        can develop, customize, and edit your QR codes in one
                        convenient location.
                    </h3>
                    <div className="WhyUs--itemContainer">
                        {data?.map((item) => {
                            return (
                                <div key={item.heading} className="WhyUs--item">
                                    <div className="WhyUs--top">
                                        <img  loading="lazy"  
                                            className="WhyUsimg"
                                            src={item.img}
                                            alt="QR Code Generator "
                                        />
                                    </div>
                                    <h4 className="WhyUsh4">{item.heading}</h4>
                                    <div className="WhyUs--middle">
                                        <p>{item.para}</p>
                                    </div>
                                </div>
                            );
                        })}
                        <button
                            className="WhyUsbutton"
                            id="getQrCodeHome"
                            onClick={() => navigate("/signUp")}
                        >
                            Get QR Code
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
