import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import profile from "../../../asset/dynamicCard/profile.png";
import mail from "../../../asset/mail.svg";
import phone from "../../../asset/phone.svg";
import { MdEmail, MdCall, MdLocationOn } from "react-icons/md";
import { FaShoppingBag } from "react-icons/fa";
import "./Vcard.css";
import { useSelector } from "react-redux";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";
import {
    BsFacebook,
    BsInstagram,
    BsLinkedin,
    BsTwitter,
    BsWhatsapp,
    BsYoutube,
} from "react-icons/bs";
const Vcard = ({ inputs, qrInfo }) => {
    const location = useLocation();
    const { dynamicColor, textColor, buttonColor, imgURL } = useSelector(
        (state) => state.url
    );
    const [isShow, setIsShow] = useState(false);
    const [popupOptions, setPopupOtions] = useState({
        title: "",
        description: "",
        icon: "",
        callback: "",
    });
    const containerRef = useRef();
    const sendEmail = () => {
        window.location = `mailto:${
            qrInfo?.email ? qrInfo?.email : inputs?.email
        }`;
    };
    const dialNumber = () => {
        window.location = `tel:${
            qrInfo?.mobileNumber ? qrInfo?.mobileNumber : inputs?.mobileNumber
        }`;
    };

    return (
        <div
            className="vcardContainer"
            ref={containerRef}
            style={{
                backgroundColor:
                    qrInfo?.color ||
                    dynamicColor?.color2 ||
                    inputs?.color ||
                    "#ffffff",
            }}
        >
            <div
                className="wrappercard"
                style={{
                    backgroundColor:
                        qrInfo?.BGcolor ||
                        dynamicColor?.color1 ||
                        inputs?.BGcolor ||
                        "#537cfa",
                }}
            >
                <img  loading="lazy"  
                    className="profile-image"
                    alt=""
                    src={
                        qrInfo?.ownLogo ||
                        inputs?.ownLogo ||
                        imgURL ||
                        inputs?.imageURL[0] ||
                        profile
                    }
                />
                <div className="contents">
                    <div
                        className="person-name"
                        style={{
                            color:
                                qrInfo?.colorPrimary ||
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        {!inputs?.firstName
                            ? qrInfo?.firstName
                                ? `${qrInfo?.firstName}` +
                                  " " +
                                  `${qrInfo?.lastName}`
                                : qrInfo?.fullName
                                ? qrInfo?.fullName
                                : inputs?.fullName
                                ? inputs?.fullName
                                : "Owen Dennis Gordon"
                            : `${inputs?.firstName}` +
                              " " +
                              `${inputs?.lastName}`}
                    </div>
                    <div
                        className="job-title"
                        style={{
                            color:
                                qrInfo?.colorPrimary ||
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        {!inputs?.job
                            ? qrInfo?.job
                                ? qrInfo?.job
                                : "Intelligence Analyst"
                            : inputs?.job}
                    </div>
                    <div className="button1 d-flex justify-content-center ">
                        <button
                            className="get-ticket-btn"
                            style={{
                                backgroundColor:
                                    qrInfo?.BGcolor ||
                                    buttonColor?.button ||
                                    inputs?.BGcolor ||
                                    "white",
                                color:
                                    qrInfo?.colorPrimary ||
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "black",
                                height: "40px",
                                minWidth: "100px",
                                border: "1px solid",
                                borderRadius: "8px",
                                marginTop: "10px",
                            }}
                            onClick={() => {
                                window.open(
                                    inputs?.button?.link.includes("http")
                                        ? `${inputs?.button?.link}`
                                        : `https://${inputs?.button?.link}/`,
                                    "_blank"
                                );
                            }}
                        >
                            {inputs?.button?.name || "Button"}
                        </button>
                    </div>
                    {inputs?.socialLinks?.map((item) => {
                        return (
                            <div className="button1 d-flex justify-content-center ">
                                <button
                                    className="get-ticket-btn"
                                    style={{
                                        backgroundColor:
                                            buttonColor?.button ||
                                            inputs?.BGcolor ||
                                            "white",
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "black",
                                        height: "40px",
                                        minWidth: "100px",
                                        border: "1px solid",
                                        borderRadius: "8px",
                                        marginTop: "10px",
                                    }}
                                    onClick={() => {
                                        window.open(
                                            item?.url?.includes("http")
                                                ? `${item?.url}`
                                                : `https://${item?.url}/`,
                                            "_blank"
                                        );
                                    }}
                                >
                                    {item?.name || "Button"}
                                </button>
                            </div>
                        );
                    })}
                    <div className="icons">
                        <div
                            className="icon-div"
                            style={{
                                backgroundColor:
                                    buttonColor?.button ||
                                    inputs?.button ||
                                    "#fff",
                            }}
                        >
                            <MdCall
                                className="main-icons"
                                style={{
                                    color:
                                        textColor?.text || inputs?.colorPrimary,
                                }}
                                onClick={() => {
                                    setIsShow(true);
                                    setPopupOtions({
                                        title: "Call",
                                        description:
                                            "Users can click on this button to call your mobile number.",
                                        icon: phone,
                                        callback: dialNumber,
                                    });
                                }}
                            />
                        </div>
                        <div
                            className="icon-div"
                            style={{
                                backgroundColor:
                                    buttonColor?.button ||
                                    inputs?.button ||
                                    "#fff",
                            }}
                        >
                            <MdEmail
                                className="main-icons"
                                style={{
                                    color:
                                        textColor?.text || inputs?.colorPrimary,
                                }}
                                onClick={() => {
                                    setIsShow(true);
                                    setPopupOtions({
                                        title: "Mail",
                                        description:
                                            "Users can tap this button to send you an email.",
                                        icon: mail,
                                        callback: sendEmail,
                                    });
                                }}
                            />
                        </div>
                        <div
                            className="icon-div"
                            onClick={() =>
                                window.open(
                                    inputs?.streetAddress
                                        ? `https://www.google.com/maps/place/${inputs?.streetAddress},${inputs?.cityAddress}-${inputs?.zipAddress},${inputs?.stateAddress},${inputs?.countryAddress}`
                                        : "https://shrtco.de/TfHWiK",
                                    "_blank"
                                )
                            }
                            style={{
                                backgroundColor:
                                    buttonColor?.button ||
                                    inputs?.button ||
                                    "#fff",
                                cursor: "pointer",
                            }}
                        >
                            <MdLocationOn
                                className="main-icon"
                                size={23}
                                style={{
                                    color:
                                        textColor?.text || inputs?.colorPrimary,
                                }}
                            />
                        </div>
                        {inputs?.socialLink?.map((item) => (
                            <div
                                className="icon-div"
                                onClick={() => window.open(item?.link)}
                                style={{
                                    backgroundColor:
                                        buttonColor?.button ||
                                        inputs?.button ||
                                        "#fff",
                                    cursor: "pointer",
                                }}
                            >
                                {item.social === "facebook" && <BsFacebook />}
                                {item.social === "tiktok" && (
                                    <i className="fab fa-tiktok fa-2" />
                                )}
                                {item.social === "telegram" && (
                                    <i className="fab fa-telegram fa-2" />
                                )}
                                {item.social === "yelp" && (
                                    <i className="fab fa-yelp fa-2" />
                                )}
                                {item.social === "google" && (
                                    <i className="fab fa-google fa-2" />
                                )}
                                {item.social === "github" && (
                                    <i className="fab fa-github fa-2" />
                                )}
                                {item.social === "spotify" && (
                                    <i className="fab fa-spotify fa-2" />
                                )}
                                {item.social === "pinterest" && (
                                    <i className="fab fa-pinterest fa-2" />
                                )}
                                {item.social === "xing" && (
                                    <i className="fab fa-xing fa-2" />
                                )}
                                {item.social === "vimeo" && (
                                    <i className="fab fa-vimeo-v fa-2" />
                                )}
                                {item.social === "reddit" && (
                                    <i className="fab fa-reddit fa-2" />
                                )}
                                {item.social === "instagram" && <BsInstagram />}
                                {item.social === "twitter" && <BsTwitter />}
                                {item.social === "whatsapp" && <BsWhatsapp />}
                                {item.social === "linkedin" && <BsLinkedin />}
                                {item.social === "youtube" && <BsYoutube />}
                                {item.social === "applemusic" && (
                                    <i className="fab fa-applemusic fa-2" />
                                )}
                                {item.social === "snapchat" && (
                                    <i className="fab fa-snapchat fa-2" />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <BusinessPopUP
                show={isShow}
                onHide={() => setIsShow(false)}
                {...popupOptions}
                ref={containerRef}
            />
            <div
                className="contact-details"
                style={{
                    backgroundColor:
                        buttonColor?.button || inputs?.button || "#fff",
                }}
            >
                <div
                    className="contact-heading"
                    style={{
                        color:
                            textColor?.text ||
                            inputs?.colorPrimary ||
                            "#000000",
                    }}
                >
                    Contact
                </div>
                <div
                    className="contact-description"
                    style={{
                        color:
                            textColor?.text ||
                            inputs?.colorPrimary ||
                            "#000000",
                    }}
                >
                    {inputs?.description ||
                        "Seeking for freelance work with Over 10 years of Intelligence Analyst experience. Feel free to get in touch!"}
                </div>
                <hr className="horizontal-line" />
                <div className="contact-calls">
                    <a
                        href={`tel: ${
                            !inputs?.mobileNumber ? "" : inputs?.mobileNumber
                        }`}
                    >
                        <MdCall
                            color="black"
                            className="contact-icon"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        />
                    </a>
                    <div className="details">
                        <p
                            className="top-details"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        >
                            {!inputs?.mobileNumber
                                ? "+91 1234554321"
                                : inputs?.mobileNumber}
                        </p>
                        <p
                            className="bottom-details"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        >
                            Mobile
                        </p>
                    </div>
                    <div className="details1">
                        <p
                            className="top-details"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        >
                            {!inputs?.phoneNumber
                                ? "+91 1234554321"
                                : inputs?.phoneNumber}
                        </p>
                        <p
                            className="bottom-details"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        >
                            Telephone
                        </p>
                    </div>
                </div>
                <hr className="horizontal-line" />
                <div className="contact-email">
                    <MdEmail
                        className="contact-icon"
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#000000",
                        }}
                    />
                    <div className="details">
                        <p
                            className="top-details"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        >
                            {!inputs?.email
                                ? "Owen Dennis Gordon"
                                : inputs?.email}
                        </p>
                        <p
                            className="bottom-details"
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#000000",
                            }}
                        >
                            Email
                        </p>
                    </div>
                </div>

                {location.pathname !== "/user/create" && (
                    <>
                        <hr className="horizontal-line" />
                        <div className="contact-buisness">
                            <FaShoppingBag
                                className="contact-icon"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#000000",
                                }}
                            />
                            <div className="details">
                                <p
                                    className="top-details"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                    }}
                                >
                                    {!inputs?.company
                                        ? "Owen Dennis Gordon"
                                        : inputs?.company}
                                </p>
                                <p
                                    className="bottom-details"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                    }}
                                >
                                    {!inputs?.job
                                        ? "Intelligence Analyst"
                                        : inputs?.job}
                                </p>
                            </div>
                        </div>
                        <hr className="horizontal-line" />
                        <div className="contact-address">
                            <MdLocationOn
                                className="contact-icon"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#000000",
                                }}
                            />

                            <div className="details">
                                <p
                                    className="top-details"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                    }}
                                >
                                    {!inputs?.streetAddress
                                        ? "Crp Hall,Connaught Place New Delhi"
                                        : `${inputs?.streetAddress}, ${inputs?.cityAddress}-${inputs?.zipAddress}, ${inputs?.stateAddress}, ${inputs?.countryAddress}`}
                                </p>

                                <a
                                    href={
                                        inputs?.streetAddress
                                            ? `https://www.google.com/maps/place/${inputs?.streetAddress},${inputs?.cityAddress}-${inputs?.zipAddress},${inputs?.stateAddress},${inputs?.countryAddress}`
                                            : "https://shrtco.de/TfHWiK"
                                    }
                                    target="_blank"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#537CFA",
                                        textDecoration: "none",
                                        fontWeight: 400,
                                    }}
                                    rel="noreferrer"
                                >
                                    Show in map
                                </a>
                            </div>
                            <br />
                        </div>
                    </>
                )}
                {/* <div className="contact-add">
          <AiOutlineUserAdd
            style={{ color: textColor?.text || "#fff" }}
            size={22}
          />
        </div> */}
            </div>
        </div>
    );
};

export default Vcard;
