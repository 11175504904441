import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import CreateIcon from "@mui/icons-material/Create";
import BookIcon from "@mui/icons-material/Book";
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';

export const BannerIcon = [
    {
        id: 1,
        icon: <ContactPhoneIcon fontSize="large" />,
        title: "V Card",
    },
    {
        id: 2,
        icon: <BookIcon fontSize="large" />,
        title: "Book",
    },
    {
        id: 3,
        icon: <DirectionsCarIcon fontSize="large" />,
        title: "Vehicles",
    },
    {
        id: 4,
        icon: <ConfirmationNumberIcon fontSize="large" />,
        title: "Tickets",
    },
    {
        id: 5,
        icon: <CreateIcon fontSize="large" />,
        title: "Pens",
    },
    {
        id: 6,
        icon: <RestaurantMenuIcon fontSize="large" />,
        title: "Menus",
    },
    {
        id: 7,
        icon: <AutoStoriesOutlinedIcon fontSize="large" />,
        title: "Brochures",
    },
    {
        id: 8,
        icon: <SportsEsportsIcon fontSize="large" />,
        title: "Games",
    },
    {
        id: 9,
        icon: <ContactPhoneIcon fontSize="large" />,
        title: "V Card",
    },
    {
        id: 10,
        icon: <BookIcon fontSize="large" />,
        title: "Book",
    },
    {
        id: 11,
        icon: <DirectionsCarIcon fontSize="large" />,
        title: "Vehicles",
    },
    {
        id: 12,
        icon: <ConfirmationNumberIcon fontSize="large" />,
        title: "Tickets",
    },
    {
        id: 13,
        icon: <CreateIcon fontSize="large" />,
        title: "Pens",
    },
    {
        id: 14,
        icon: <RestaurantMenuIcon fontSize="large" />,
        title: "Menus",
    },
    {
        id: 15,
        icon: <AutoStoriesOutlinedIcon fontSize="large" />,
        title: "Brochures",
    },
    {
        id: 16,
        icon: <ContactPhoneIcon fontSize="large" />,
        title: "V Card",
    },
];
