
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
const Like = ({ like, onLike }) => {
  return (
    <>
      <ThumbUpIcon
        style={{ margin: "0 20px", fontSize: "25px",color:like==="Like"?"#f1e153":"" }}
        onClick={() => onLike("Like")}
        className="like-icon"
      />
      <ThumbDownIcon
        style={{ margin: "0 20px", fontSize: "25px",color:like==="Dislike"?"#f1e153":"" }}
        onClick={() => onLike("Dislike")}
        className="like-icon-down"
      />
    </>
  );
};

export default Like;
