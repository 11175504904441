import { useRef, useState } from "react";
import { AiOutlineRight, AiFillStar } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import { ImSpoonKnife } from "react-icons/im";
import { BsCoin, BsFillPeopleFill, BsCheckCircle } from "react-icons/bs";
import "./Feedback.css";
import { useSelector } from "react-redux";
import { CatagoriesItem } from "./CatagoriesItem";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";
import ExpandedFeedback from "./ExpandedFeedback";
//
import left_arrow from "../../../asset/left-arrow.svg";

export const Feedback = ({ inputs }) => {
    const { dynamicColor, textColor, buttonColor, feedback } = useSelector(
        (state) => state.url
    );
    const containerRef = useRef();
    const [isShow, setIsShow] = useState(false);
    const [popupOptions, setPopupOtions] = useState({
        title: "",
        description: "",
        icon: "",
        callback: "",
    });

    const callback = () => {
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = `https://${inputs?.websiteLink}`;
        a.click();
    };
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentExpandedItem, setCurrentExpandedItem] = useState("");

    console.log("feedback", feedback);

    return (
        <div
            className="feedbackContainer"
            style={{
                backgroundColor:
                    dynamicColor?.color2 || inputs?.color || "#ecf6ef",
            }}
            ref={containerRef}
        >
            <div
                className="feedbackWrapper"
                style={{
                    backgroundColor:
                        dynamicColor?.color1 || inputs?.BGcolor || "#45a45e",
                    color: textColor?.text || inputs?.colorPrimary || "#fff",
                }}
            >
                <div>
                    {isExpanded && (
                        <img  loading="lazy"  
                            src={left_arrow}
                            onClick={() => setIsExpanded(false)}
                            alt="left-arrow"
                        />
                    )}
                </div>
                {!inputs?.company ? "Holiday In Hotel" : inputs?.company}
                <div
                    className="top-icon"
                    style={{
                        backgroundColor:
                            buttonColor?.button || inputs?.button || "#fff",
                        color:
                            textColor?.text ||
                            inputs?.colorPrimary ||
                            "#45a45e",
                    }}
                >
                    <AiFillStar />
                </div>
            </div>
            {isExpanded ? (
                <ExpandedFeedback
                    title={currentExpandedItem}
                    buttonColor={buttonColor}
                    inputs={inputs}
                    textColor={textColor}
                    setIsExpanded={setIsExpanded}
                />
            ) : (
                <div className="feedback-main-content">
                    {/* ifClicked on expand */}

                    <>
                        <div>
                            <p
                                className="text-heading"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#000000",
                                }}
                            >
                                {!inputs?.title
                                    ? "We appreciate your Feedback!"
                                    : inputs?.title}
                            </p>
                            <p
                                className="text-description"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "gray",
                                }}
                            >
                                Please Select to reveiw a category.
                            </p>
                        </div>

                        {inputs?.catagories?.length > 0 ? (
                            inputs?.catagories?.map((item, index) => {
                                return (
                                    <CatagoriesItem
                                        item={item}
                                        isExpanded={isExpanded}
                                        setIsExpanded={setIsExpanded}
                                    />
                                );
                            })
                        ) : feedback?.length ? (
                            feedback?.map((item, index) => {
                                return (
                                    <div
                                        className="data-form"
                                        key={index}
                                        style={{
                                            color:
                                                textColor?.text ||
                                                inputs?.colorPrimary ||
                                                "#000000",
                                        }}
                                    >
                                        <div
                                            className="form-input"
                                            onClick={() => {
                                                setCurrentExpandedItem(
                                                    item.link
                                                );
                                                setIsExpanded(true);
                                            }}
                                        >
                                            <i
                                                className={`fas fa-${item.icon} px-2`}
                                            ></i>
                                            {item.link}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div
                                className="data-form"
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#000000",
                                }}
                            >
                                <div
                                    className="form-input"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                        backgroundColor:
                                            buttonColor?.button ||
                                            inputs?.button ||
                                            "#ffffff",
                                    }}
                                >
                                    <BsCoin className="coin-icon" />
                                    Prices
                                    <AiOutlineRight
                                        className="arrow-icon"
                                        onClick={() => {
                                            setCurrentExpandedItem("Prices");
                                            setIsExpanded(true);
                                        }}
                                    />
                                </div>
                                <div
                                    className="form-input"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                        backgroundColor:
                                            buttonColor?.button ||
                                            inputs?.button ||
                                            "#ffffff",
                                    }}
                                >
                                    <BsFillPeopleFill className="coin-icon" />
                                    Staff
                                    <AiOutlineRight
                                        className="arrow-icon"
                                        onClick={() => {
                                            setCurrentExpandedItem("Staff");
                                            setIsExpanded(true);
                                        }}
                                    />
                                </div>
                                <div
                                    className="form-input"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                        backgroundColor:
                                            buttonColor?.button ||
                                            inputs?.button ||
                                            "#ffffff",
                                    }}
                                >
                                    <BsCheckCircle className="coin-icon" />
                                    Availability
                                    <AiOutlineRight
                                        className="arrow-icon"
                                        onClick={() => {
                                            setCurrentExpandedItem(
                                                "Availability"
                                            );
                                            setIsExpanded(true);
                                        }}
                                    />
                                </div>
                                <div
                                    className="form-input"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                        backgroundColor:
                                            buttonColor?.button ||
                                            inputs?.button ||
                                            "#ffffff",
                                    }}
                                >
                                    <HiLocationMarker className="coin-icon" />
                                    Location
                                    <AiOutlineRight
                                        className="arrow-icon"
                                        onClick={() => {
                                            setCurrentExpandedItem("Location");
                                            setIsExpanded(true);
                                        }}
                                    />
                                </div>
                                <div
                                    className="form-input"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#000000",
                                        backgroundColor:
                                            buttonColor?.button ||
                                            inputs?.button ||
                                            "#ffffff",
                                    }}
                                >
                                    <ImSpoonKnife className="coin-icon" />
                                    Food
                                    <AiOutlineRight
                                        className="arrow-icon"
                                        onClick={() => {
                                            setCurrentExpandedItem("Food");
                                            setIsExpanded(true);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </>

                    <BusinessPopUP
                        show={isShow}
                        onHide={() => setIsShow(false)}
                        {...popupOptions}
                        ref={containerRef}
                    />
                    <div
                        className="bottom-link"
                        onClick={() => {
                            setIsShow(true);
                            setPopupOtions({
                                title: "Visit Our Website",
                                description:
                                    "By tapping this (optional) button, users are forwarded to a website of your choice.",
                                icon: "",
                                callback: callback,
                            });
                        }}
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#000000",
                        }}
                    >
                        Visit our website
                    </div>
                </div>
            )}
        </div>
    );
};
