import { useState, useRef } from "react";
import musicLogo from "../../../asset/dynamicCard/musicLogo.svg";
import "./Mp4.css";
import DownloadIcon from "@mui/icons-material/Download";
import { ShareModal } from "../ShareModal/ShareModal";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useSelector } from "react-redux";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";

export const Mp4 = ({ inputs }) => {
  const { dynamicColor, textColor, buttonColor } = useSelector(
    (state) => state.url
  );
  console.log(inputs);
  const containerRef = useRef();
  const [isShow, setIsShow] = useState(false);
  const [popupOptions, setPopupOtions] = useState({
    title: "",
    description: "",
    icon: "",
    callback: "",
    submitButtonText: "Download",
  });

  const openInNewTab = () => {
    window.open(
      `https://${inputs?.websiteLink}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div
      className="mp4Container"
      ref={containerRef}
      style={{ overflow: isShow ? "hidden" : "" }}
    >
      <div
        className="mp4Wrapper"
        style={{
          backgroundColor: dynamicColor?.color1 || inputs?.BGcolor || "#000000",
        }}
      >
        <div className="topIcon">
          <div className="icon">
            <DownloadIcon
              onClick={() => {
                setIsShow(true);
                setPopupOtions({
                  iconComponent: (
                    <div className="share-div-popup">
                      <div className="mp4-download">
                        <DownloadIcon />
                      </div>
                    </div>
                  ),
                  title: "Download",
                  submitButtonText: "Download",
                  audiourl: inputs?.audioURL,
                  description:
                    "By tapping here, user can download the mp3 fileto their devices.",
                });
              }}
            />
            <BusinessPopUP
              show={isShow}
              onHide={() => setIsShow(false)}
              {...popupOptions}
              ref={containerRef}
            />
          </div>
          <div className="icon">
            <ShareModal />
          </div>
        </div>
        <div className="main">
          <div className="logo">
            <img  loading="lazy"  
              src={inputs?.ownlogo ? inputs?.ownlogo : musicLogo}
              alt="noimg"
            />
          </div>
          <div className="detail">
            <div
              className="name"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#FFCF29",
              }}
            >
              {!inputs?.title ? "Memories" : inputs?.title}
            </div>
            <div
              className="album"
              style={{
                color: textColor?.text || inputs?.colorPrimary || "#fff",
              }}
            >
              {!inputs?.description ? "Maroon 5" : inputs?.description}
            </div>
          </div>
          <AudioPlayer
            autoPlay
            src={!inputs?.audioURL ? "" : inputs?.audioURL}
            onPlay={() => console.log("onPlay")}
            showJumpControls={false}
            hasDefaultKeyBindings={false}
            showFilledProgress={false}
            showDownloadProgress={false}
            autoPlayAfterSrcChange={false}
            style={{
              backgroundColor:
                dynamicColor?.color2 || inputs?.color || "#000000",
              color: textColor?.text || inputs?.colorPrimary || "#fff",
              width: "93.5%",
            }}
          />
        </div>
        <div
          className="mp4-bottom"
          style={{
            backgroundColor:
              dynamicColor?.color1 || inputs?.BGcolor || "#000000",
            color: textColor?.text || inputs?.colorPrimary || "#fff",
          }}
        >
          <div
            onClick={() => openInNewTab()}
            className="link"
            style={{ color: textColor?.text || inputs?.colorPrimary || "#fff" }}
          >
            {!inputs?.websiteLink ? "www.music.com" : inputs?.websiteLink}
          </div>
          <div className="button-wrapper">
            <div
              className="button"
              style={{
                border: `3px solid ${
                  buttonColor?.button || inputs?.button || "#ffffff"
                }`,
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: textColor?.text || inputs?.colorPrimary || "#fff",
                }}
                href={!inputs?.button?.link?.length === 0 ? "#" : inputs?.button?.link}
              >
                {inputs?.button?.name.length === 0 ? "Buy Now" : inputs?.button?.name}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
