import { useState } from "react";
// import "react-toastify/dist/ReactToastify.css";
import {
    Navbar,
    Offcanvas,
    Container,
    Nav,
    NavDropdown,
    Dropdown,
} from "react-bootstrap";
import logo1 from "../../asset/logoBig.png";
import avatar from "../../asset/user/avatar.png";
import "../../style/user/UserNavbar/userNavbar.scss";
import "./userNavbar.scss";
import "../../Pages/Admin/userNavbar.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearError, logout } from "../../redux/exportAllAction";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import NotificationCard from "./NotificationCard";

import OutsideClickHandler from "react-outside-click-handler";
import { ToastContainer } from "react-toastify";
import LogoutModal from "../LogoutModal";

export const UserNavbar = ({ admin, setShowSidebar, showSidebar }) => {
    const userState = useSelector((state) => state.user);
    const { userDetails } = userState;
    const [modalLogoutShow, setModalLogoutShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(clearError());
        dispatch(logout());
    };

    // notification
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const logoClickHandler = () => {
        if (admin) {
            navigate("/admin");
        } else {
            navigate("/user/home");
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="mobileNavbarContainer">
                <div
                    onClick={logoClickHandler}
                    style={{ height: "45px" }}
                    className="logoContainer"
                >
                    <img  loading="lazy"  
                        style={{ height: "100%" }}
                        className="logo"
                        src={logo1}
                        alt="."
                    />
                </div>
                <span
                    variant="primary"
                    onClick={() => setShowSidebar((prev) => !prev)}
                    className="me-2 openIcon"
                >
                    {showSidebar ? (
                        <i className="fas fa-angle-left"></i>
                    ) : (
                        <i className="fas fa-angle-right"></i>
                    )}
                </span>
                <Dropdown>
                    <Dropdown.Toggle
                        style={{
                            marginRight: "20px",
                            backgroundColor: "white",
                            border: "none",
                        }}
                        id="dropdown-basic"
                    >
                        <button
                            style={{ border: "none", backgroundColor: "white" }}
                        >
                            {userDetails?.photo ? (
                                <img  loading="lazy"  
                                    className=""
                                    src={userDetails?.photo}
                                    alt="avatar"
                                    style={{
                                        width: "43px",
                                        height: "43px",
                                        borderRadius: "50%",
                                    }}
                                />
                            ) : (
                                <img  loading="lazy"   src={avatar} alt="avatar" />
                            )}
                        </button>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <NavDropdown.Item
                            className="droplinkItem"
                            onClick={() => navigate("/user/home")}
                        >
                            {userDetails?.photo ? (
                                <img  loading="lazy"  
                                    style={{
                                        width: "43px",
                                        height: "43px",
                                        borderRadius: "50%",
                                    }}
                                    src={userDetails?.photo}
                                    alt="avatar"
                                />
                            ) : (
                                <img  loading="lazy"   src={avatar} alt="avatar" />
                            )}
                            {userDetails?.name}{" "}
                        </NavDropdown.Item>
                        {!admin && (
                            <>
                                <NavDropdown.Item
                                    className="droplinkItem"
                                    onClick={() => navigate("/user/account")}
                                >
                                    <i className="fas fa-user-circle"></i>{" "}
                                    Account
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    className="droplinkItem"
                                    onClick={() => navigate("/pricing")}
                                >
                                    <i className="fas fa-puzzle-piece"></i>{" "}
                                    Feature & Pricing
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    className="droplinkItem"
                                    onClick={() => navigate("/help")}
                                >
                                    <i className="fas fa-cog"></i> Help
                                </NavDropdown.Item>
                            </>
                        )}

                        <NavDropdown.Item
                            className="droplinkItem"
                            onClick={() => setModalLogoutShow(true)}
                        >
                            <i className="fas fa-sign-out-alt"></i> Logout
                        </NavDropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="userNavbarContainer">
                {["sm"].map((expand) => (
                    <Navbar key={expand} bg="bg-white" expand={expand}>
                        <Container fluid className="containerFluid">
                            <Navbar.Brand>
                                <div className="left">
                                    <div
                                        className="logoContainer"
                                        onClick={logoClickHandler}
                                    >
                                        <img  loading="lazy"  
                                            className="logo"
                                            src={logo1}
                                            alt="."
                                        />
                                    </div>

                                    <span
                                        variant="primary"
                                        onClick={() =>
                                            setShowSidebar((prev) => !prev)
                                        }
                                        className="me-2 openIcon"
                                    >
                                        {showSidebar ? (
                                            <i className="fas fa-angle-left"></i>
                                        ) : (
                                            <i className="fas fa-angle-right"></i>
                                        )}
                                    </span>
                                </div>
                            </Navbar.Brand>
                            <Navbar.Toggle
                                aria-controls={`offcanvasNavbar-expand-${expand}`}
                            />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title
                                        id={`offcanvasNavbarLabel-expand-${expand}`}
                                    >
                                        Hello{" "}
                                        {userDetails?.name
                                            ? userDetails?.name
                                            : "Admin"}
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1">
                                        <OutsideClickHandler
                                            onOutsideClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            <div className="p-2 pe-3 d-flex justify-content-center align-items-center Navbar-notification ">
                                                <NotificationsNoneIcon
                                                    variant="contained"
                                                    onClick={handleClick(
                                                        "bottom-end"
                                                    )}
                                                />
                                            </div>

                                            <Popper
                                                open={open}
                                                placement={placement}
                                                anchorEl={anchorEl}
                                                transition
                                                style={{
                                                    zIndex: "10000",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                {({ TransitionProps }) => (
                                                    <Fade
                                                        {...TransitionProps}
                                                        timeout={350}
                                                    >
                                                        <div className="popper-Box">
                                                            <div className="container header pt-5 pb-3">
                                                                <h1>
                                                                    What's New ?
                                                                </h1>
                                                            </div>
                                                            <div className="d-flex flex-column ">
                                                                <NotificationCard />
                                                                <NotificationCard />
                                                                <NotificationCard />
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </OutsideClickHandler>

                                        <div className="p-2 pe-4 d-flex justify-content-center align-items-center Navbar-notification ">
                                            <HelpOutlineIcon
                                                onClick={() =>
                                                    navigate("/help")
                                                }
                                            />
                                        </div>
                                        <div
                                            className="welcome"
                                            style={{
                                                padding: "0 20px",
                                                borderLeft:
                                                    "1px solid #7c858c ",
                                            }}
                                        >
                                            <div
                                                className="welcome-text "
                                                style={{ textAlign: "center" }}
                                            >
                                                Welcome!!
                                            </div>
                                            <span className="name-text">
                                                {admin
                                                    ? userState?.loggedBy ===
                                                      "Subadmin"
                                                        ? userDetails?.name
                                                        : "Admin"
                                                    : userDetails?.name
                                                    ? userDetails?.name
                                                    : "User"}
                                            </span>
                                        </div>

                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="primary"
                                                id="dropdown-basic"
                                            >
                                                {userDetails?.photo ? (
                                                    <img  loading="lazy"  
                                                        className=""
                                                        src={userDetails?.photo}
                                                        alt="avatar"
                                                        style={{
                                                            width: "43px",
                                                            height: "43px",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                ) : (
                                                    <img  loading="lazy"  
                                                        src={avatar}
                                                        alt="avatar"
                                                    />
                                                )}
                                            </Dropdown.Toggle>
                                            {!admin && (
                                                <Dropdown.Menu
                                                    style={{
                                                        left: -70,
                                                    }}
                                                >
                                                    <NavDropdown.Item
                                                        className="droplinkItem"
                                                        onClick={() =>
                                                            navigate(
                                                                "/user/home"
                                                            )
                                                        }
                                                    >
                                                        {userDetails?.name}
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        className="droplinkItem"
                                                        onClick={() =>
                                                            navigate(
                                                                "/user/account"
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-user-circle"></i>{" "}
                                                        Account
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        className="droplinkItem"
                                                        onClick={() =>
                                                            navigate("/pricing")
                                                        }
                                                    >
                                                        <i className="fas fa-puzzle-piece"></i>{" "}
                                                        Feature & Pricing
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        className="droplinkItem"
                                                        onClick={() =>
                                                            navigate("/help")
                                                        }
                                                    >
                                                        <i className="fas fa-cog"></i>{" "}
                                                        Help
                                                    </NavDropdown.Item>

                                                    <NavDropdown.Item
                                                        className="droplinkItem"
                                                        onClick={() =>
                                                            setModalLogoutShow(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-sign-out-alt"></i>{" "}
                                                        Logout
                                                    </NavDropdown.Item>
                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>
                                        <div className="right">
                                            <Nav.Link
                                                className="logout rightIcon"
                                                onClick={() =>
                                                    setModalLogoutShow(true)
                                                }
                                            >
                                                <svg
                                                    width="25"
                                                    height="31"
                                                    viewBox="0 0 30 31"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        opacity="0.5"
                                                        d="M2.05952 30.6406C1.65471 30.6406 1.26649 30.4826 0.980251 30.2013C0.694011 29.92 0.533203 29.5385 0.533203 29.1406V2.14062C0.533203 1.7428 0.694011 1.36127 0.980251 1.07996C1.26649 0.79866 1.65471 0.640625 2.05952 0.640625H23.4279C23.8327 0.640625 24.221 0.79866 24.5072 1.07996C24.7934 1.36127 24.9543 1.7428 24.9543 2.14062V6.64062H21.9016V3.64062H3.58583V27.6406H21.9016V24.6406H24.9543V29.1406C24.9543 29.5385 24.7934 29.92 24.5072 30.2013C24.221 30.4826 23.8327 30.6406 23.4279 30.6406H2.05952ZM21.9016 21.6406V17.1406H11.2174V14.1406H21.9016V9.64062L29.5332 15.6406L21.9016 21.6406Z"
                                                        fill="#303030"
                                                    />
                                                </svg>
                                            </Nav.Link>
                                        </div>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
                <ToastContainer />
                <LogoutModal
                    show={modalLogoutShow}
                    handleLogout={handleLogout}
                    onHide={() => setModalLogoutShow(false)}
                />
            </div>
        </>
    );
};
