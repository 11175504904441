import { Modal } from "react-bootstrap";
import React from "react";
import "./ModalComponent/modalComponent.css";

const LogoutModal = ({ show, handleLogout, onHide }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalComponentTitle"
                >
                    Account Logout Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalComponentBody">
                <p>Are you sure you want to Logout your account?</p>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end w-50 gap-3 deleteModalFooter">
                    <button
                        className="modalComponentButton btn btn-danger"
                        onClick={handleLogout}
                    >

                        Logout

                    </button>
                    <button
                        className="modalComponentButton cancelBtn btn btn-secondary"
                        onClick={onHide}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default LogoutModal;
