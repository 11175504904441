import axios from "axios";
import { useState } from "react";

const useLocation = () => {
    const [userLocation, setLocation] = useState(null);

    const onLocation = async () => {
        try {
            const res = await axios.get("https://geolocation-db.com/json/");
            setLocation(res.data);
        } catch {
            const res = await axios.get("https://ipapi.co/json");
            setLocation(res.data);
        }
    };

    useState(() => {
        onLocation();
    }, []);

    return { userLocation };
};

export default useLocation;
