import "./App.css";
import React, { lazy } from "react";
import { injectStyle } from "react-toastify/dist/inject-style";
import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./Pages/Home";
import { Login } from "./Pages/Login/Login";
import { Signup } from "./Pages/Signup/Signup";
// import "react-toastify/dist/ReactToastify.css";
import { Card } from "./Pages/Card";
import { SocialMedia } from "./Pages/DynamicCard/SocialMedia/SocialMedia";
import { Business } from "./Pages/DynamicCard/Business/Business";
import { Feedback } from "./Pages/DynamicCard/Feedback/Feedback";
import { Rating } from "./Pages/DynamicCard/Rating/Rating";
import { Video } from "./Pages/DynamicCard/Video/Video";
import { Images } from "./Pages/DynamicCard/Images/Images";
import { Mp4 } from "./Pages/DynamicCard/Mp4/Mp4";
import { Apps } from "./Pages/DynamicCard/Apps/Apps";
import { Pdf } from "./Pages/DynamicCard/Pdf/Pdf";
import { Facebook } from "./Pages/DynamicCard/Facebook/Facebook";
import { Event } from "./Pages/DynamicCard/Event/Event";
import { UrlStatic } from "./Pages/StaticCard/UrlStatic";
import { Text } from "./Pages/StaticCard/Text";
import { VcardStatic } from "./Pages/StaticCard/VcardStatic";
import { Email } from "./Pages/StaticCard/Email/Email";
import { Sms } from "./Pages/StaticCard/Sms";
import { Wifi } from "./Pages/StaticCard/Wifi";
import { Twitter } from "./Pages/StaticCard/TwitterCard";

import { useSelector } from "react-redux";
import { CardDisplay } from "./Pages/displayCards/CardDisplay";
import Protected from "./Component/Protected/Protected";

import { ToastContainer } from "react-toastify";
import ThankYou from "./Pages/ThankYou/ThankYou";
import EducationalInstitute from "./Pages/EducationalInstitute";

const SolutionCards = lazy(() => import("./Pages/SolutionCard/SolutionCard"));
const VarietyForDigitalMedias = lazy(() =>
  import("./Pages/DigitalMedia/VarietyForDigitalMedia")
);
const BeginnersGuides = lazy(() => import("./Pages/Guide/BeginnersGuide"));
const MarketingCards = lazy(() => import("./Pages/MarketingCard/Marketing"));
const BusinessCards = lazy(() => import("./Pages/BusinessCard/BusinessCard"));
const PersonalUse = lazy(() => import("./Pages/PersonalUse/PersonalUse"));
const FreeGenerator = lazy(() => import("./Pages/FreeGenerator/FreeGenerator"));
const DynamicQrCode = lazy(() => import("./Pages/DynamicQrCode/DynamicQrCode"));
const ProductPackaging = lazy(() =>
  import("./Pages/ProductPackaging/ProductPackaging")
);

const Coupon = lazy(() => import("./Pages/DynamicCard/Coupon/Coupon"));
const Vcard = lazy(() => import("./Pages/DynamicCard/Vcard/Vcard"));

const MYUser = lazy(() => import("./Pages/user/UserPanel/User"));
const FeildPages = lazy(() => import("./Component/FeildPage/FeildPage"));
const Abouts = lazy(() => import("./Pages/StaticPages/About"));
const TermsConditions = lazy(() =>
  import("./Pages/StaticPages/TermsCondition")
);
const Helps = lazy(() => import("./Pages/Help/Help"));
const AskQuerys = lazy(() => import("./Pages/Help/AskQuery"));
const PrivacyPolicys = lazy(() => import("./Pages/StaticPages/PrivacyPolicy"));
const Plans = lazy(() => import("./Pages/Plan/Plan"));
const ForgetPassword = lazy(() =>
  import("./Pages/ForgetPassword/ForgetPassword")
);
const Activate = lazy(() => import("./Pages/Activate/Activate"));
const Admin = lazy(() => import("./Pages/Admin/AdminPanel/Admin"));

const SingleUserBlog = lazy(() => import("./Pages/Blogs/SingleUserBlog"));
const Blog = lazy(() => import("./Pages/Blogs/Blog"));

const AddBlogs = lazy(() => import("./Pages/Admin/AdminBlogs/AddBlogs"));
const SingleBlog = lazy(() => import("./Pages/Admin/AdminBlogs/SingleBlog"));
const EditBlogs = lazy(() => import("./Pages/Admin/AdminBlogs/EditBlogs"));

if (typeof window !== "undefined") {
  injectStyle();
}

function App() {
  const { currentUserId, loggedBy } = useSelector((state) => state.user);
  const accessToken = localStorage.getItem("accessToken");

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            currentUserId || accessToken ? (
              loggedBy === "Admin" ? (
                <Navigate to="/admin" />
              ) : (
                <Navigate to="/user/home" />
              )
            ) : (
              <Home />
            )
          }
        />
        <Route
          exact
          path="*"
          element={
            currentUserId || accessToken ? (
              loggedBy === "Admin" ? (
                <Navigate to="/admin" />
              ) : (
                <Navigate to="/user/home" />
              )
            ) : (
              <Home />
            )
          }
        />
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/signUp"
          element={currentUserId ? <Navigate to="/user/home" /> : <Signup />}
        />
        <Route exact path="/pricing" element={<Plans />} />
        <Route exact path="/thank-you" element={<ThankYou />} />
        <Route exact path="/card/:id" element={<CardDisplay />} />
        <Route exact path="/forget-password" element={<ForgetPassword />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicys />} />

        <Route
          exact
          path="/qr-code-business-card"
          element={<BusinessCards />}
        />
        <Route
          exact
          path="/qr-code-digital-media"
          element={<VarietyForDigitalMedias />}
        />
        <Route exact path="/qr-code-beginners" element={<BeginnersGuides />} />

        <Route
          exact
          path="/qr-code-marketing-card"
          element={<MarketingCards />}
        />
        <Route
          exact
          path="/qr-code-solution-card"
          element={<SolutionCards />}
        />
        <Route
          exact
          path={`/term-and-condition`}
          element={<TermsConditions />}
        />
        <Route
          exact
          path={`/qr-code-educational-institutions`}
          element={<EducationalInstitute />}
        />
        <Route exact path="/qr-code-personal-use" element={<PersonalUse />} />
        <Route
          exact
          path="/qr-code-free-with-logo"
          element={<FreeGenerator />}
        />
        <Route exact path="/qr-code-dynamic" element={<DynamicQrCode />} />
        <Route
          exact
          path="/qr-code-product-packaging"
          element={<ProductPackaging />}
        />
        <Route exact path="/help" element={<Helps />} />
        <Route exact path="/ask-query" element={<AskQuerys />} />
        <Route exact path="/about" element={<Abouts />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
        <Route element={<Protected />}>
          <Route exact path="/user/home" element={<MYUser />} />
          <Route exact path="/user/create" element={<MYUser />} />
          <Route
            exact
            path="/user/create/feild/:qrType/:card"
            element={<FeildPages />}
          />
          <Route exact path="/user/account" element={<MYUser />} />
          <Route exact path="/user/qr-design" element={<MYUser />} />
          {/* highlight */}
          <Route exact path="/user/card-design" element={<MYUser />} />

          <Route exact path="/user/activate/:email" element={<Activate />} />
        </Route>
        <Route
          exact
          path="/admin"
          element={
            loggedBy === "Admin" || loggedBy === "Subadmin" ? (
              <Admin />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          exact
          path="/admin/add-blogs"
          element={
            loggedBy === "Admin" || loggedBy === "Subadmin" ? (
              <AddBlogs />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          exact
          path="/admin/blogs/:id"
          element={
            loggedBy === "Admin" || loggedBy === "Subadmin" ? (
              <SingleBlog />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          exact
          path="/admin/edit-blogs/:id"
          element={
            loggedBy === "Admin" || loggedBy === "Subadmin" ? (
              <EditBlogs />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route exact path="qr/:qrId" element={<Card />} />

        <Route exact path="/blogs" element={<Blog />} />
        <Route exact path="/blogs/:id" element={<SingleUserBlog />} />

        <Route exact path="/coupon" element={<Coupon />} />
        <Route exact path="/vcard" element={<Vcard />} />
        <Route exact path="/social-media" element={<SocialMedia />} />
        <Route exact path="/business" element={<Business />} />
        <Route exact path="/feedback" element={<Feedback />} />
        <Route exact path="/rating" element={<Rating />} />
        <Route exact path="/video" element={<Video />} />
        <Route exact path="/images" element={<Images />} />
        <Route exact path="/mp4" element={<Mp4 />} />
        <Route exact path="/apps" element={<Apps />} />
        <Route exact path="/pdf" element={<Pdf />} />
        <Route exact path="/facebook" element={<Facebook />} />
        <Route exact path="/event" element={<Event />} />
        <Route exact path="/email" element={<Email />} />
        <Route exact path="/sms" element={<Sms />} />
        <Route exact path="/url" element={<UrlStatic />} />
        <Route exact path="/text" element={<Text />} />
        <Route exact path="/vcard-static" element={<VcardStatic />} />
        <Route exact path="/wifi" element={<Wifi />} />
        <Route exact path="/twitter" element={<Twitter />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        progress={undefined}
      />
    </>
  );
}

export default App;
