const fbUrlRegex =
    /^https?:\/\/(www\.|m\.|mbasic\.|web\.)facebook\.com\/(pg\/[a-zA-Z0-9.-]+|([a-zA-Z0-9.-]+))\/?$/;

const fbMeUrlRegex =
    /^https?:\/\/(www\.|fb\.me)\/(pg\/[a-zA-Z0-9.-]+|([a-zA-Z0-9.-]+))\/?$/;

const fbComUrlRegex =
    /^https?:\/\/(www\.|)fb\.com\/(pg\/[a-zA-Z0-9.-]+|([a-zA-Z0-9.-]+))\/?$/;

const twitterUrlRegex =
    /^https?:\/\/(www\.|mobile\.|mobile\.twitter\.com\/|twitter\.com\/)?([A-Za-z0-9_]{1,15})\/?$/;
const instagramUrlRegex =
    /^https?:\/\/(www\.|)instagram\.com\/([A-Za-z0-9_\-.]+)\/?$/;

const whatsappLinkRegex =
    /^https?:\/\/(wa\.me|api\.whatsapp\.com)\/(send\?phone=|\d{2,})(\d+)(.*)$/;

const tiktokStandardRegex =
    /^(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@[\w.-]+\/video\/\d+$/;

const tiktokShortenedRegex = /^(?:https?:\/\/)?(?:www\.)?tiktok\.com\/v\/\d+$/;

const tiktokShareableRegex =
    /^(?:https?:\/\/)?(?:www\.)?tiktok\.com\/share\/video\/\d+$/;

const tiktokVmRegex = /^(?:https?:\/\/)?(?:www\.)?vm\.tiktok\.com\/video\/\d+$/;

const snapchatStandardRegex =
    /^(?:https?:\/\/)?(?:www\.)?snapchat\.com\/add\/\w+$/;

const snapchatShareableRegex =
    /^(?:https?:\/\/)?(?:www\.)?snapchat\.com\/add\/\w+\?.*$/;

// Standard YouTube URL
const youtubeStandardRegexs =
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/channel\/[\w-]+$/;

// Shareable YouTube URL
const youtubeShareableRegexs =
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/channel\/[\w-]+\?.*$/;

// Standard YouTube URL
const youtubeStandardRegex =
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=[\w-]+$/;

// Short YouTube URL
const youtubeShortRegex = /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/[\w-]+$/;

// Embed YouTube URL
const youtubeEmbedRegex =
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/[\w-]+$/;

// Shareable YouTube URL
const youtubeShareableRegex =
    /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/[\w-]+\?.*$/;

// Standard Telegram URL
const telegramStandardRegex =
    /^(?:https?:\/\/)?(?:www\.)?t\.me\/[\w-]+(\/[\w-]+)*$/;

// Short Telegram URL
const telegramShortRegex =
    /^(?:https?:\/\/)?(?:www\.)?telegram\.me\/[\w-]+(\/[\w-]+)*$/;

// Shareable Telegram URL
const telegramShareableRegex =
    /^(?:https?:\/\/)?(?:www\.)?t\.me\/[\w-]+(\/[\w-]+)*\?.*$/;

// Business URL
const yelpBusinessRegex =
    /^(?:https?:\/\/)?(?:www\.)?yelp\.com\/biz\/([\w-]+)$/;

// User URL
const yelpUserRegex =
    /^(?:https?:\/\/)?(?:www\.)?yelp\.com\/user_details\?userid=([\w-]+)$/;

// Review URL
const yelpReviewRegex =
    /^(?:https?:\/\/)?(?:www\.)?yelp\.com\/biz\/[\w-]+\/review\/[\w-]+$/;

// Pin URL
const pinterestPinRegex =
    /^(?:https?:\/\/)?(?:www\.)?pinterest\.(?:com|it|fr|es|co\.uk|ca|de|nl|jp)\/(?:pin|[\w-]{2}\/pin)\/(\d{13,17})\/?/;

// Board URL
const pinterestBoardRegex =
    /^(?:https?:\/\/)?(?:www\.)?pinterest\.(?:com|it|fr|es|co\.uk|ca|de|nl|jp)\/([\w-]+)\/(?:[^/]+\/)?([\w-]+)\/?$/;

// User URL
const pinterestUserRegex =
    /^(?:https?:\/\/)?(?:www\.)?pinterest\.(?:com|it|fr|es|co\.uk|ca|de|nl|jp)\/([\w-]+)\/?$/;

// Profile URL
const xingProfileRegex =
    /^(?:https?:\/\/)?(?:[a-z]{2,3}\.)?xing\.com\/(?:profile|people)\/([\w-]+)(?:\/|$)/;

// Company URL
const xingCompanyRegex =
    /^(?:https?:\/\/)?(?:[a-z]{2,3}\.)?xing\.com\/companies\/([\w-]+)(?:\/|$)/;

const isValidFacebookUrl = (url) => {
    return (
        fbUrlRegex.test(url) ||
        fbMeUrlRegex.test(url) ||
        fbComUrlRegex.test(url)
    );
};

const isValidTwitterUrl = (url) => {
    return twitterUrlRegex.test(url);
};

const isValidInstagramUrl = (url) => {
    return instagramUrlRegex.test(url);
};

const isValidWhatsAppUrl = (url) => {
    return whatsappLinkRegex.test(url);
};

const isValidTiktokUrl = (url) => {
    return (
        tiktokStandardRegex.test(url) ||
        tiktokShortenedRegex.test(url) ||
        tiktokShareableRegex.test(url) ||
        tiktokVmRegex.test(url)
    );
};

const isValidSnapchatUrl = (url) => {
    return snapchatStandardRegex.test(url) || snapchatShareableRegex.test(url);
};

const isValidYoutubeUrl = (url) => {
    return (
        youtubeStandardRegexs.test(url) ||
        youtubeShareableRegexs.test(url) ||
        youtubeStandardRegex.test(url) ||
        youtubeShortRegex.test(url) ||
        youtubeEmbedRegex.test(url) ||
        youtubeShareableRegex.test(url)
    );
};
const isValidTelegramUrl = (url) => {
    return (
        telegramStandardRegex.test(url) ||
        telegramShortRegex.test(url) ||
        telegramShareableRegex.test(url)
    );
};

const isValidYelpUrl = (url) => {
    return (
        yelpBusinessRegex.test(url) ||
        yelpUserRegex.test(url) ||
        yelpReviewRegex.test(url)
    );
};

const isValidPinterestUrl = (url) => {
    return (
        pinterestPinRegex.test(url) ||
        pinterestBoardRegex.test(url) ||
        pinterestUserRegex.test(url)
    );
};

const isValidLinkedinUrl = (url) => {
    const linkedinProfileRegex =
        /^https?:\/\/(www\.)?linkedin\.com\/(in|pub)\/[\w-]+\/?$/i;
    let data = linkedinProfileRegex.test(url);
    debugger;
    return data;
};

const isValidZingUrl = (url) => {
    return xingProfileRegex.test(url) || xingCompanyRegex.test(url);
};

export const SocialLInkValidation = (type, link) => {
    debugger;
    if (type === "facebook") {
        debugger;
        return isValidFacebookUrl(link.url);
    } else if (type === "twitter") {
        return isValidTwitterUrl(link.url);
    } else if (type === "instagram") {
        return isValidInstagramUrl(link.url);
    } else if (type === "whatsapp") {
        return isValidWhatsAppUrl(link.url);
    } else if (type === "tiktok") {
        return isValidTiktokUrl(link.url);
    } else if (type === "snapchat") {
        return isValidSnapchatUrl(link.url);
    } else if (type === "youtube") {
        return isValidYoutubeUrl(link.url);
    } else if (type === "telegram") {
        return isValidTelegramUrl(link.url);
    } else if (type === "yelp") {
        return isValidYelpUrl(link.url);
    } else if (type === "pinterest") {
        return isValidPinterestUrl(link.url);
    } else if (type === "linkedin") {
        return isValidLinkedinUrl(link.url);
    } else if (type === "zing") {
        return isValidZingUrl(link.url);
    }
};
