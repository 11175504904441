import { useState } from "react";
import "../HeroFeild/EmailSection/emailSection.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalDynamic } from "../ModalComponent/ModalComponent";
import { useSelector } from "react-redux";

export const TextSection = ({
  top,
  type,
  handleDetail,
  setInputs,
  setDetail,
  detail,
  inputs,
}) => {
  const [typeSelected, setTypeSelected] = useState("static");
  const location = useLocation();
  const [modalShow1, setModalShow1] = useState(false);
  const userState = useSelector((state) => state.user);
  const { currentUserId } = userState;
  const navigate = useNavigate();
  const textInputHandler = (e) => {
    handleDetail(e);
    setDetail((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div className="emailSectionContainer">
      <ModalDynamic
        show={modalShow1}
        description="Please Login QR Sign Up for Dynamic Card QR Code"
        buttonText="LOGIN"
        link="/login"
        onHide={() => setModalShow1(false)}
      />
      {top && (
        <div className="top">
          <div
            className={typeSelected === "static" ? "selected item" : "item"}
            onClick={() => setTypeSelected("static")}
          >
            Static QR Code
          </div>
          <div
            className={typeSelected === "dynamic" ? "selected item" : "item"}
            onClick={() =>
              currentUserId ? navigate("/user/home") : setModalShow1(true)
            }
          >
            Dynamic QR Code <i className="fas fa-crown"></i>
          </div>
        </div>
      )}
      <div className="formsection">
        <div className="heading">Text QR code</div>
        {location.pathname !== "/" && (
          <div className="inputItem">
            <label htmlFor="name">Name of your QR Code</label>
            <input
              placeholder="QR Code Name"
              className="input"
              type="text"
              required
              name="qrName"
              value={inputs?.qrName || ""}
              onChange={textInputHandler}
            />
          </div>
        )}
        <div className="inputItem">
          <label htmlFor="message">Message</label>
          <textarea
            className="input"
            required
            onChange={textInputHandler}
            defaultValue={inputs?.message || undefined}
            id="message"
            placeholder="Write a Message..."
            name="message"
            maxLength="200"
          />
          <h6 className="mt-1">
            {detail?.message
              ? `${detail.message.length} / 200 characters`
              : "0 / 200 characters"}
          </h6>
        </div>
      </div>
    </div>
  );
};
