const initialState = {
    primaryColor: "",
    secondaryColor: "",
    textColor: "",
    buttonColor: {
        button: "",
        text: "",
    },
};

const colorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_COLORS":
            state = {
                primaryColor: "",
                secondaryColor: "",
                textColor: "",
                buttonColor: {
                    button: "",
                    text: "",
                },
            };
            return {
                ...state,
                primaryColor: action.payload.primaryColor,
                secondaryColor: action.payload.secondaryColor,
                textColor: action.payload.textColor,
                buttonColor: action.payload.buttonColor,
            };
        case "GET_COLORS":
            return state;
        default:
            return state;
    }
};

export default colorsReducer;
