import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import CreatableSelect from "react-select/creatable";
import { SocialIcon } from "react-social-icons";
import { toast } from "react-toastify";

import "./VcardSection.scss";
import { CardDesign } from "../../../Pages/user/Create/CardDesign";
import { ModalDynamic } from "../../ModalComponent/ModalComponent";
import Binfo from "../../../asset/Binfo.png";
import Like from "../../../asset/like.png";
import { SocialLInkValidation } from "../../DynamicFeild/SocialLinkVaildation";

export const VcardSection = ({
  top,
  type,
  design,
  handleDetail,
  inputs,
  detail,
  setInputs,
  setDetail,
}) => {
  const [typeSelected, setTypeSelected] = useState("static");
  const [styleDisplay, setStyleDisplay] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const { currentUserId } = userState;
  const [modalShow1, setModalShow1] = useState(false);
  const [btnObj, setBtnObj] = useState({
    name: "Learn more",
    link: "",
  });
  const [socialName, setSocialsName] = useState("");
  const [error, setError] = useState(false);
  const [socialItem, setSocialItem] = useState({
    name: "",
    title: "",
    url: "",
  });
  const [socialItemList, setSocialItemList] = useState([]);
  const addSocial = (f) => {
    if (inputs.socialLinks.some((item) => item.name === f)) {
      setError(true);
      return;
    } else if (
      inputs.socialLinks.length > 0 &&
      inputs.socialLinks.some((item) => item?.url?.length === 0)
    ) {
      setError(true);
      return;
    } else {
      setInputs((prev) => {
        return {
          ...prev,
          socialLinks: [...inputs?.socialLinks, { name: f, title: f, url: "" }],
        };
      });
      setSocialsName(f);
      setError(false);
    }
  };
  const removeSocial = (f) => {
    setInputs((prev) => {
      return {
        ...prev,
        socialLinks: inputs?.socialLinks?.filter((item) => item?.name !== f),
      };
    });
    setSocialItemList(socialItemList?.filter((item) => item?.name !== f));
  };
  const socialLink = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const data = {
      name: name,
      title: name,
      url: value,
    };
    setSocialItem(data);
    setError(false);
  };

  const saveSocial = (s) => {
    if (socialItem?.url?.length === 0) {
      setError(true);
      return;
    }
    if (SocialLInkValidation(socialName, socialItem)) {
      setSocialItemList((prev) => [...prev, socialItem]);
      setSocialItem({
        name: "",
        title: "",
        url: "",
      });
      setError(false);
    } else {
      setError(true);
      toast.error(`Please Enter Right ${socialName} Url`);
    }
  };
  useEffect(() => {
    setDetail((prev) => {
      return {
        ...prev,
        socialLinks: socialItemList,
      };
    });
    setInputs((prev) => {
      return {
        ...prev,
        socialLinks: socialItemList,
      };
    });
  }, [socialItemList]);

  const vcardDataHandler = (e) => {
    handleDetail(e);
    let path = window.location.pathname;
    if (
      path.includes("user/create/feild/dynamic/vcardDynamic") ||
      path.includes("user/create/feild/static/vcardStatic")
    ) {
      setInputs((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else {
      setDetail((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    document.title = "VCard - Section";
    let path = window.location.pathname;
    if (path.includes("user/create/feild/dynamic/vcardDynamic")) {
      setStyleDisplay(true);
    }
  }, [window.location.pathname]);

  const btnChangeHandler = (e) => {
    setBtnObj((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    setDetail((prevState) => ({
      ...prevState,
      button: btnObj,
    }));
    setInputs((prevState) => ({
      ...prevState,
      button: btnObj,
    }));
  }, [btnObj, setDetail, setInputs]);
  const ButtonOption = [
    { value: "Learn more", label: "Learn more" },
    { value: "Apply now", label: "Apply now" },
  ];

  return (
    <div className="vcardSectionContainer mb-3">
      <ModalDynamic
        show={modalShow1}
        description="Please Login QR Sign Up to Use V-CARD Dynamic Card QR Code"
        buttonText="SIGN UP"
        link="/signup"
        onHide={() => setModalShow1(false)}
      />
      {top && (
        <div className="top">
          <div
            className={typeSelected === "static" ? "selected item" : "item"}
            onClick={() => setTypeSelected("static")}
          >
            Static QR Code
          </div>
          <div
            className={typeSelected === "dynamic" ? "selected item" : "item"}
            onClick={() =>
              currentUserId ? navigate("/user/home") : setModalShow1(true)
            }
          >
            Dynamic QR Code{" "}
            <i className="fas fa-crown" style={{ marginLeft: "5px" }}></i>
          </div>
        </div>
      )}

      <div
        className={
          !styleDisplay ? "formsection d-flex flex-column" : "formsection"
        }
      >
        <div className="heading">VCard QR code</div>
        {styleDisplay && <CardDesign />}
        {styleDisplay && (
          <>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="heading m-0">
                  <img  loading="lazy"   src={Binfo} alt="Cicon" width="20px" height="20px" />
                  <span className="headtag">Basic Information</span>
                </div>
              </AccordionSummary>
              <hr />
              <AccordionDetails>
                <div className="inputItem">
                  <label htmlFor="name">
                    Name of your QR Code
                    <span className="asterik">*</span>
                  </label>
                  <input
                    className="input"
                    placeholder="QR Code name"
                    type="text"
                    required
                    name="qrName"
                    value={inputs?.qrName || ""}
                    onChange={vcardDataHandler}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    {/* <div className="heading">Basic Information</div> */}
                    <label htmlFor="FirstName">First Name</label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.firstName || inputs?.firstName}
                      className="input"
                      required
                      type="text"
                      id="FirstName"
                      placeholder="Owen Dennis"
                      name="firstName"
                    />
                  </div>
                  <div className="inputItem">
                    <label htmlFor="FirstName">
                      Last Name
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.lastName || inputs?.lastName}
                      className="input"
                      type="text"
                      required
                      id="LastName"
                      placeholder="Gordon"
                      name="lastName"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    <label htmlFor="Contact">
                      Mobile
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.mobileNumber || inputs?.mobileNumber}
                      className="input"
                      type="number"
                      min="0"
                      required
                      id="Contact"
                      placeholder="+91 1234554321"
                      name="mobileNumber"
                    />
                  </div>
                  <div className="inputItem">
                    <label htmlFor="Contact">
                      Phone
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.phoneNumber || inputs?.phoneNumber}
                      className="input"
                      type="number"
                      min="0"
                      required
                      id="phone"
                      placeholder="+91 1234554321"
                      name="phoneNumber"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    <label htmlFor="fax">
                      Fax
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.faxNumber || inputs?.faxNumber}
                      className="input"
                      type="number"
                      min="0"
                      required
                      id="fax"
                      placeholder="Fax"
                      name="faxNumber"
                    />
                  </div>
                  <div className="inputItem">
                    <label htmlFor="Email">
                      Email
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.email || inputs?.email}
                      className="input"
                      type="email"
                      required
                      placeholder="Owen Dennis Gordon"
                      name="email"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    <label htmlFor="Company">
                      Company
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.company || inputs?.company}
                      className="input"
                      type="text"
                      required
                      id="Company"
                      placeholder="Owen Dennis Gordon"
                      name="company"
                    />
                  </div>
                  <div className="inputItem">
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.job || inputs?.job}
                      className="input"
                      type="text"
                      required
                      id="position"
                      placeholder="Intelligence Analyst"
                      name="job"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    <label htmlFor="Street">
                      Street
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.streetAddress || inputs?.streetAddress}
                      className="input"
                      type="text"
                      required
                      id="Street"
                      placeholder="Crp Hall,Connaught Place New Delhi"
                      name="streetAddress"
                    />
                  </div>
                  <div className="inputItem">
                    <label htmlFor="City">
                      City
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.cityAddress || inputs?.cityAddress}
                      className="input"
                      type="text"
                      required
                      id="City"
                      placeholder="Crp Hall,Connaught Place New Delhi"
                      name="cityAddress"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    <label htmlFor="zip">
                      Pincode
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.zipAddress || inputs?.zipAddress}
                      className="input"
                      type="number"
                      min="0"
                      required
                      id="zip"
                      placeholder="Pincode"
                      name="zipAddress"
                    />
                  </div>
                  <div className="inputItem">
                    <label htmlFor="State">
                      State
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.stateAddress || inputs?.stateAddress}
                      className="input"
                      type="text"
                      required
                      id="State"
                      placeholder="Crp Hall,Connaught Place New Delhi"
                      name="stateAddress"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    <label htmlFor="Country">
                      Country
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.countryAddress || inputs?.countryAddress}
                      className="input"
                      type="text"
                      required
                      id="CouWebsite"
                      placeholder="Crp Hall,Connaught Place New Delhi"
                      name="countryAddress"
                    />
                  </div>
                  <div className="inputItem">
                    <label htmlFor="Website">
                      Website
                      <span className="asterik">*</span>
                    </label>
                    <input
                      onChange={vcardDataHandler}
                      value={detail?.websiteLink || inputs?.websiteLink}
                      className="input"
                      type="url"
                      required
                      id="Website"
                      placeholder="www.yourwebsite.com"
                      name="websiteLink"
                    />
                  </div>
                </div>
                <div className="inputItem">
                  <label htmlFor="Website">
                    Description
                    <span className="asterik">*</span>
                  </label>
                  <textarea
                    rows="4"
                    cols="50"
                    style={{ height: "auto" }}
                    onChange={vcardDataHandler}
                    value={detail?.description || inputs?.description}
                    className="input"
                    type="text"
                    required
                    id="description"
                    placeholder="Seeking for freelance work with Over 10 years of Intelligence Analyst experience. Feel free to get in touch!"
                    name="description"
                    maxLength="200"
                  />
                  <h6>{detail?.description?.length} 0 / 200 characters</h6>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="inputItem">
                    <label htmlFor="website">
                      Button Name
                      <span className="asterik">*</span>
                    </label>
                    <div
                      className="multiInputContainer"
                      style={{ width: "200px" }}
                    >
                      <div className="multiInputItem">
                        <CreatableSelect
                          isClearable
                          options={ButtonOption}
                          type="text"
                          required
                          name="name"
                          id="sale"
                          onChange={(e) =>
                            setBtnObj({
                              ...btnObj,
                              name: e.label,
                            })
                          }
                          value={{
                            label: inputs?.button?.name,
                            value: inputs?.button?.name,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mx-4"></div>
                  <div className="inputItem w-100 ml-2">
                    <label htmlFor="title">
                      Button URL
                      <span className="asterik">*</span>
                    </label>
                    <input
                      className="input"
                      id="title"
                      type="text"
                      pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})"
                      required
                      value={inputs?.button?.link}
                      name="link"
                      onChange={btnChangeHandler}
                      placeholder="www.redirect.com"
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="heading m-0">
                  <img  loading="lazy"   src={Like} alt="Cicon" width="20px" height="20px" />
                  <span className="headtag">Social Media Channels</span>
                </div>
              </AccordionSummary>
              <hr />
              <AccordionDetails>
                <div className="Cards-input-bg">
                  <div className="inputItem" style={{ width: "100%" }}>
                    <div className="socialContainer">
                      <label htmlFor="feature">Add social media profile</label>
                      <div className="desc">
                        Click on the icon to add social media profile.
                      </div>
                      <div className="socialIconContainer">
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("facebook")}
                          title="facebook"
                        >
                          <i className="fab fa-facebook"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("twitter")}
                          title="twitter"
                        >
                          <i className="fab fa-twitter"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("instagram")}
                          title="instagram"
                        >
                          <i className="fab fa-instagram"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("whatsapp")}
                          title="whatsapp"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("tiktok")}
                          title="tiktok"
                        >
                          <i className="fab fa-tiktok"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("snapchat")}
                          title="snapchat"
                        >
                          <i className="fab fa-snapchat"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("youtube")}
                          title="youtube"
                        >
                          <i className="fab fa-youtube"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("telegram")}
                          title="telegram"
                        >
                          <i className="fab fa-telegram"></i>
                        </div>

                        <div
                          className="socialIcon"
                          onClick={() => addSocial("yelp")}
                          title="yelp"
                        >
                          <i className="fab fa-yelp"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("google")}
                          title="google"
                        >
                          <i className="fab fa-google"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("pinterest")}
                          title="pinterest"
                        >
                          <i className="fab fa-pinterest"></i>
                        </div>
                        <div
                          className="socialIcon"
                          style={{
                            display: socialName === "facebook" ? "none" : "",
                          }}
                          onClick={() => addSocial("linkedin")}
                          title="linkedin"
                        >
                          <i className="fab fa-linkedin"></i>
                        </div>
                        <div
                          className="socialIcon"
                          onClick={() => addSocial("xing")}
                          title="xing"
                        >
                          <i className="fab fa-xing"></i>
                        </div>

                        <div
                          className="socialIcon"
                          onClick={() => addSocial("vimeo")}
                          title="vimeo"
                        >
                          <i className="fab fa-vimeo"></i>
                        </div>

                        <div
                          className="socialIcon"
                          onClick={() => addSocial("reddit")}
                          title="reddit"
                        >
                          <i className="fab fa-reddit"></i>
                        </div>

                        <div
                          className="socialIcon"
                          onClick={() => addSocial("github")}
                          title="github"
                        >
                          <i className="fab fa-github"></i>
                        </div>

                        <div
                          className="socialIcon"
                          onClick={() => addSocial("spotify")}
                          title="spotify"
                        >
                          {" "}
                          <i className="fab fa-spotify"></i>
                        </div>

                        <div
                          className="socialIcon"
                          onClick={() => addSocial("applemusic")}
                          title="applemusic"
                        >
                          {" "}
                          <i className="fab fa-applemusic"></i>
                        </div>
                      </div>

                      <div className="socialLinkContainer">
                        {inputs?.socialLinks?.map((s) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="socialLinkItem"
                              key={s.name}
                            >
                              <SocialIcon
                                style={{
                                  width: "25px",
                                  height: "25px",
                                }}
                                network={s.name}
                              />
                              <input
                                type="text"
                                placeholder={s.name}
                                name={s.name}
                                onChange={(e) => socialLink(e)}
                                style={{
                                  borderColor: error ? "red" : "gray",
                                }}
                              />
                              {socialItemList?.filter(
                                (item) => item?.name === s.name
                              )?.length === 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <i
                                    onClick={() => saveSocial(s)}
                                    className="fas fa-check"
                                    style={{
                                      color: "green",
                                      marginRight: "20px",
                                    }}
                                  ></i>
                                  <i
                                    className="fas fa-times"
                                    onClick={() => removeSocial(s.name)}
                                    style={{
                                      color: "red",
                                    }}
                                  ></i>
                                </div>
                              ) : (
                                <i
                                  className="fas fa-times"
                                  onClick={() => removeSocial(s.name)}
                                  style={{
                                    color: "red",
                                  }}
                                ></i>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {!styleDisplay && (
          <>
            <div className="d-flex gap-2 gap-md-0">
              <div className="inputItem">
                {/* <div className="heading">Basic Information</div> */}
                <label htmlFor="FirstName">
                  Your Name<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={
                    detail?.firstName || inputs?.fullName.split(" ")[0]
                  }
                  className="input"
                  required
                  type="text"
                  id="FirstName"
                  placeholder="First Name"
                  name="firstName"
                />
              </div>
              <div className="inputItem">
                <input
                  onChange={vcardDataHandler}
                  defaultValue={
                    detail?.lastName || inputs?.fullName.split(" ")[1]
                  }
                  className="input"
                  required
                  type="text"
                  id="LastName"
                  placeholder="Last Name"
                  name="lastName"
                />
              </div>
            </div>

            <div className="inputItem" style={{ width: "95%" }}>
              <label htmlFor="Contact">
                Contact<span className="asterik">*</span>
              </label>
              <input
                onChange={vcardDataHandler}
                defaultValue={detail?.mobileNumber || inputs?.mobileNumber}
                className="input"
                type="number"
                required
                min="0"
                id="Contact"
                placeholder="Mobile"
                name="mobileNumber"
                style={{ width: "100%" }}
              />
            </div>
            <div className="w-100" style={{ width: "95%" }}></div>
            <div className="d-flex gap-2 gap-md-0">
              <div className="inputItem">
                <label htmlFor="Phone">
                  Phone<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={detail?.phoneNumber || inputs?.phoneNumber}
                  className="input"
                  type="number"
                  required
                  min="0"
                  id="phone"
                  placeholder="Phone"
                  name="phoneNumber"
                />
              </div>
              <div className="inputItem">
                <label htmlFor="Fax">
                  Fax<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={detail?.faxNumber || inputs?.faxNumber}
                  className="input"
                  type="number"
                  required
                  min="0"
                  id="fax"
                  placeholder="Fax"
                  name="faxNumber"
                />
              </div>
            </div>
            <div className="inputItem " style={{ width: "95%" }}>
              <label htmlFor="Email">
                Email<span className="asterik">*</span>
              </label>
              <input
                onChange={vcardDataHandler}
                defaultValue={detail?.email || inputs?.email}
                className="input"
                type="email"
                required
                // type="textCompanyemail"
                placeholder="your@email.com"
                name="email"
              />
            </div>
            <div className="w-100" style={{ width: "95%" }}></div>
            <div className="d-flex gap-2 gap-md-0">
              <div className="inputItem">
                <label htmlFor="Company">
                  Company<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={detail?.company || inputs?.company}
                  className="input"
                  type="text"
                  required
                  id="Company"
                  placeholder="Company"
                  name="company"
                />
              </div>
              <div className="inputItem">
                <label htmlFor="Contact">
                  Position<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={detail?.job || inputs?.job}
                  className="input"
                  required
                  type="text"
                  id="position"
                  placeholder="Your Position"
                  name="job"
                />
              </div>
            </div>

            <div className="w-100" style={{ width: "95%" }}></div>
            <div className="inputItem " style={{ width: "95%" }}>
              <label htmlFor="Street">
                Street<span className="asterik">*</span>
              </label>
              <input
                onChange={vcardDataHandler}
                defaultValue={detail?.streetAddress || inputs?.streetAddress}
                className="input"
                type="text"
                required
                id="Street"
                placeholder="Street Address"
                name="streetAddress"
              />
            </div>
            <div className="w-100"></div>

            <div className="d-flex gap-2 gap-md-0">
              <div className="inputItem">
                <label htmlFor="City">
                  City<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={detail?.cityAddress || inputs?.cityAddress}
                  className="input"
                  type="text"
                  required
                  id="City"
                  placeholder="City Address"
                  name="cityAddress"
                />
              </div>
              <div className="inputItem">
                <label htmlFor="Pincode">
                  Pin Code<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={detail?.zipAddress || inputs?.zipAddress}
                  className="input"
                  type="number"
                  min="0"
                  required
                  id="zip"
                  placeholder="Pincode"
                  name="zipAddress"
                />
              </div>
            </div>
            <div className="d-flex gap-2 gap-md-0">
              <div className="inputItem">
                <label htmlFor="State">
                  State<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={detail?.stateAddress || inputs?.stateAddress}
                  className="input"
                  type="text"
                  id="State"
                  required
                  placeholder="State Address"
                  name="stateAddress"
                />
              </div>
              <div className="inputItem">
                <label htmlFor="Country">
                  Country<span className="asterik">*</span>
                </label>
                <input
                  onChange={vcardDataHandler}
                  defaultValue={
                    detail?.countryAddress || inputs?.countryAddress
                  }
                  className="input"
                  type="text"
                  required
                  id="CouWebsite"
                  placeholder="Country"
                  name="countryAddress"
                />
              </div>
            </div>
            <div className="inputItem" style={{ width: "95%" }}>
              <label htmlFor="Website">
                Website<span className="asterik">*</span>
              </label>
              <input
                onChange={vcardDataHandler}
                defaultValue={detail?.websiteLink || inputs?.websiteLink}
                className="input"
                type="text"
                required
                pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})"
                id="Website"
                placeholder="www.yourwebsite.com"
                name="websiteLink"
              />
            </div>
            {location.pathname !== "/" && (
              <div className="inputItem">
                <label htmlFor="name">
                  Name of your QR Code
                  <span className="asterik">*</span>
                </label>
                <input
                  className="input"
                  placeholder="QR Code Name"
                  type="text"
                  required
                  name="qrName"
                  defaultValue={inputs?.qrName || ""}
                  onChange={vcardDataHandler}
                />
              </div>
            )}
            <div className="inputItem" style={{ width: "95%" }}>
              <label htmlFor="Website">
                Description<span className="asterik">*</span>
              </label>
              <textarea
                onChange={vcardDataHandler}
                defaultValue={detail?.description || inputs?.description}
                rows="4"
                required
                cols="50"
                style={{ height: "auto" }}
                className="input"
                type="text"
                id="description"
                placeholder="About..."
                name="description"
                maxLength="200"
              />
              <h6 className="mt-1">
                {detail?.description
                  ? `${detail.description.length} / 200 characters`
                  : "0 / 200 characters"}
              </h6>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
