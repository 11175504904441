import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Ad } from "../Component/Ad/Ad";
import { Flow } from "../Component/Flow/Flow";
import { Footer } from "../Component/Footer/Footer";
import { Hero } from "../Component/hero/Hero";
import { Ideas } from "../Component/Ideas/Ideas";
import { Navbar } from "../Component/navbar/Navbar";
import { Platform } from "../Component/Platform/Platform";
import { Helmet } from "react-helmet";
import { UserNavbar } from "../Component/user/UserNavbar";
// import Cookies from "js-cookie";
// import { ModalCookies } from "../Component/ModalComponent/ModalComponent";
import { DynamicAndStatic } from "../Component/Dynamic&Static/DynamicAndStatic";
import { QRType } from "../Component/QRType/QRType";
import { WhyUs } from "../Component/WhyUs/WhyUs";
import { PlanAndPricing } from "../Component/Plan&Pricing/PlanAndPricing";
import { KnowAbout } from "../Component/KnowAbout/KnowAbout";
import { ToastContainer, toast } from "react-toastify";
import AdminSidebar from "../Component/AdminSidebar/AdminSidebar";
import "../Component/hero/hero.scss";
import { useSearchParams } from "react-router-dom";

const data = [
  {
    id: 1,
    Ques: "What is a QR Code?",
    Ans: "A QR code is a two-dimensional barcode read by QR scanners, smartphones with a QR code scanner app, and other devices. A QR code can store 4,296 alphanumeric characters. It is the most popular 2d barcode. QR Code is the key to a new cashless society.",
  },
  {
    id: 2,
    Ques: "How can I generate a QR Code?",
    Ans: `
      There are certain ways that you can generate a QR code. One way is to use a QR code generator online. Many websites offer this service, and you must enter the URL or text you want to encode into the QR code. After doing this, the website will generate the QR code for you. Another way to generate a QR code is to use a QR code app. There are many different apps available for both iPhone and Android devices. After downloading and installing the app, you must enter the URL or text you want to encode into the QR code. The app will then generate the QR code for you.
      You can also seamlessly generate a QR code using Microsoft Excel. To do this, download and install the QR Code Generator add-in. Once you have done this, you can generate a QR code by selecting the cell you want to encode and then clicking on the Generate QR Code button.        
      `,
  },
  {
    id: 3,
    Ques: "Is customization possible in QR codes?",
    Ans: `Yes, customization is possible in QR codes. You can change the QR code's colors, size, and shape to match your brand or logo. You can also add a logo or image to the center of the QR code.
      `,
  },
  {
    id: 4,
    Ques: "Is generating QR Codes free?",
    Ans: `Some websites claim to generate QR codes for free, but many have hidden fees or limitations. Some sites may only allow you to create a QR code for a limited time, after which you must pay to download or print it. Others may have a limit on the number of codes you can create or only allow you to create codes with specific features.\
      To be sure you can generate QR codes without hidden costs, it's best to use a paid service. Paid QR code generators typically have more features than free ones, and they don't have any limits on how many codes you can create. Plus, you can download and print your QR codes instantly without waiting for approval.
      `,
  },
  {
    id: 5,
    Ques: "Are QR Code generators safe?",
    Ans: "There is no denying that QR codes are becoming increasingly popular. And, as with anything that gains popularity, there are always safety concerns. So, are QR code generators safe? The short answer is yes, QR code generators are safe. But, as with anything else on the internet, there are always risks associated with using them. The biggest risk is downloading a malicious QR code generator that could infect your device with malware. So, how can you stay safe when using QR code generators? The best way is to use reputable QR code generators from trusted sources. Also, scan the QR code with a trusted reader before using it. It will help ensure that the QR code contains the expected data. Taking basic safety precautions can minimize these risks and enjoy all the benefits of QR codes.",
  },
];

export const Home = () => {
  let subHead = true;
  const userState = useSelector((state) => state.user);
  const currentUserId = userState.currentUserId;
  const [showSidebar, setShowSidebar] = useState(false);
  const [select, setSelect] = useState("");
  // const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // const handleClose = () => {
  //   setShow(false);
  //   Cookies.set("/qr/user/cookie", "/qr/user/cookie?true", {
  //     expires: 60,
  //   });
  // };
  // const handleShow = () => setShow(true);

  const showMessage = () => {
    const donation = searchParams.get("donation");
    if (donation === "success") {
      toast.success("Thank You for the donation", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  // const [isFirst, setIsFirst] = useState(Cookies.get("/qr/user/cookie"));
  useEffect(() => {
    // if (!isFirst) {
    //   setShow(true);
    // }
    showMessage();
  }, []);
  return (
    <>
      <div className="userContainer">
        <Helmet>
          <title>
            Free QR Code Generator: Create Professional QR Codes - QRcodebrew
          </title>
          <meta
            name="description"
            content=" Free QR Code Generator for URL, vCard, and more. Add logo, colors, frames, and download in high print quality. Get your free QR Codes now!."
          />
          <meta
            name="keywords"
            content="best free qr code generator
                        QR Code Generator
                        free qr code generator
                        Professional QR Codes "
          />
          <meta name="theme-color" content="#008f68" />
          <link rel="canonical" href="https://www.qrcodebrew.com/" />
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What is a QR Code ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A QR code is a two-dimensional barcode read by QR scanners, smartphones with a QR code scanner app, and other devices. A QR code can store 4,296 alphanumeric characters. It is the most popular 2d barcode. QR Code is the key to a new cashless society."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is Customization Possible in QR Codes ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "There are certain ways that you can generate a QR code. One way is to use a QR code generator online. Many websites offer this service, and you must enter the URL or text you want to encode into the QR code. After doing this, the website will generate the QR code for you. Another way to generate a QR code is to use a QR code app. There are many different apps available for both iPhone and Android devices. After downloading and installing the app, you must enter the URL or text you want to encode into the QR code. The app will then generate the QR code for you. You can also seamlessly generate a QR code using Microsoft Excel. To do this, download and install the QR Code Generator add-in. Once you have done this, you can generate a QR code by selecting the cell you want to encode and then clicking on the Generate QR Code button."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is customization possible in QR codes ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, customization is possible in QR codes. You can change the QR code's colors, size, and shape to match your brand or logo. You can also add a logo or image to the center of the QR code."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is generating QR Codes free ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some websites claim to generate QR codes for free, but many have hidden fees or limitations. Some sites may only allow you to create a QR code for a limited time, after which you must pay to download or print it. Others may have a limit on the number of codes you can create or only allow you to create codes with specific features. To be sure you can generate QR codes without hidden costs, it's best to use a paid service. Paid QR code generators typically have more features than free ones, and they don't have any limits on how many codes you can create. Plus, you can download and print your QR codes instantly without waiting for approval."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Are QR Code Generators safe ?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "There is no denying that QR codes are becoming increasingly popular. And, as with anything that gains popularity, there are always safety concerns. So, are QR code generators safe? The short answer is yes, QR code generators are safe. But, as with anything else on the internet, there are always risks associated with using them. The biggest risk is downloading a malicious QR code generator that could infect your device with malware. So, how can you stay safe when using QR code generators? The best way is to use reputable QR code generators from trusted sources. Also, scan the QR code with a trusted reader before using it. It will help ensure that the QR code contains the expected data. Taking basic safety precautions can minimize these risks and enjoy all the benefits of QR codes."
                  }
                }
              ]
            }
          `}
          </script>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org/", 
              "@type": "BreadcrumbList", 
              "itemListElement": [{
                "@type": "ListItem", 
                "position": 1, 
                "name": "home page",
                "item": "https://www.qrcodebrew.com/"  
              },{
                "@type": "ListItem", 
                "position": 2, 
                "name": "Beginners Guide",
                "item": "https://www.qrcodebrew.com/qr-code-beginners"  
              },{
                "@type": "ListItem", 
                "position": 3, 
                "name": "Business card",
                "item": "https://www.qrcodebrew.com/qr-code-business-card"  
              },{
                "@type": "ListItem", 
                "position": 4, 
                "name": "Digital media",
                "item": "https://www.qrcodebrew.com/qr-code-digital-media"  
              },{
                "@type": "ListItem", 
                "position": 5, 
                "name": "marketing card",
                "item": "https://www.qrcodebrew.com/qr-code-marketing-card"  
              },{
                "@type": "ListItem", 
                "position": 6, 
                "name": "solution card",
                "item": "https://www.qrcodebrew.com/qr-code-solution-card"  
              },{
                "@type": "ListItem", 
                "position": 7, 
                "name": "pricing",
                "item": "https://www.qrcodebrew.com/pricing"  
              },{
                "@type": "ListItem", 
                "position": 8, 
                "name": "support",
                "item": "https://www.qrcodebrew.com/help"  
              },{
                "@type": "ListItem", 
                "position": 9, 
                "name": "about us",
                "item": "https://www.qrcodebrew.com/about"  
              }]
            }
            `}
          </script>
        </Helmet>

        {currentUserId ? (
          <UserNavbar
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
          />
        ) : (
          <Navbar subHead={subHead} />
        )}
        <div className="userWrapper pt-0">
          {/* cookies modal */}
          {/* <ModalCookies
                        show={show}
                        onClose={handleClose}
                        onShow={handleShow}
                    /> */}
          <div className={showSidebar ? "userLeft show" : "userLeft hide"}>
            <AdminSidebar admin={false} setSelect={setSelect} select={select} />
          </div>
          <div style={{ backgroundColor: "#FFFFFF" }} className="userRight">
            {/* <Ad /> */}
            <Hero />
            <Ad />
            <Ideas />
            <Ad />
            <Platform />
            <Ad />
            <Flow />
            <Ad />
            <DynamicAndStatic />
            <Ad />
            <QRType />
            <Ad />
            <WhyUs />
            <Ad />
            <PlanAndPricing />
            <Ad />
            <KnowAbout data={data} />
            <Footer color={true} />
          </div>
        </div>
      </div>
      {/* cookies modal */}
      {/* <ModalCookies
                show={show}
                onClose={handleClose}
                onShow={handleShow}
            /> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
