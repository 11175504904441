import { Link } from "react-router-dom";
import "./dynamic.scss";
const DynamicCard = () => {
  return (
    <div>
      <h1 className="title-dynamic pb-3">Dynamic QR Code</h1>
      <h5>Please Signup for use Dynamic QR </h5>
      <button className="d-flex align-items-center justify-content-center btn-primary mt-3">
        <Link to="/signup">SIGN UP</Link>
        <i className="fas fa-arrow-right px-3"></i>
      </button>
    </div>
  );
};

export default DynamicCard;
