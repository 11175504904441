import "./platform.scss";
import { Link, useNavigate } from "react-router-dom";
import folder from "../../asset/folder.png";
export const Platform = () => {
    const navigate = useNavigate();
    return (
        <div className="platformContainer">
            <div className="platformWrapper">
                <div>
                    <div className="heading">PLATFORM</div>
                    <div className="desc">
                    Our online <Link style={{fontSize : '21px'}} to="/qr-code-free-with-logo" > free qr code generator with logo </Link> offers an interactive platform loaded with seamless features to get you the best QR code design.{" "}
                    </div>
                </div>
                <div className="cardContainer">
                    <div className="ncard">
                        <div className="imgContainer">
                            <i
                                className="fas fa-palette"
                                style={{ color: "#58A697" }}
                            ></i>
                        </div>
                        <div className="cardHeading">More Design Features</div>
                        <div className="cardDesc">
                            Explore eye-catchy, clear, colorful, and attractive
                            QR code design templates
                        </div>
                    </div>
                    <div className="ncard">
                        <div className="imgContainer">
                            <i
                                className="fas fa-pen"
                                style={{ color: "#58A697" }}
                            ></i>
                        </div>
                        <div className="cardHeading">Dynamic QR Codes</div>
                        <div className="cardDesc">
                            Edit and get your QR code design customized the way
                            you want.
                        </div>
                    </div>
                    <div className="ncard">
                        <div className="imgContainer">
                            <img  loading="lazy"   src={folder} alt="folder" />
                        </div>
                        <div className="cardHeading">More Format Types</div>
                        <div className="cardDesc">
                            Images, PNG, video, PDF, just name it and get your
                            QR Code design formatted the way you want.
                        </div>
                    </div>
                </div>
                <button
                    className="platform-btn"
                    id="platform-btn"
                    onClick={() => navigate("/signUp")}
                >
                    Getting Started
                </button>
            </div>
        </div>
    );
};
