import { publicRequest } from "../../requestMethod";

const initialCardState = {
  user: "",
  // name: "",
  // ownlogo: "",
  // isDefaultLogo: true,
  // colorPrimary: "",
  // colorButton: "",
  // basicCompany: "",
  // button: {
  //     name: "", link: ""
  // },
  // welcomeImage: "",
  // shareButtonActive: true,
  // socialLink: [{name: "", text: "", url: ""}],
  // title: "",
  // description: "",
  // website: "",
  // imageURL: "",
  // frameNumber: "",
  // logoNumber: "",
  // color: "",
  // BGcolor: "",
  // company: "",
  // headline: "",
  // videoURL: [],
  // qrType: "",
  // qrImage: "",
  // isDynamic: true
  cardsResponse: {},
  cardSelected: "url"
};

const cardsReducer = (state = initialCardState, action) => {
  switch (action.type) {
    case "CARD_CREATED":
      try {
        const res = publicRequest.post("/qr/", action.payload);
        state.cardsResponse = res.json();
      } catch (error) {
        console.log(error);
      }

    // eslint-disable-next-line no-fallthrough
    case "OPEN_QR" : 
      return {
        ...state,
        cardSelected: action.payload
      }
    default:
      return state;
  }
};

export default cardsReducer;
