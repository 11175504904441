import { useState } from "react";
import "./StaticCard.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};

export const VcardStatic = ({ inputs }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className="staticCardContainer">
            <div className="staticCardWrapper">
                <div className="staticCardTop"> V-Card</div>
                <div className="infoContainer" style={{ marginLeft: "3em" }}>
                    {inputs?.fullName && (
                        <div className="infoItem">
                            <div className="label">First Name</div>
                            <div className="info">
                                {inputs?.firstName
                                    ? inputs?.firstName
                                    : inputs?.fullName
                                    ? inputs?.fullName.split(" ")[0]
                                    : "First Name"}
                            </div>
                        </div>
                    )}
                    {inputs?.fullName && (
                        <div className="infoItem">
                            <div className="label">Last Name</div>
                            <div className="info">
                                {inputs?.lastName
                                    ? inputs?.lastName
                                    : inputs?.fullName
                                    ? inputs?.fullName?.split(" ")[1]
                                    : "Last Name"}
                            </div>
                        </div>
                    )}
                    {inputs?.company && (
                        <div className="infoItem">
                            <div className="label">Company</div>
                            <div className="info">
                                {!inputs?.company
                                    ? "Company Name"
                                    : inputs?.company}
                            </div>
                        </div>
                    )}
                    {inputs?.job && (
                        <div className="infoItem">
                            <div className="label">Position</div>
                            <div className="info">
                                {!inputs?.job ? "Position" : inputs?.job}
                            </div>
                        </div>
                    )}
                    {inputs?.mobileNumber && (
                        <div className="infoItem">
                            <div className="label">Mobile no.</div>
                            <div className="info">
                                {!inputs?.mobileNumber
                                    ? "8456165132"
                                    : inputs?.mobileNumber}
                            </div>
                        </div>
                    )}
                    {inputs?.phoneNumber && (
                        <div className="infoItem">
                            <div className="label">Phone</div>
                            <div className="info">
                                {!inputs?.phoneNumber
                                    ? "44444444"
                                    : inputs?.phoneNumber}
                            </div>
                        </div>
                    )}
                    {inputs?.faxNumber && (
                        <div className="infoItem">
                            <div className="label">Fax</div>
                            <div className="info">
                                {!inputs?.faxNumber
                                    ? "65462168"
                                    : inputs?.faxNumber}
                            </div>
                        </div>
                    )}
                    {inputs?.email && (
                        <div className="infoItem">
                            <div className="label">Email</div>
                            <div className="info">
                                {!inputs?.email
                                    ? "your@gmail.com"
                                    : inputs?.email}
                            </div>
                        </div>
                    )}
                    {inputs?.websiteLink && (
                        <div className="infoItem">
                            <div className="label">Website</div>
                            <div className="info">
                                {!inputs?.websiteLink
                                    ? "www.user.com"
                                    : inputs?.websiteLink}
                            </div>
                        </div>
                    )}
                    {inputs?.streetAddress && (
                        <div className="infoItem">
                            <div className="label">Street Name</div>
                            <div className="info">
                                {!inputs?.streetAddress
                                    ? "Street Adress"
                                    : inputs?.streetAddress}
                            </div>
                        </div>
                    )}
                    {inputs?.cityAddress && (
                        <div className="infoItem">
                            <div className="label">City Name</div>
                            <div className="info">
                                {!inputs?.cityAddress
                                    ? "City Adress"
                                    : inputs?.cityAddress}
                            </div>
                        </div>
                    )}
                    {inputs?.zipAddress && (
                        <div className="infoItem">
                            <div className="label">Zip Code</div>
                            <div className="info">
                                {!inputs?.zipAddress
                                    ? "Zip Adress"
                                    : inputs?.zipAddress}
                            </div>
                        </div>
                    )}
                    {inputs?.stateAddress && (
                        <div className="infoItem">
                            <div className="label">State</div>
                            <div className="info">
                                {!inputs?.stateAddress
                                    ? "State Adress"
                                    : inputs?.stateAddress}
                            </div>
                        </div>
                    )}
                    {inputs?.countryAddress && (
                        <div className="infoItem">
                            <div className="label">Country</div>
                            <div className="info">
                                {!inputs?.countryAddress
                                    ? "Country Adress"
                                    : inputs?.countryAddress}
                            </div>
                        </div>
                    )}
                </div>
                <div className="space"></div>
                <button
                    style={{
                        height: "2em",
                        // position: "absolute",
                        // left: 0,
                        // bottom: 0,
                    }}
                    onClick={handleOpen}
                >
                    Save to contact
                </button>
            </div>

            {/* modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Your Contact information will be stored in your contact
                        book
                    </Typography>
                    <div className="d-flex justify-content-end mt-2">
                        <button
                            onClick={handleClose}
                            style={{
                                color: "white",
                                backgroundColor: "rgb(219, 157, 72)",
                                padding: "5px",
                                borderRadius: "5px",
                                border: "none",
                            }}
                        >
                            Ok
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};
