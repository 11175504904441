import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import "../../style/user/UserNavbar/userNavbar.scss";
import {Link} from 'react-router-dom'
function NotificationCard() {
  return (
    <>
        <div className="container notification-card py-2">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi ex minima maiores ratione ut quaerat nobis quisquam possimus, quo, maxime dolorem libero quas architecto laboriosam, fuga doloremque autem iste atque?</p>
                            <span className="py-2 d-flex justify-content-between "><p>mar,4 2021</p> <Link to={'#'} className="text-decoration-none">Get Inspired <ArrowRightAltIcon/> </Link> </span>
                          </div>

    </>
  )
}

export default NotificationCard