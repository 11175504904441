import { useState, useRef } from "react";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";
import "./Images.scss";
import { ShareModal } from "../ShareModal/ShareModal";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { useSelector } from "react-redux";
import img1 from "./image/image1.png";
import img2 from "./image/image2.png";
import img3 from "./image/image3.png";
import img4 from "./image/image4.png";
import img5 from "./image/image5.png";

export const Images = ({ inputs, qrFor }) => {
    const { dynamicColor, textColor, imgURL } = useSelector(
        (state) => state.url
    );

    let path = window.location.pathname;
    const [isShow, setIsShow] = useState(false);

    const [state, setState] = useState(false);
    const [popupOptions, setPopupOtions] = useState({
        description:
            "By tapping here, users are navigated to the linked social media profile or website.",
    });
    const containerRef = useRef();

    return (
        <div
            className="imagesContainer"
            ref={containerRef}
            style={{ overflow: isShow || state ? "hidden" : "" }}
        >
            <div
                className="imagesWrapper"
                style={{
                    backgroundColor:
                        dynamicColor?.color2 || inputs?.color || "#ffffff",
                }}
            >
                <div
                    className="top"
                    style={{
                        backgroundColor:
                            dynamicColor?.color1 ||
                            inputs?.BGcolor ||
                            "#112B3C",
                    }}
                >
                    <div className="iconContainer">
                        {/* <ShareIcon className='shareIcon' /> */}
                        <ShareModal state={state} />
                    </div>
                    <div
                        className="heading"
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        {!inputs?.title ? "Summer Sale" : inputs?.title}
                    </div>
                    <div
                        className="desc"
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        {!inputs?.description
                            ? "check out our photo galery to discover our best suites, services and localities, A Perfect stay wait you."
                            : inputs?.description}
                    </div>

                    <div className="button1 d-flex justify-content-center ">
                        <button
                            className="get-ticket-btn"
                            style={{
                                backgroundColor:
                                    dynamicColor?.color2 ||
                                    inputs?.color ||
                                    "#5e55b9",
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#fff",
                                height: "40px",
                                minWidth: "100px",
                            }}
                            onClick={() => {
                                window.open(
                                    inputs?.button?.link.includes("http")
                                        ? `${inputs?.button?.link}`
                                        : `https://${inputs?.button?.link}/`,
                                    "_blank"
                                );
                            }}
                        >
                            {inputs?.button?.name || "Button"}
                        </button>
                    </div>
                </div>

                <BusinessPopUP
                    show={isShow}
                    onHide={() => setIsShow(false)}
                    {...popupOptions}
                    ref={containerRef}
                />

                <div className="imagesContainer">
                    <SimpleReactLightbox>
                        <SRLWrapper>
                            {path === "/user/create" && (
                                <div className="image">
                                    <img  loading="lazy"   className="Pimg" src={img5} alt="5" />
                                </div>
                            )}
                            {path ===
                                "/user/create/feild/dynamic/imageDynamic" &&
                                !imgURL &&
                                inputs?.imageURL?.length === 0 && (
                                    <>
                                        <div className="image">
                                            <img  loading="lazy"  
                                                className="img1"
                                                src={img1}
                                                alt="1"
                                                style={{ transform: "none" }}
                                            />
                                        </div>
                                        <div className="image">
                                            <img  loading="lazy"  
                                                className="img2"
                                                src={img2}
                                                alt="2"
                                            />
                                        </div>
                                    </>
                                )}
                            {/* upload image */}
                            {inputs?.imageURL?.length > 0 ? (
                                inputs.imageURL.map((data, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="image"
                                            style={{
                                                width: "200px",
                                                margin: "-100px auto 120px",
                                            }}
                                        >
                                            <img  loading="lazy"  
                                                src={data}
                                                className="Uimg"
                                                alt="1"
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                /* upload image */
                                <>
                                    <div className="image">
                                        <img  loading="lazy"  
                                            className="img3"
                                            src={img3}
                                            alt="3"
                                        />
                                    </div>
                                    <div className="image">
                                        <img  loading="lazy"  
                                            className="img4"
                                            src={img4}
                                            alt="4"
                                        />
                                    </div>
                                </>
                            )}
                        </SRLWrapper>
                    </SimpleReactLightbox>
                </div>
            </div>
        </div>
    );
};
