import { REGISTER_FAIL, REGISTER_START, REGISTER_SUCCESS } from "./userType";
import "react-toastify/dist/ReactToastify.css";
import { publicRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import { loggedBy, loginSuccess, loginUserData } from "./userLoginAction";

export const registerStart = () => ({
    type: REGISTER_START,
});
export const registerSuccess = (userId) => ({
    type: REGISTER_SUCCESS,
    payload: userId,
});
export const registerInProgress = (userId) => ({
    type: "REGISTER_IN_PROGRESS",
    payload: userId,
});

export const registerFail = () => ({
    type: REGISTER_FAIL,
});
export const registerFailMessage = (message) => ({
    type: "REGISTER_FAIL_MESSAGE",
    payload: message,
});

export const register = (userDetail, type, setInputOtp) => {
    return async (dispatch) => {
        await dispatch(registerStart());
        try {
            const res =
                type === "basic"
                    ? await publicRequest.post("/user/signup", userDetail)
                    : await publicRequest.post("/user/socialsignup", {
                          firebaseId: userDetail?.token,
                      });
            const user = res.data;
            dispatch(registerInProgress(user));
            console.log(res);
            if (type === "basic") {
                if (
                    res?.data?.message === "Verfication Code Sent Successfully"
                ) {
                    setInputOtp(true);
                    toast.warn("Verfication Code Sent Successfully ,Please check your email.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                localStorage.setItem("accessToken", user.token);
                localStorage.setItem("userId", user.id);
                await dispatch(loginSuccess(user.id));
                await dispatch(loggedBy(user.role));
                await dispatch(
                    loginUserData({ ...user?.user, photo: userDetail?.photo })
                );
            }
        } catch (error) {
            dispatch(registerFail());
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
};
