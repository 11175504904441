import cover from "../../../asset/dynamicCard/appCardCover.png";
import { AiFillApple } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import "./Apps.scss";
import { ShareModal } from "../ShareModal/ShareModal";
import { useSelector } from "react-redux";

export const Apps = ({ inputs }) => {
    const { dynamicColor, textColor, buttonColor, imgURL } = useSelector(
        (state) => state.url
    );
    console.log(inputs);
    return (
        <div className="appsContainer">
            <div
                className="appsWrapper"
                style={{
                    background: `linear-gradient(179.98deg, ${
                        dynamicColor?.color1 || inputs?.BGcolor || "#363D63"
                    } 0.01%, ${
                        dynamicColor?.color2 || inputs?.color || "#BF204D"
                    } 99.99%)`,
                }}
            >
                <div className="app-share-modal">
                    <ShareModal />
                </div>
                <div
                    className="app-content"
                    style={{
                        marginTop: "10px",
                    }}
                >
                    <p
                        className="app-content-title"
                        style={{
                            fontSize: "20px",
                            fontWeight: "bolder",
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        {!inputs?.appName ? "Among Us" : inputs?.appName}
                    </p>
                    <p
                        className="app-content-link"
                        style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            marginTop: "-10px",
                            marginBottom: "10px",
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        {!inputs?.developer ? "Amongus.in" : inputs?.developer}
                    </p>
                </div>
                <div
                    className="app-profile-image"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                    <img  loading="lazy"  
                        src={
                            imgURL ||
                            inputs?.imageURL[0] ||
                            inputs?.applogo ||
                            cover
                        }
                    />
                </div>
                <div className="app-content-bottom">
                    <p
                        className="app-content-bottom-title"
                        style={{
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                            fontWeight: "bolder",
                            fontSize: "20px",
                        }}
                    >
                        {!inputs?.title ? "Among Us" : inputs?.title}
                    </p>
                    <p
                        className="app-content-bottom-desc"
                        style={{
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            fontSize: "12px",
                            fontWeight: 500,
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        {!inputs?.description
                            ? `"Among Us" is a multiplayer game available for Android and iOS mobile devices, Windows computers, and the Nintendo Switch.`
                            : inputs?.description}
                    </p>
                </div>
                <div className="button1 d-flex justify-content-center ">
                    <button
                        className="get-ticket-btn"
                        style={{
                            backgroundColor:
                                buttonColor?.button ||
                                inputs?.BGcolor ||
                                "rgb(54, 61, 99)",
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                            height: "40px",
                            minWidth: "100px",
                            border: "1px solid",
                            borderRadius: "8px",
                        }}
                        onClick={() => {
                            window.open(
                                inputs?.button?.link.includes("http")
                                    ? `${inputs?.button?.link}`
                                    : `https://${inputs?.button?.link}/`,
                                "_blank"
                            );
                        }}
                    >
                        {inputs?.button?.name || "Button"}
                    </button>
                </div>
                <div className="app-content-get">
                    <div
                        className="app-content-app "
                        style={{
                            backgroundColor:
                                buttonColor?.button ||
                                inputs?.button ||
                                "#121b3b",
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                            marginTop: "40px",
                        }}
                    >
                        <div
                            className="row align-items-center"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                window.open(
                                    !inputs?.appStoreLink
                                        ? "https://apps.apple.com/us/app"
                                        : inputs?.appStoreLink,
                                    "_blank"
                                )
                            }
                        >
                            <div className="col-3">
                                <AiFillApple
                                    className="app-content-app-icon mb-2"
                                    style={{
                                        marginTop: -1,
                                        marginRight: 5,
                                    }}
                                />
                            </div>
                            <div className="col-9">
                                <p
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 10,
                                        textAlign: "left",
                                        fontWeight: 400,
                                        lineHeight: 0,
                                        paddingTop: 10,
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#fff",
                                    }}
                                >
                                    Download on the
                                </p>
                                <p
                                    className="app-content-app-text"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#fff",
                                        textAlign: "left",
                                        lineHeight: "13px",
                                    }}
                                >
                                    App Store
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="app-content-play"
                        style={{
                            backgroundColor:
                                buttonColor?.button ||
                                inputs?.button ||
                                "#121b3b",
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#fff",
                        }}
                    >
                        <div
                            className="row align-items-center"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                window.open(
                                    !inputs?.googlePlayLink
                                        ? "https://play.google.com/store/apps/"
                                        : inputs?.googlePlayLink,
                                    "_blank"
                                )
                            }
                        >
                            <div className="col-3">
                                <IoLogoGooglePlaystore
                                    className="app-content-play-icon"
                                    style={{
                                        marginTop: -3,
                                        marginRight: -44,
                                    }}
                                />
                            </div>
                            <div
                                className="col-9"
                                style={{
                                    paddingLeft: "27px",
                                }}
                            >
                                <p
                                    style={{
                                        textDecoration: "none",
                                        fontSize: 10,
                                        textAlign: "left",
                                        fontWeight: 400,
                                        lineHeight: 0,
                                        paddingTop: 10,
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#fff",
                                    }}
                                >
                                    GET IT ON
                                </p>
                                <p
                                    className="app-content-play-text"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#fff",
                                        textAlign: "left",
                                        lineHeight: "13px",
                                    }}
                                >
                                    Play Store
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-bottom-link">
                    <a
                        href={!inputs?.websiteLink ? "" : inputs?.websiteLink}
                        className="app-bottom-link"
                        style={{
                            textDecoration: "none",
                            fontSize: 12,
                            color: textColor?.text || "#fff",
                        }}
                    >
                        {!inputs?.websiteLink
                            ? "www.amongus.in"
                            : inputs?.websiteLink}
                    </a>
                </div>
            </div>
        </div>
    );
};
