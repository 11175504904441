import { useState } from "react";
import cover from "../../../asset/dynamicCard/cover.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import "./Pdf.css";
import { useSelector } from "react-redux";
import { ShareModal } from "../ShareModal/ShareModal";
import CardModal from "../../../Component/CardModal/CardModal";
export const Pdf = ({ inputs, qrInfo }) => {
    const {
        dynamicColor,
        textColor,
        buttonColor,

        imgURL,
        pdfURL,
    } = useSelector((state) => state.url);
    const [show, setShow] = useState(false);
    const [urlshow, setURLShow] = useState(false);
    const [state, setState] = useState(false);
    const handleClose = () => {
        setShow(false);
        setURLShow(false);
    };
    const SharePopup = () => {
        setState(true);
    };
    function OpenUrl() {
        if (qrInfo?.pdfURL) {
            window.open(`${qrInfo?.pdfURL}`);
        } else if (inputs?.pdfURL || pdfURL) {
            window.open(`${inputs?.pdfURL || pdfURL}`);
        }
        setURLShow(false);
    }

    const ModalBody = (
        <>
            <div className="d-flex justify-content-center">
                <ShareModal state={state} />
            </div>
            <hr style={{ color: "grey" }} />
            <h3 className="pdfsharetxt">
                By tapping here, user can share the PDF to various social media
                platforms
            </h3>
            <h5 className="pdfsstxt" onClick={SharePopup}>
                Share PDF
            </h5>
        </>
    );
    const ModalURLBody = (
        <>
            <div className="d-flex justify-content-center">
                <AiFillEye className="eye-icon" size="26" />
            </div>
            <hr style={{ color: "grey" }} />
            <h3 className="pdfsharetxt">
                By tapping here, user can open and view the PDF in full screen
                mode
            </h3>
            <h5 className="pdfsstxt" onClick={OpenUrl}>
                Got it
            </h5>
        </>
    );
    return (
        <div className="pdfContainer">
            <div
                className="pdfWrapper"
                style={{
                    backgroundColor:
                        dynamicColor?.color2 || inputs?.color || "#ffffff",
                    color: textColor?.text || inputs?.text || "#ff886b",
                }}
            >
                <div className="upper-content">
                    <div className="d-flex justify-content-between">
                        <p
                            className="brand-name"
                            style={{
                                color:
                                    qrInfo?.text ||
                                    textColor?.text ||
                                    inputs?.text ||
                                    "#000000",
                            }}
                        >
                            {!inputs?.basicCompany
                                ? qrInfo?.basicCompany
                                    ? qrInfo?.basicCompany
                                    : "Southern Restaurant Menu"
                                : inputs?.basicCompany}
                        </p>
                        <span className="vertical-dot-icon">
                            <BsThreeDotsVertical
                                size="22"
                                onClick={() => setShow(true)}
                                color={
                                    qrInfo?.button ||
                                    buttonColor?.button ||
                                    inputs?.button ||
                                    "#ff886b"
                                }
                            />
                        </span>
                    </div>

                    <CardModal
                        show={show}
                        onHide={handleClose}
                        data={ModalBody}
                    />
                    <CardModal
                        show={urlshow}
                        onHide={handleClose}
                        data={ModalURLBody}
                    />
                    <p
                        className="brand-desc"
                        style={{
                            color:
                                qrInfo?.text ||
                                textColor?.text ||
                                inputs?.text ||
                                "#000000",
                        }}
                    >
                        {!inputs?.description
                            ? qrInfo?.description
                                ? qrInfo?.description
                                : "Come in, hang out and we’ll make you feel right at home."
                            : inputs?.description}
                    </p>
                </div>
                <div className="image-content position-relative">
                    <img  loading="lazy"  
                        className="pdf-image-cover"
                        src={
                            qrInfo?.ownlogo ||
                            inputs?.ownlogo ||
                            imgURL ||
                            cover
                        }
                        alt="imgaa"
                    />
                    <div
                        className="image-text-menu"
                        style={{
                            backgroundColor:
                                qrInfo?.color1 ||
                                dynamicColor?.color1 ||
                                inputs?.BGcolor ||
                                "#ff886b",
                            color:
                                qrInfo?.text ||
                                textColor?.text ||
                                inputs?.text ||
                                "#ffffff",
                        }}
                    >
                        {!inputs?.title
                            ? qrInfo?.title
                                ? qrInfo?.title
                                : "Menu"
                            : inputs?.title}
                    </div>
                    <div
                        className="PDFsidedesign"
                        style={{
                            backgroundColor:
                                qrInfo?.color1 ||
                                dynamicColor?.color1 ||
                                inputs?.BGcolor ||
                                "#ff886b",
                            left: 0,
                            clipPath:
                                "polygon(0px 0%, 100% 10%, 100% 100%, 0% 100%)",
                        }}
                    />
                    <div
                        className="PDFsidedesign"
                        style={{
                            backgroundColor:
                                qrInfo?.color1 ||
                                dynamicColor?.color1 ||
                                inputs?.BGcolor ||
                                "#ff886b",
                            right: 0,
                        }}
                    />
                </div>

                {/* <div className="image-top"></div> */}
                <div
                    className="bottom-content"
                    style={{
                        backgroundColor:
                            qrInfo?.color1 ||
                            dynamicColor?.color1 ||
                            inputs?.BGcolor ||
                            "#ff886b",
                    }}
                >
                    <div className="brand">
                        <a
                            href={
                                !inputs?.websiteLink
                                    ? qrInfo?.websiteLink
                                        ? `${qrInfo?.websiteLink}`
                                        : "WWW.SouthernRestaurant.com"
                                    : `${inputs?.websiteLink}`
                            }
                            className="brand-link"
                            style={{
                                color:
                                    qrInfo?.text ||
                                    textColor?.text ||
                                    inputs?.text ||
                                    "#ffffff",
                            }}
                        >
                            {!inputs?.websiteLink
                                ? qrInfo?.websiteLink
                                    ? `${qrInfo?.websiteLink}`
                                    : "WWW.SouthernRestaurant.com"
                                : `${inputs?.websiteLink}`}
                        </a>
                    </div>
                    <div>
                        <button
                            className="bottom-content-button"
                            onClick={() => setURLShow(true)}
                            style={{
                                backgroundColor:
                                    qrInfo?.button ||
                                    buttonColor?.button ||
                                    inputs?.button ||
                                    "#ffff",
                                textDecoration: "none",
                                color:
                                    qrInfo?.text ||
                                    textColor?.text ||
                                    inputs?.text ||
                                    "#ff886b",
                            }}
                        >
                            <AiFillEye className="eye-icon" size="20" />
                            View PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
