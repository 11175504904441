import ad1 from "../../asset/Ad/ad4.webp";
import ad2 from "../../asset/Ad/ad5.webp";
import ad3 from "../../asset/Ad/ad6.webp";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ad.scss";
import "swiper/swiper.min.css";

export const Ad = () => {
    SwiperCore.use([Autoplay]);

    return (
      <div className="adContainer">
        <Swiper
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          spaceBetween={50}
          slidesPerView={1}
        >
          <SwiperSlide>
            <img  loading="lazy"   src={ad2} alt="" width="auto" height="auto" />
          </SwiperSlide>
          <SwiperSlide>
            <img  loading="lazy"   src={ad1} alt="" width="auto" height="auto" />
          </SwiperSlide>
          <SwiperSlide>
            <img  loading="lazy"   src={ad3} alt="" width="auto" height="auto" />
          </SwiperSlide>
        </Swiper>
      </div>
    );
};
