import "./Ideas.scss";
import { Link, useNavigate } from "react-router-dom";

import ideas1 from "../../asset/ideas1.png";
import ideas2 from "../../asset/ideas2.png";
import ideas3 from "../../asset/ideas3.png";
import arrowleft from "../../asset/right.png";

const data = [
    {
        img: ideas2,
        heading: "Business Cards",
        para: (
            <>
                <Link to="/qr-code-business-card">Business card QR code generator</Link> 
                {' are a fun and easy way to promote your business. You can use them to rank your website, products, or services. Get attractive business cards today from our online QR Code generator.'}
            </>
        ),
        id: "businessCardsHome",
    },
    {
        img: ideas3,
        heading: "Pamphlet",
        // para: "A pamphlet is a thin book with lots of information and beautiful details. You can create interesting pamphlets for marketing with our online QR Code generator.",
        para: (
            <>
                {'A '} <Link to="/qr-code-marketing-card">qr code pamphlet</Link> {'is a thin book with lots of information and beautiful details. You can create interesting pamphlets for marketing with our online QR Code generator.'}
            </>
        ),
        id: "pamphletHome",
    },
    {
        img: ideas1,
        heading: "Catalog",
        para: "Another interesting QR Code design is a catalog filled with attractive images in the form of a catchy book. This design improves the presentation of products.",
        id: "catalogHome",
    },
];

export const Ideas = () => {
    const navigate = useNavigate();
    const handleCard = (heading) => {
        if (heading === "Business Cards") {
            navigate("/qr-code-business-card");
        } else if (heading === "Pamphlet") {
            navigate("/qr-code-marketing-card");
        } else if (heading === "Catalog") {
            navigate("/qr-code-marketing-card");
        }
    };

    return (
        <div className="ideasContainer">
            <div className="ideasWrapper">
                <div className="ideascont">
                    <h2 className="ideasheading">Creative QR Codes Ideas</h2>
                    <div className="ideas--itemContainer">
                        {data?.map((item) => {
                            return (
                                <div
                                    onClick={() => handleCard(item.heading)}
                                    key={item.heading}
                                    id={item?.id}
                                    className="ideas--item"
                                >
                                    <div className="ideas--item_imgContainer">
                                        <img  loading="lazy"  
                                            className="ideasimg"
                                            src={item.img}
                                            alt="Free QR Code Generator"
                                        />
                                    </div>
                                    <h5 className="ideash5">{item.heading}</h5>
                                    <p className="ideasp">{item.para}</p>
                                    <div className="ideasarrow-container">
                                        <img  loading="lazy"  
                                            className="ideasarrow"
                                            src={arrowleft}
                                            alt="right"
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            onClick={() => navigate("/qr-code-solution-card")}
                            className="ideas--item2"
                            id="checkMoreHome"
                        >
                            <div>
                                <h4>Check Out More</h4>
                                <h5 className="ideash5">Creative QR</h5>
                                <h4>Codes Example?</h4>
                            </div>

                            <Link
                                style={{
                                    color: "#58A697",
                                    textDecoration: "none",
                                    fontSize: "20px",
                                }}
                                className="link"
                                to="/signUp"
                            >
                                VIEW MORE &nbsp;{">"}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
