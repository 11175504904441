import "./QRType.scss";

import icon2 from "../../asset/icon2.png";
import icon3 from "../../asset/icon3.png";
import icon4 from "../../asset/icon4.png";
import icon5 from "../../asset/icon5.png";
import icon6 from "../../asset/icon6.png";
import icon7 from "../../asset/icon7.png";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { openMyQrType } from "../../redux/exportAllAction";

const data = [
    {
        img: icon7,
        heading: "URL",
        para: "Just let us know what URL you want to use, and we'll take care of the rest. You won't have access to tracking or editing the code, but it's a quick and easy way to get one made.",
        btnId: "urlQrCode",
    },
    {
        img: icon2,
        heading: "Text",
        para: 'Get high-quality VCards from our end for free from our <a href="/qr-code-educational-institutions">Educational Free QR Code generator</a> and make contact sharing seamless',
        btnId: "textQrCode",
    },

    {
        img: icon3,
        heading: "VCard",
        para: "Get high-quality VCards from our end for free from our free QR Code generator and make contact sharing seamless.",
        btnId: "vCardQrCode",
    },
    {
        img: icon4,
        heading: "Email",
        para: 'Our online <a href="/qr-code-personal-use">Personal QR Code generator </a> will help you generate attractive Email QR Codes for free. They will have an email ID, subject, and message so users can contact you with a simple scan.',
        
        btnId: "emailQrCode",
    },
    {
        img: icon5,
        heading: "Wifi",
        para: `With all credentials, the <a href="/qr-code-digital-media"> WiFi QR code </a> offers a fast and easy way to connect to a WiFi network.  A WiFi QR Code helps the users to join any WiFi network easily.`,
        btnId: "wifiQrCode",
    },
    {
        img: icon6,
        heading: "Twitter",
        para: `<a href="/qr-code-digital-media">Twitter  QR codes</a> are an innovative tool to help businesses and individuals increase their social media engagement. These codes allow users to scan a code and instantly follow an account.`,
        btnId: "twitterQrCode",
    },
];

export const QRType = () => {
    const dispatch = useDispatch();
    const clickHandler = (heading) => {
        window.scrollTo(0, 0);
        dispatch(openMyQrType(String(heading).toLowerCase()));
    };

    return (
        <div className="QRTypeContainer">
            <div className="QRTypeWrapper">
                <div className="QRTypecont">
                    <div className="QRTypeheading">
                        Types of QR codes you can create for free?
                    </div>
                    <h3 className="QRTypedesc">
                        Our free QR Code generator offers the following static
                        codes free of cost. Upscale your business with these QR
                        Codes and get great benefits.
                    </h3>
                    <div className="QRType--itemContainer">
                        {data?.map((item) => {
                            return (
                                <div
                                    key={item.heading}
                                    className="QRType--item"
                                >
                                    <div className="qrtype--top">
                                        <img  loading="lazy"   src={item.img} alt="" />
                                        <h4
                                            style={{
                                                fontWeight: 600,
                                                marginBottom: 0,
                                            }}
                                        >
                                            {item.heading}
                                        </h4>
                                    </div>
                                    <div className="qrtype--middle">
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                // lineHeight: "24px",
                                                fontSize: "17px",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.para,
                                            }}
                                        />
                                    </div>

                                    <Button
                                        onClick={() =>
                                            clickHandler(item.heading)
                                        }
                                        className="qrtype--bottom"
                                        variant="contained"
                                        id={item?.btnId}
                                    >
                                        Create
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
