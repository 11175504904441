import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    colorDynamicButton,
    colorDynamicText,
    setColorBg,
} from "../../redux/QrURL/qraction";
import { TextField } from "@mui/material";
import "./ColorPicker.css";

export const ColorPicker = ({
    colorPrimary,
    colorSecondary,
    setColorPrimary,
    setColorSecondary,
    value,
    name,
}) => {
    const [colors, setColors] = useState({ text: "", button: "" });
    const dispatch = useDispatch();
    const data = useSelector((state) => state);
    let color = { color1: colorPrimary, color2: colorSecondary };
    function colorHandler(e) {
        switch (e.target.name) {
            case "color1":
                color = { color1: e.target.value, color2: colorSecondary };
                setColorPrimary(e.target.value);
                dispatch(setColorBg(color));
                break;
            case "color2":
                color = { color1: colorPrimary, color2: e.target.value };
                setColorSecondary(e.target.value);
                dispatch(setColorBg(color));
                break;
            case "text":
                setColors({ ...colors, text: e.target.value });
                dispatch(colorDynamicText(colors));
                break;
            case "button":
                setColors({ ...colors, button: e.target.value });
                dispatch(colorDynamicButton(colors));
                break;

            default:
                break;
        }
    };

    return (
        <TextField
            id="standard-basic"
            type="color"
            name={name}
            defaultValue={
                name === "color1"
                    ? data?.color?.primaryColor
                    : name === "color2"
                    ? data?.color?.secondaryColor
                    : name === "button"
                    ? data?.color?.buttonColor?.button
                    : data?.color?.textColor
            }
            title="Choose your Frame color"
            onChange={colorHandler}
            label={`Color ${
                value
                    ? value
                    : name === "color1"
                    ? data?.color?.primaryColor
                    : name === "color2"
                    ? data?.color?.secondaryColor
                    : name === "button"
                    ? data?.color?.buttonColor?.button
                    : data?.color?.textColor
            }`}
            variant="outlined"
            value={value}
        />
    );
};
