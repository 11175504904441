import "./StaticCard.scss";

export const UrlStatic = ({ heading, number, message, subject, inputs }) => {



  return (
    <div className="staticCardContainer">
      <div className="url-box">
        <div className="url-bg">
          <a
            className="anchorTag"
            href={!inputs?.websiteLink ? "" : inputs?.websiteLink}
          >
            <input
              className="url-input"
              type="text"
              placeholder={
                !inputs?.websiteLink
                  ? "https://www.website.com"
                  : inputs?.websiteLink
              }
            />
            <a className="add">+</a>
            <a className="pageNum">1</a>
          </a>
        </div>
        <div className="websiteName-box">
          <div className="websiteName">
            {!inputs?.title ? "myqrcodelink.com" : inputs?.title}
          </div>

          <a className="hamburgerDot1"></a>
          <a className="hamburgerDot2"></a>
          <a className="hamburgerDot3"></a>
        </div>
        <div className="skeleton-box">
          <div className="webBorder"></div>
          <div className="skeleton1"></div>
          <div className="skeleton2"></div>
          <div className="skeleton3"></div>
          <div className="skeleton4"></div>
          <div className="skeleton5"></div>
          <div className="skeleton6"></div>
          <div className="skeleton7"></div>
          <div className="skeleton8"></div>
          <div className="skeleton9"></div>
          <div className="skeleton10"></div>
          <div className="skeleton11"></div>
          <div className="skeleton12"></div>
        </div>
      </div>
    </div>
  );
};
