import { useEffect, useState } from "react";
import { BannerIcon } from "./index.js";

const Banner = ({ Title, des }) => {
  const [bannerItem, setBannerItem] = useState(BannerIcon);
  const [bannarItem2, setBannerItem2] = useState(BannerIcon);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setBannerItem2(BannerIcon.slice(9, BannerIcon?.length));
    } else {
      setBannerItem(BannerIcon.slice(0, 8));
    }
  }, [open]);

  console.log(bannarItem2);
  
  return (
    <div className="banner">
      <h1>
        QR Codes on <span>{Title}</span>
      </h1>
      <h6>{des}</h6>
      <div className="circle-tag3">
        {BannerIcon.map((item) => (
          <div className="" key={item.id}>
            <span>{item.icon}</span>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
      <div className="circle-tag">
        {bannerItem.map((item) => (
          <div className="" key={item.id}>
            <span>{item.icon}</span>
            <p>{item.title}</p>
          </div>
        ))}

        {!open && (
          <div
            onClick={() => setOpen((prev) => !prev)}
            className="menuItemBanner"
          >
            <i class="fa fa-bars" aria-hidden="true" />
          </div>
        )}
      </div>
      {open && (
        <div className="circle-tag2">
          {bannarItem2.map((item) => (
            <div className="" key={item.id}>
              <span>{item.icon}</span>
              <p>{item.title}</p>
            </div>
          ))}

          <div
            onClick={() => setOpen((prev) => !prev)}
            className="menuItemBanner"
          >
            <i class="fa fa-times" aria-hidden="true" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
