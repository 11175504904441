export const QRUrl = (url) => ({
    type: "QRURL",
    payload: url,
});
export const QREdit = (id) => ({
    type: "EDITQR",
    payload: id,
});
export const QRLinks = (links) => ({
    type: "Links",
    payload: links,
});
export const FolderName = (folderName) => ({
    type: "FILTER_BY_FOLDER",
    payload: folderName,
});
export const setColorImage = (color) => ({
    type: "COLOR_SET",
    payload: color,
});
export const setBtnColor = (color) => ({
    type: "BUTTON_COLOR_SET",
    payload: color,
});
export const imageUrl = (imageUrl) => ({
    type: "IMAGE_URL",
    payload: imageUrl,
});
export const pdfUrl = (pdfUrl) => ({
    type: "PDF_URL",
    payload: pdfUrl,
});
export const QRDelete = (id) => ({
    type: "REMOVE_FROM_HOME",
    payload: id,
});
export const showAllCards = (qr) => ({
    type: "SHOW_ALL_CARDS",
    payload: qr,
});

export const setColorBg = (colorObj) => ({
    type: "SET_COLOR_BG",
    payload: colorObj,
});
export const colorDynamic = (colorObj) => ({
    type: "SET_COLOR_DYNAMIC",
    payload: colorObj,
});
export const colorDynamicBackground = (colorObj) => ({
    type: "SET_COLOR_BACKGROUND",
    payload: colorObj,
});
export const colorDynamicText = (colorObj) => ({
    type: "SET_COLOR_TEXT",
    payload: colorObj,
});
export const colorDynamicButton = (colorObj) => ({
    type: "SET_COLOR_BUTTON",
    payload: colorObj,
});

export const timeData = (time) => ({
    type: "TIME_DATA",
    payload: time,
});
export const removeDate = (time) => ({
    type: "TIME_DATA_REMOVE",
    payload: time,
});
export const updateDate = (time) => ({
    type: "TIME_DATA_UPDATE",
    payload: time,
});
export const socialAction = (social) => ({
    type: "SOCIAL_DATA_UPDATE",
    payload: social,
});
export const socialActionAll = (social) => ({
    type: "SOCIAL_DATA",
    payload: social,
});
export const feedBackForm = (social) => ({
    type: "FEEDBACK_DATA",
    payload: social,
});
export const feedBackInput = (social) => ({
    type: "FEEDBACK_DATA_UPDATE",
    payload: social,
});
export const clearData = () => ({
    type: "CLEAR_DATA_QR",
    payload: "",
});
export const setRatingStyle = (value) => ({
    type: "SET_RATING_STYLE",
    payload: value,
});
export const featuresData = (value) => ({
    type: "SET_FEATURES",
    payload: value,
});
