import { useEffect, useState } from "react";
import "./KnowAbout.scss";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography } from "@mui/material";
import bottom from "../../asset/bottom.png";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "#ffffff",
  marginBottom: "10px",
  borderRadius: "20px",
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  ".MuiAccordionSummary-root": {
    border: "none",
    flexDirection: "row",
    display: "-webkit-inline-box",
  },
  ".MuiAccordionSummary-content": {
    marginRight: "40px",
    fontWeight: "bold",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "20px",

  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const data = [
  {
    id: 1,
    Ques: "What is a QR Code?",
    Ans: "A QR code is a two-dimensional barcode read by QR scanners, smartphones with a QR code scanner app, and other devices. A QR code can store 4,296 alphanumeric characters. It is the most popular 2d barcode. QR Code is the key to a new cashless society.",
  },
  {
    id: 2,
    Ques: "How can I generate a QR Code?",
    Ans: `
      There are certain ways that you can generate a QR code. One way is to use a QR code generator online. Many websites offer this service, and you must enter the URL or text you want to encode into the QR code. After doing this, the website will generate the QR code for you. Another way to generate a QR code is to use a QR code app. There are many different apps available for both iPhone and Android devices. After downloading and installing the app, you must enter the URL or text you want to encode into the QR code. The app will then generate the QR code for you.
      You can also seamlessly generate a QR code using Microsoft Excel. To do this, download and install the QR Code Generator add-in. Once you have done this, you can generate a QR code by selecting the cell you want to encode and then clicking on the Generate QR Code button.        
      `,
  },
  {
    id: 3,
    Ques: "Is customization possible in QR codes?",
    Ans: `Yes, customization is possible in QR codes. You can change the QR code's colors, size, and shape to match your brand or logo. You can also add a logo or image to the center of the QR code.
      `,
  },
  {
    id: 4,
    Ques: "Is generating QR Codes free?",
    Ans: `Some websites claim to generate QR codes for free, but many have hidden fees or limitations. Some sites may only allow you to create a QR code for a limited time, after which you must pay to download or print it. Others may have a limit on the number of codes you can create or only allow you to create codes with specific features.\
      To be sure you can generate QR codes without hidden costs, it's best to use a paid service. <a href="/pricing" >Paid QR code generators</a> typically have more features than free ones, and they don't have any limits on how many codes you can create. Plus, you can download and print your QR codes instantly without waiting for approval.
      `,
  },
  {
    id: 5,
    Ques: "Are QR Code generators safe?",
    Ans: "There is no denying that QR codes are becoming increasingly popular. And, as with anything that gains popularity, there are always safety concerns. So, are QR code generators safe? The short answer is yes, QR code generators are safe. But, as with anything else on the internet, there are always risks associated with using them. The biggest risk is downloading a malicious QR code generator that could infect your device with malware. So, how can you stay safe when using QR code generators? The best way is to use reputable QR code generators from trusted sources. Also, scan the QR code with a trusted reader before using it. It will help ensure that the QR code contains the expected data. Taking basic safety precautions can minimize these risks and enjoy all the benefits of QR codes.",
  },
];

const FaqBusinessData = [
  {
    id: 1,
    Ques: "How are QR codes used in education",
    Ans: `QR codes are used in education to quickly and easily share information with students, such as links to websites, online resources, or class materials. They can also be used for interactive activities like scavenger hunts or quizzes.`,
  },
  {
    id: 2,
    Ques: "How do you scan QR codes for school?",
    Ans: `To scan QR codes for school, you can use a smartphone or tablet with a built-in camera. Simply open a QR code scanner app or your device's camera app, point it at the QR code, and it will automatically detect and provide the relevant information or link        `,
  },
  {
    id: 3,
    Ques: "What are the advantage of QR code to the students?",
    Ans: `The advantages of QR codes for students include easy access to digital content, convenience in accessing resources, engagement in interactive learning activities, and the ability to save time by quickly accessing information.`,
  },
  {
    id: 4,
    Ques: "What are the purposes of QR code?",
    Ans: `The purposes of QR codes are diverse and include information sharing, marketing, tracking products, making payments, accessing websites, providing contact details, and enhancing interactive learning experiences in education
    `,
  },
  //   {
  //     id: 5,
  //     Ques: "Are there any best practices for using QR codes on business cards?",
  //     Ans: "Yes, ensure the QR code is large enough to scan, and test it with multiple smartphones to ensure it works correctly. Also, ensure the website or contact information linked to the QR code is up-to-date and relevant to your business. Finally, avoid using QR codes for sensitive information such as passwords or financial data.",
  //   },
];

const DigitalMediaData = [
  {
    id: 1,
    Ques: "Will my QR Code expire?",
    Ans: `No. A Social Media QR Code that is created with QRcodebrew will never expire for as long as you have a running subscription. You can pause or activate it as you please by just logging into your account.
        `,
  },
  {
    id: 2,
    Ques: "How do I scan a social media QR Code?",
    Ans: `The best way to test if your device can scan any QR Code is to open the camera app and hold it over a QR Code. If a notification pops up, your device is equipped with a built-in QR Code scanner. If nothing happened, check your settings to see if QR Code scanning is enabled. If all else fails, simply download a trusted QR Code reader from your app store.`,
  },
  {
    id: 3,
    Ques: "Can I customize the appearance of my QR code?",
    Ans: `Yes, many QR code generators allow you to customize your QR code's color, shape, and design. It can help make your business card more visually appealing and stand out to potential clients.`,
  },
  {
    id: 4,
    Ques: "What happens if my social media links changed?",
    Ans: `Do not worry. Social Media QR Code is Dynamic, which means the contents and links can be replaced or edited at any time without needing to reprint or regenerate a new QR Code. Simply log in to your account, select which links to update or replace and hit save.`,
  },
];

const PersonalUse = [
  {
    id: 1,
    Ques: "How do I create a personal QR code?",
    Ans: `Creating a personal QR code is straightforward:
    Choose a QR Code Generator: Use an online QR code generator like QRcodebrew.  <br/>
    1: Select the Content: Decide what information you want to encode, such as contact details, website links, or event information. <br/>
    2: Input Information: Enter the relevant data in the generator's fields. <br/>
    3: Customize Design (Optional): Some generators allow you to customize the QR code's appearance with colors or logos. <br/>
    4: Generate the QR Code: Click the "Generate" or "Create" button to generate your QR code.<br/>
    5: Save or Download: Save the QR code image to your device. <br/>
    Your personal QR code is now ready to be shared or printed for various purposes.
    `,
  },
  {
    id: 2,
    Ques: "How do I print a personal QR code?",
    Ans: `The three vertical dots will be in the top right corner of the screen when viewing the QR code. If you tap the icon, a menu will appear with options. Alternatively, some apps have a "Print" icon that looks like a printer for easy access. If you don't see a print option in the menu, look for this icon.
    `,
  },
  {
    id: 3,
    Ques: "What information can I share with a personal QR Code ?",
    Ans: `<a href="https://www.qrcodebrew.com/help" target="_blank">QR codes </a> offer solutions for a variety of digital purposes. It's more like a library filled with possibilities than an actual book. Each QR code is designed to simplify the sharing of information. <br/>

    You can use a personal QR code to share almost any information. This includes your social media accounts, impressive work you want to showcase on a resume or even your medical history. QR codes are a secure and user-friendly method to store and access all of your essential information whenever you need it
`,
  },

];
const FreeGenerator = [
  {
    id: 1,
    Ques: "Can I use the generated QR Codes for commercial purposes?",
    Ans: `Yes, you can use generated QR codes for commercial purposes, such as marketing campaigns, product packaging, or promotions, as long as you have the necessary rights to the content linked to the QR code
    `,
  },
  {
    id: 2,
    Ques: "Are the created qr codes expiring?",
    Ans: `QR codes themselves do not expire. However, the content they link to, such as a website or a document, may expire or become unavailable if you don't maintain it. It's important to ensure the linked content remains accessible.
    `,
  },
  {
    id: 3,
    Ques: "Is there a scan limit for the QR codes",
    Ans: `There is typically no inherent scan limit for QR codes. QR codes can be scanned an unlimited number of times as long as they are in good condition and the linked content is available.

`,
  },

];
const DynamicQrCode = [
  {
    id: 1,
    Ques: "Can QR codes be dynamic?",
    Ans: `Yes, QR codes can be dynamic. Dynamic QR codes allow you to change the linked content or destination without altering the QR code itself.
    `,
  },
  {
    id: 2,
    Ques: "Can Google create dynamic QR code?",
    Ans: `Google and various online QR code generators can create dynamic QR codes that enable you to update the linked information, such as a URL or contact details, as needed.
    `,
  },
  {
    id: 3,
    Ques: "Can I change a static QR code to dynamic?",
    Ans: `You cannot directly change a static QR code to dynamic. To make a QR code dynamic, you need to use a dynamic QR code generator from the beginning or create a new dynamic QR code with the desired changes.
`,
  },
  {
    id: 4,
    Ques: "Can I delete a dynamic QR code?",
    Ans: `It is possible to delete dynamic QR codes from your dashboard if they have been scanned fewer than eight times. However, once a dynamic code has been scanned eight times or more, it cannot be deleted.
`,
  },
  {
    id: 5,
    Ques: "How many times can my dynamic QR be scanned?",
    Ans: `There is no limit to the number of times users can scan your dynamic QR codes, even after your subscription expires.
    `,
  },

];
const ProductData = [
  {
    id: 1,
    Ques: "Can you put a QR code on a package?",
    Ans: `Yes, you can put a QR code on a package to provide customers with easy access to information or digital content related to the product.

    `,
  },
  {
    id: 2,
    Ques: "How do I create a QR code for product packaging?    ",
    Ans: `To create a QR code for product packaging, you can use a QR code generator tool or software. Input the desired information or link, customise the QR code, and then download the generated QR code image.
    `,
  },
  {
    id: 3,
    Ques: "Should I put a QR code on my packaging?    ",
    Ans: `Whether you should put a QR code on your packaging depends on your marketing and customer engagement strategy. It can be a useful tool for sharing product details, promotions, or additional information with consumers.

    `,
  },
  {
    id: 4,
    Ques: "Where do you put the QR code on packaging?",
    Ans: `The placement of the QR code on packaging can vary, but it's typically located in a visible and easily scannable area, such as the back, front, or side of the package, where customers can scan it with their smartphones or other devices.
    `,
  },
 

];
const EducationData = [
  {
    id: 1,
    Ques: "How are QR codes used in education",
    Ans: `QR codes are used in education to quickly and easily share information with students, such as links to websites, online resources, or class materials. They can also be used for interactive activities like scavenger hunts or quizzes.`,
  },
  {
    id: 2,
    Ques: "How do you scan QR codes for school?",
    Ans: `To scan QR codes for school, you can use a smartphone or tablet with a built-in camera. Simply open a QR code scanner app or your device's camera app, point it at the QR code, and it will automatically detect and provide the relevant information or link    `,
  },
  {
    id: 3,
    Ques: "What are the advantages of QR code to the students?",
    Ans: `The advantages of QR codes for students include easy access to digital content, convenience in accessing resources, engagement in interactive learning activities, and the ability to save time by quickly accessing information.
    `,
  },
  {
    id: 4,
    Ques: "What are the purposes of QR code?",
    Ans: `The purposes of QR codes are diverse and include information sharing, marketing, tracking products, making payments, accessing websites, providing contact details, and enhancing interactive learning experiences in education
    `,
  },
 

];

export const KnowAbout = ({ faq }) => {
  const [expanded, setExpanded] = useState(1);
  const location = useLocation();
  const [faqData, setFaqData] = useState(data);

  useEffect(() => {
    if (location.pathname === "/qr-code-business-card") {
      setFaqData(FaqBusinessData);
    } else if (location.pathname === "/qr-code-digital-media") {
      setFaqData(DigitalMediaData);
    } else if (location.pathname === "/qr-code-personal-use") {
      setFaqData(PersonalUse);
    } else if (location.pathname === "/qr-code-free-with-logo") {
      setFaqData(FreeGenerator);
    } else if (location.pathname === "/qr-code-dynamic") {
      setFaqData(DynamicQrCode);
    }else if (location.pathname === "/qr-code-product-packaging") {
      setFaqData(ProductData);
    }else if (location.pathname === "/qr-code-educational-institutions") {
      setFaqData(EducationData);
    }  else {
      setFaqData(data);
    }
  }, [location]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="KnowAboutContainer">
      <div className="KnowAboutWrapper">
        <div className="KnowAboutcont">
          {faq ? (
            <h2
              style={{
                fontWeight: 600,
                textAlign: "start",
                fontSize: "28px",
                marginLeft: "1em",
              }}
            >
              FAQ
            </h2>
          ) : (
            <>
              <div className="KnowAboutheading">
                What should you know about QR Codes?
              </div>
              <h3 className="KnowAboutdesc">
                Create, customize, and edit all of your QR codes in one
                location.
              </h3>
            </>
          )}
          <div className="KnowAbout--itemContainer container">
            <div className="KnowAbout--left">
              {faqData?.map((item) => {
                return (
                  <Accordion
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                        }}
                      >
                        {item.Ques}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        style={{
                          lineHeight: "27px",
                          fontSize: "16px",
                          color: "gray",
                        }}
                        className="KnowAbout--ans"
                        dangerouslySetInnerHTML={{
                          __html: item?.Ans,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
            <div className="KnowAbout--right">
              <div className="KnowAboutdiv">
                <img  loading="lazy"  
                  className="KnowAboutimg"
                  src={bottom}
                  alt="QR Code for payment generator"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
