import { GET_USER_INFO, USER_INFO } from "./userType";
import { userRequest } from "../../requestMethod";
export const GetUserInfo = () => ({
  type: GET_USER_INFO,
});
export const StoreUserInfo = (user) => ({
  type: USER_INFO,
  payload: user,
});

export const UserInfo = (id) => {
  return async (dispatch) => {
    await dispatch(GetUserInfo());
    try {
      const res = await userRequest.get(`/user/${id}`);
      const user = res.data;
      await dispatch(StoreUserInfo(user.user));
    } catch (error) {
      console.log(error);
    }
  };
};
