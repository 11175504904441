import { useState, useRef } from "react";
import "./Business.scss";
import mail from "../../../asset/mail.svg";
import phone from "../../../asset/phone.svg";
import { Avatar } from "@mui/material";
import LocalPhoneSharpIcon from "@mui/icons-material/LocalPhoneSharp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import businessImage from "../../../asset/dynamicCard/BusinessImage.png";
import { useSelector } from "react-redux";
import { BusinessPopUP } from "../../../Component/ModalComponent/ModalComponent";

export const Business = ({ inputs }) => {
    const { dynamicColor, textColor, buttonColor,  imgURL } =
        useSelector((state) => state.url);
    console.log(inputs);
    const [isShow, setIsShow] = useState(false);
    const [popupOptions, setPopupOtions] = useState({
        title: "",
        description: "",
        icon: "",
        callback: "",
    });
    const { time } = useSelector((state) => state.url);
    console.log(time);
    const dialNumber = () => {
        window.location = `tel:${inputs?.mobileNumber}`;
    };
    const sentMail = () => {
        window.location = `mailto:${inputs?.email}`;
    };
    const containerRef = useRef();

    return (
        <div className="businessContainer" ref={containerRef}>
            <div className="businessWrapper">
                <div
                    className="top"
                    style={{
                        backgroundColor:
                            dynamicColor?.color1 ||
                            inputs?.BGcolor ||
                            "#3d86eb",
                    }}
                >
                    <div
                        className="heading"
                        style={{
                            backgroundColor:
                                dynamicColor?.color2 ||
                                inputs?.color ||
                                "#ffffff",
                            color:
                                textColor?.text ||
                                inputs?.colorPrimary ||
                                "#000000",
                        }}
                    >
                        {!inputs?.company ? "AK Collection" : inputs?.company}
                    </div>
                    <div className="imageContainer">
                        <img  loading="lazy"  
                            src={imgURL || inputs?.imageURL[0] || businessImage}
                            alt=""
                        />
                    </div>
                    <div
                        className="about"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <div style={{ display: "flex" }}>
                            <div className="logo">
                                <Avatar
                                    alt="Remy Sharp"
                                    src={
                                        imgURL ||
                                        inputs?.imageURL[0] ||
                                        businessImage
                                    }
                                />
                            </div>
                            <div className="detailContainer">
                                <div
                                    className="d1"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#fff",
                                    }}
                                >
                                    {!inputs?.headline
                                        ? "Buy Quality Products"
                                        : inputs?.headline}
                                </div>
                                <div
                                    className="d2"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#fff",
                                    }}
                                >
                                    {!inputs?.summary
                                        ? "we aim to provide quality and best cloth for our all customers."
                                        : inputs?.summary}
                                </div>
                            </div>
                        </div>

                        <div className="button1 d-flex justify-content-center ">
                            <button
                                className="get-ticket-btn"
                                style={{
                                    backgroundColor:
                                        buttonColor?.button ||
                                        inputs?.BGcolor ||
                                        "#fff",
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "#3d86eb",
                                    border: "none",
                                    padding: "10px",
                                    borderRadius: "8px",
                                }}
                                onClick={() => {
                                    window.open(
                                        inputs?.button?.link.includes("http")
                                            ? `${inputs?.button?.link}`
                                            : `https://${inputs?.button?.link}/`,
                                        "_blank"
                                    );
                                }}
                            >
                                {inputs?.button?.name || "Get Tickets"}
                            </button>
                        </div>
                    </div>
                    <div
                        className="iconContainer"
                        style={{ borderBottom: "1px solid white", margin: 0 }}
                    >
                        <div
                            className="icon"
                            style={{
                                backgroundColor:
                                    buttonColor?.button ||
                                    inputs?.button ||
                                    "#fff",
                            }}
                        >
                            <LocalPhoneSharpIcon
                                onClick={dialNumber}
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "",
                                }}
                            />
                        </div>

                        <a
                            href={`https://www.google.com/maps/place/${inputs?.streetAddress} + ${inputs?.cityAddress}+${inputs?.stateAddress}+${inputs?.zipAddress}+${inputs?.countryAddress}`}
                        >
                            <div
                                className="icon"
                                style={{
                                    backgroundColor:
                                        buttonColor?.button ||
                                        inputs?.button ||
                                        "#fff",
                                    cursor: "pointer",
                                }}
                            >
                                <LocationOnIcon
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "",
                                    }}
                                />
                            </div>
                        </a>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                    >
                        {inputs?.socialLinks?.map((item) => (
                            <div
                                className="icon-div"
                                onClick={() => window.open(item?.url)}
                                style={{
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                    margin: "10px",
                                }}
                            >
                                {item.name === "facebook" && (
                                    <i className="fab fa-facebook socialIcon-size" />
                                )}
                                {item.name === "tiktok" && (
                                    <i className="fab fa-tiktok socialIcon-size" />
                                )}
                                {item.name === "telegram" && (
                                    <i className="fab fa-telegram socialIcon-size" />
                                )}
                                {item.name === "yelp" && (
                                    <i className="fab fa-yelp socialIcon-size" />
                                )}
                                {item.name === "google" && (
                                    <i className="fab fa-google socialIcon-size" />
                                )}
                                {item.name === "github" && (
                                    <i className="fab fa-github socialIcon-size" />
                                )}
                                {item.name === "spotify" && (
                                    <i className="fab fa-spotify socialIcon-size" />
                                )}
                                {item.name === "pinterest" && (
                                    <i className="fab fa-pinterest socialIcon-size" />
                                )}
                                {item.name === "xing" && (
                                    <i className="fab fa-xing socialIcon-size" />
                                )}
                                {item.name === "vimeo" && (
                                    <i className="fab fa-vimeo-v socialIcon-size" />
                                )}
                                {item.name === "reddit" && (
                                    <i className="fab fa-reddit socialIcon-size" />
                                )}
                                {item.name === "instagram" && (
                                    <i class="fab fa-instagram socialIcon-size" />
                                )}
                                {item.name === "twitter" && (
                                    <i class="fab fa-twitter socialIcon-size" />
                                )}
                                {item.name === "whatsapp" && (
                                    <i class="fab fa-whatsapp socialIcon-size" />
                                )}
                                {item.name === "linkedin" && (
                                    <i class="fab fa-linkedin socialIcon-size" />
                                )}
                                {item.name === "youtube" && (
                                    <i class="fab fa-youtube socialIcon-size" />
                                )}
                                {item.name === "applemusic" && (
                                    <i className="fab fa-applemusic socialIcon-size" />
                                )}
                                {item.name === "snapchat" && (
                                    <i className="fab fa-snapchat socialIcon-size" />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="middle"
                    style={{
                        backgroundColor:
                            dynamicColor?.color1 ||
                            inputs?.BGcolor ||
                            "#3d86eb",
                    }}
                >
                    <div className="item">
                        <div className="icon">
                            <AccessTimeOutlinedIcon
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#ffffff",
                            }}
                        >
                            <div className="heading-bussiness mb-4">
                                Opening hours
                            </div>

                            <div className="dayContainer">
                                {inputs?.openingHours?.length > 0 &&
                                    inputs?.openingHours?.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="day">
                                                    {item?.day}
                                                </div>
                                                <div className="time">
                                                    {item?.openTime[0]}
                                                    {" - "}
                                                    {item?.closeTime[0]}
                                                </div>
                                            </div>
                                        );
                                    })}
                                {time.map((item) => (
                                    <>
                                        <div className="day">{item?.day}</div>
                                        <div className="time">
                                            {item?.openTime}
                                            {" - "}
                                            {item?.closeTime}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <LocationOnIcon
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#ffffff",
                            }}
                        >
                            <div
                                className="heading-bussiness"
                                style={{ marginBottom: "10px" }}
                            >
                                Location
                            </div>
                            <div className="detail">
                                {!inputs?.streetAddress
                                    ? " 4 Jagmohan mullick lane,"
                                    : inputs?.streetAddress}
                                <br />{" "}
                                {!inputs?.cityAddress
                                    ? "New Market,kolkata"
                                    : inputs?.cityAddress}
                                ,{" "}
                                {!inputs?.stateAddress
                                    ? "UP"
                                    : inputs?.stateAddress}{" "}
                                {!inputs?.zipAddress ? "" : inputs?.zipAddress},{" "}
                                {!inputs?.countryAddress
                                    ? ""
                                    : inputs?.countryAddress}
                                <div className="mt-2">
                                    <a
                                        href={`https://www.google.com/maps/place/${inputs?.streetAddress} + ${inputs?.cityAddress}+${inputs?.stateAddress}+${inputs?.zipAddress}+${inputs?.countryAddress}`}
                                        style={{
                                            textDecoration: "none",
                                            color:
                                                textColor?.text ||
                                                inputs?.colorPrimary ||
                                                "#ffffff",
                                        }}
                                        className="blue-text "
                                    >
                                        {" "}
                                        Show in map
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BusinessPopUP
                        show={isShow}
                        onHide={() => setIsShow(false)}
                        {...popupOptions}
                        ref={containerRef}
                    />
                    <div className="item" style={{ borderBottom: "none" }}>
                        <div className="icon">
                            <ContactPageRoundedIcon
                                style={{
                                    color:
                                        textColor?.text ||
                                        inputs?.colorPrimary ||
                                        "",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                color:
                                    textColor?.text ||
                                    inputs?.colorPrimary ||
                                    "#ffffff",
                            }}
                        >
                            <div className="heading-bussiness">Contact</div>
                            <div className="contactContainer">
                                <div
                                    className="option"
                                    onClick={() => {
                                        setIsShow(true);
                                        setPopupOtions({
                                            title: "Call",
                                            description:
                                                "Users can click on this button to call your mobile number.",
                                            icon: phone,
                                            callback: dialNumber,
                                        });
                                    }}
                                >
                                    <div className="icon">
                                        <LocalPhoneSharpIcon
                                            style={{
                                                color:
                                                    textColor?.text ||
                                                    inputs?.colorPrimary ||
                                                    "",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            color:
                                                textColor?.text ||
                                                inputs?.colorPrimary ||
                                                "#ffffff",
                                        }}
                                    >
                                        <div className="content heading-bussiness">
                                            {!inputs?.mobileNumber
                                                ? "+91 0000000000"
                                                : inputs?.mobileNumber}
                                        </div>
                                        <div
                                            className="info"
                                            style={{
                                                color:
                                                    textColor?.text ||
                                                    inputs?.colorPrimary ||
                                                    "#ffffff",
                                                opacity: "0.5",
                                            }}
                                        >
                                            Mobile
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="option"
                                    style={{
                                        color:
                                            textColor?.text ||
                                            inputs?.colorPrimary ||
                                            "#ffffff",
                                    }}
                                    onClick={() => {
                                        setIsShow(true);
                                        setPopupOtions({
                                            title: "Mail",
                                            description:
                                                "Users can tap this button to send you an email.",
                                            icon: mail,
                                            callback: sentMail,
                                        });
                                    }}
                                >
                                    <div className="icon">
                                        <EmailRoundedIcon
                                            style={{
                                                color:
                                                    textColor?.text ||
                                                    inputs?.colorPrimary ||
                                                    "",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            color:
                                                textColor?.text ||
                                                inputs?.colorPrimary ||
                                                "#ffffff",
                                        }}
                                    >
                                        <div
                                            className="content heading-bussiness"
                                            style={{
                                                color:
                                                    textColor?.text ||
                                                    inputs?.colorPrimary ||
                                                    "#ffffff",
                                            }}
                                        >
                                            {!inputs?.email
                                                ? "abc@gmail.com"
                                                : inputs?.email}
                                        </div>
                                        <div
                                            className="info"
                                            style={{
                                                color:
                                                    textColor?.text ||
                                                    inputs?.colorPrimary ||
                                                    "#ffffff",
                                                opacity: "0.5",
                                            }}
                                        >
                                            Email
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
