import {
  LOGIN_BY,
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_START,
  REGISTER_SUCCESS,
  GET_USER_INFO,
  USER_INFO,
} from "./userType";
const initialUserState = {
  currentUserId: "",
  loggedBy: "",
  isLoading: false,
  error: false,
  errorMessage: "",
  userDetails: {},
  message: "",
};

const userReducer = (state = initialUserState, action) => {
  // alert(action.type);
  switch (action.type) {
    case REGISTER_START:
    case LOGIN_START:
      return {
        ...state,
        currentUserId: null,
        isLoading: true,
        error: false,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        currentUserId: action.payload,
        isLoading: false,
        error: false,
      };
    case GET_USER_INFO:
    case USER_INFO: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case "REGISTER_IN_PROGRESS": {
      return {
        ...state,
        userDetails: action.payload,
        isLoading: false,
        error: false,
      };
    }

    case LOGIN_BY:
      return {
        ...state,
        loggedBy: action.payload,
        isLoading: false,
        error: false,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
      return {
        ...state,
        currentUserId: null,
        isLoading: false,
        error: true,
        errorMessage: action.payload,
      };

    case LOGOUT_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        currentUserId: null,
        isLoading: false,
        error: false,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case "REGISTER_FAIL_MESSAGE": {
      return {
        ...state,
        message: action.payload.message,
      };
    }
    case "CLEAR_ERROR": {
      return {
        ...state,
        errorMessage: "",
        userDetails: {},
        currentUserId: "",
        loggedBy: "",
      };
    }

    default:
      return state;
  }
};

export default userReducer;
