import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { LOGOUT_FAIL, LOGOUT_START, LOGOUT_SUCCESS } from "./userType";

export const logoutStart = () => ({
    type: LOGOUT_START,
});
export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutFail = () => ({
    type: LOGOUT_FAIL,
});

export const logout = () => {
    return async (dispatch) => {
        await dispatch(logoutStart());
        try {
            localStorage.removeItem("accessToken");
            localStorage.clear();
            toast.success("Logout Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            dispatch(logoutSuccess());
        } catch (error) {
            dispatch(logoutFail());
        }
    };
};
