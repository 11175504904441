import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import logo1 from "../../asset/logoBig.png";
import googleLogo from "../../asset/Google.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./login.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
    auth,
    googleAuthProvider,
    facebookAuthProvider,
    twitterAuthProvider,
} from "../../firebase/firebase";
// import { useGoogleLogin } from "@react-oauth/google";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    TwitterAuthProvider,
} from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
    clearError,
    login,
    loginSuccess,
    loginUserData,
} from "../../redux/exportAllAction";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { Helmet } from "react-helmet";

export const Login = () => {
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showIcon, setShowIcon] = useState(true);
    const from = location.state?.from?.pathname || "/user/home";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.user);
    const { isLoading } = userState;

    const [inputOtp, setInputOtp] = useState({
        id: "",
        isTrue: false,
    });

    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(null);

    const { userDetails } = useSelector((state) => state.user);

    const handleClick = () => {
        setShowPassword(!showPassword);
        setShowIcon(!showIcon);
    };
    const { loggedBy } = useSelector((state) => state.user);

    const handleLogin = async (e) => {
        e.preventDefault();
        const userDetail = {
            email: email,
            password: password,
        };
        dispatch(login(userDetail, "basic", setInputOtp));
    };

    useEffect(() => {
        if (loggedBy === "Individual") {
            localStorage.getItem("plan")
                ? navigate("/pricing")
                : navigate(from, { replace: true });
        }
        if (loggedBy === "Company") {
            navigate(from, { replace: true });
        }
        if (loggedBy === "Admin") {
            navigate("/admin");
        }
        if (loggedBy === "Subadmin") {
            navigate("/admin");
        }
    }, [loggedBy]);

    // const handleFacebookLogin = () => {
    //     signInWithPopup(auth, facebookAuthProvider)
    //         .then((result) => {
    //             const credential =
    //                 FacebookAuthProvider.credentialFromResult(result);

    //             const user = result.user;
    //             console.log(user);
    //             dispatch(login(user.accessToken, "social", setInputOtp));
    //             navigate(from, { replace: true });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    // const handleTwitterLogin = () => {
    //     signInWithPopup(auth, twitterAuthProvider)
    //         .then((result) => {
    //             const credential =
    //                 TwitterAuthProvider.credentialFromResult(result);

    //             const user = result.user;
    //             console.log(user);
    //             dispatch(login(user.accessToken, "social", setInputOtp));
    //             navigate(from, { replace: true });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    const handleGoogleLogin = () => {
        console.log("google");
        console.log(googleAuthProvider);
        signInWithPopup(auth, googleAuthProvider)
            .then((result) => {
                const credential =
                    GoogleAuthProvider.credentialFromResult(result);
                console.log(result);
                const user = result.user;
                console.log(user);
                dispatch(login(user.accessToken, "social", setInputOtp));
                console.log(from);
                navigate(from, { replace: true });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // const handleGoogleLogin = useGoogleLogin({
    //     onSuccess: (codeResponse) => {
    //         console.log(codeResponse);
    //         dispatch(login(codeResponse.access_token, "social", setInputOtp));
    //         navigate(from, { replace: true });
    //     },
    //     onError: (error) => console.log("Login Failed:", error),
    // });

    const resendOtpHandler = async () => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/resend-code`,
                {
                    id: userDetails.id,
                }
            );
            
            toast.success("OTP Successfully Sent, Please Check Your Email", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            toast.error("OTP Sent Failed! Try Again Later", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const verifyOtp = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/signup-verification`,
                {
                    id: inputOtp.id,
                    code: Number(otp),
                }
            );
            toast.success("Registered Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setInputOtp(false);
            setLoading(false);
            setOtp("");
            localStorage.setItem("accessToken", verifyOtp?.data?.token);
            localStorage.setItem("userId", verifyOtp?.data?.id);
            await dispatch(loginSuccess(verifyOtp?.data?.id));
            await dispatch(loggedBy(verifyOtp?.data?.role));
            await dispatch(loginUserData(verifyOtp?.data?.user));
        } catch (error) {
            toast.error(error?.response.data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const responseMessage = (response) => {
    //     console.log(response);
    // };
    // const errorMessage = (error) => {
    //     console.log(error);
    // };

    return (
        <div className="loginContainer">
            <Helmet>
                <title>Login - QRcodebrew</title>
                <meta
                    name="description"
                    content="Sign in to your account and experience our easy-to-use, customizable QR code generation services. Simplify your digital needs with QRcodebrew."
                />
                <meta name="keywords" content="Login - QR Code Generator PRO" />
                <meta name="theme-color" content="#008f68" />
                <link rel="canonical" href="https://www.qrcodebrew.com/login" />
            </Helmet>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="loginWrapper">
                <div className="top">
                    <div className="logoContainer">
                        <div
                            className="d-flex justify-content-center mb-4"
                            style={{ margin: "0 auto" }}
                        >
                            <Link to="/" className="text-link">
                                <img  loading="lazy"   src={logo1} alt="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="loginWrapper1">
                        <div>
                            <h1
                                className="desc"
                                style={{
                                    fontSize: "30px",
                                    fontWeight: "700",
                                    marginTop: "10px",
                                    marginBottom: "20px",
                                }}
                            >
                                Login
                            </h1>
                        </div>
                        <div className="login-middle">
                            {!inputOtp.isTrue ? (
                                <form
                                    className="login-form"
                                    onSubmit={(e) => handleLogin(e)}
                                >
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        required="true"
                                    />
                                    <div className="position-relative">
                                        <input
                                            // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                            minLength="6"
                                            maxLength="16"
                                            style={{
                                                marginBottom: "0",
                                                width: "100%",
                                            }}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="Password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            required="true"
                                        />
                                        <span
                                            className="position-absolute"
                                            style={{
                                                top: "10px",
                                                right: "10px",
                                            }}
                                        >
                                            {!showIcon ? (
                                                <AiOutlineEye
                                                    size="25"
                                                    color="#53a697"
                                                    onClick={() =>
                                                        handleClick()
                                                    }
                                                />
                                            ) : (
                                                <AiOutlineEyeInvisible
                                                    size="25"
                                                    color="#53a697"
                                                    onClick={() =>
                                                        handleClick()
                                                    }
                                                />
                                            )}
                                        </span>
                                    </div>
                                    <Link to="/forget-password">
                                        <div className="forget">
                                            <a
                                                href="#"
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                Forgot Password?
                                            </a>
                                        </div>
                                    </Link>
                                    {isLoading ? (
                                        <button id="loginLOading" type="submit">
                                            <CircularProgress size="20px" />
                                        </button>
                                    ) : (
                                        <button id="login" type="submit">
                                            Login
                                        </button>
                                    )}
                                </form>
                            ) : (
                                <form
                                    onSubmit={(e) => verifyOtp(e)}
                                    className="signup-form"
                                >
                                    <input
                                        minLength="4"
                                        type="number"
                                        min="0"
                                        style={{ marginBottom: "5px" }}
                                        placeholder="Enter OTP"
                                        required="true"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                    <span
                                        onClick={resendOtpHandler}
                                        style={{
                                            marginBottom: "30px",
                                            color: "#db9d48",
                                            textAlign: "right",
                                            cursor: "pointer",
                                        }}
                                        id="resendOtpLoadingLogin"
                                    >
                                        Resend OTP
                                    </span>
                                    {loading ? (
                                        <button
                                            id="verifyOtpLoadingLogin"
                                            type="submit"
                                        >
                                            <CircularProgress size="20px" />
                                        </button>
                                    ) : (
                                        <button
                                            id="verifyOtpLogin"
                                            type="submit"
                                        >
                                            Verify OTP
                                        </button>
                                    )}
                                </form>
                            )}
                        </div>
                        <div className="or">
                            <span></span>
                            &nbsp;OR&nbsp;
                            <span></span>
                        </div>
                        <div className="bottom">
                            <div
                                className="iconContainer"
                                style={{ justifyContent: "space-between" }}
                            >
                                {/* <div
                                    className="icon icon-facebook"
                                    id="facebookLoginLogin"
                                    onClick={handleFacebookLogin}
                                >
                                    <i className="fab fa-facebook"></i>
                                </div> */}
                                <div onClick={handleGoogleLogin} className=" icon-google">
                                    <div className="googleBox">
                                        <img  loading="lazy"   src={googleLogo} alt="google" />
                                    </div>

                                    <p>Sign In With Google</p>
                                </div>
                                {/* <div
                                    className="icon icon-twitter"
                                    id="twitterLoginLogin"
                                    onClick={handleTwitterLogin}
                                >
                                    <i className="fab fa-twitter"></i>
                                </div> */}
                            </div>
                            <div
                                className="new-account-link mb-2"
                                onClick={() => dispatch(clearError())}
                            >
                                Don't have a account,{" "}
                                <Link id="signUp_login" to="/signUp">
                                    {" "}
                                    Sign Up
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
