import { useEffect, useState } from "react";
import logo1 from "../../asset/logoBig.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./signup.scss";
import googleLogo from "../../asset/Google.webp";
import { useDispatch, useSelector } from "react-redux";
import {
    auth,
    googleAuthProvider,
    // facebookAuthProvider,
    // twitterAuthProvider,
} from "../../firebase/firebase";
import {
    // FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    // TwitterAuthProvider,
} from "firebase/auth";

import {
    clearError,
    loggedBy,
    loginSuccess,
    loginUserData,
    register,
} from "../../redux/exportAllAction";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

export const Signup = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showIcon, setShowIcon] = useState(false);
    const [name, setName] = useState("");
    const [otp, setOtp] = useState(null);
    const [inputOtp, setInputOtp] = useState(false);
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.user);
    const from = location.state?.from?.pathname || state?.path || "/user/home";
    const { isLoading } = userState;
    const [loading, setLoading] = useState(false);
    const { userDetails } = useSelector((state) => state.user);

    const handleLogin = async (e) => {
        e.preventDefault();
        const userDetail = {
            email: email,
            password: password,
            name: name,
        };
        dispatch(register(userDetail, "basic", setInputOtp));
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const verifyOtp = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/signup-verification`,
                {
                    id: userDetails.id,
                    code: Number(otp),
                }
            );
            console.log(verifyOtp);
            if (verifyOtp?.data?.message === "Register Successfully") {
                toast.success("Register Successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                localStorage.setItem("accessToken", verifyOtp?.data?.token);
                localStorage.setItem("userId", verifyOtp?.data?.id);
                dispatch(loginSuccess(verifyOtp?.data?.id));
                dispatch(loggedBy(verifyOtp?.data?.role));
                dispatch(loginUserData(verifyOtp?.data?.user));

                setInputOtp(false);
            }
            setLoading(false);
        } catch (error) {
            toast.error(error?.response.data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setLoading(false);
    };

    const resendOtpHandler = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/resend-code`,
                {
                    id: userDetails.id,
                }
            );
            toast.success("OTP Successfully Sent, Please Check Your Email", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            toast.error("OTP Sent Failed! Try Again Later", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    // const handleFacebookLogin = () => {
    //     signInWithPopup(auth, facebookAuthProvider)
    //         .then(async (result) => {
    //             const user = result.user;
    //             const credential =
    //                 FacebookAuthProvider.credentialFromResult(result);
    //             const accessToken = credential.accessToken;
    //             await dispatch(
    //                 register(user.accessToken, "social", setInputOtp)
    //             );
    //             navigate(from, { replace: true });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    // const handleTwitterLogin = () => {
    //     signInWithPopup(auth, twitterAuthProvider)
    //         .then((result) => {
    //             const credential =
    //                 TwitterAuthProvider.credentialFromResult(result);

    //             const user = result.user;
    //             dispatch(register(user.accessToken, "social", setInputOtp));
    //             navigate(from, { replace: true });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    const handleGoogleLogin = () => {
        signInWithPopup(auth, googleAuthProvider)
            .then((result) => {
                const credential =
                    GoogleAuthProvider.credentialFromResult(result);

                const user = result.user;
                dispatch(
                    register(
                        { token: user.accessToken, photo: user?.photoURL },
                        "social",
                        setInputOtp
                    )
                );
                console.log(from);
                navigate(from, { replace: true });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleClick = () => {
        setShowIcon(!showIcon);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="signupContainer">
            <Helmet>
                <title>Sign Up - QRcodebrew</title>
                <meta
                    name="description"
                    content="Join our platform to create innovative QR codes. Enhance your digital presence with our easy-to-use QR code generation services. Register now!"
                />
                <meta
                    name="keywords"
                    content="Register - QR Code Generator PRO"
                />
                <meta name="theme-color" content="#008f68" />
                <link
                    rel="canonical"
                    href="https://www.qrcodebrew.com/signUp"
                />
            </Helmet>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="signupWrapper">
                <div className="top">
                    <div className="logoContainer mb-5">
                        <Link to="/" className="text-link">
                            <img  loading="lazy"   src={logo1} alt="." />
                        </Link>
                    </div>
                    <div className="signUpWrapper1">
                        <div>
                            <h1
                                className="desc"
                                style={{
                                    fontWeight: "700",
                                    height: "50px",
                                    marginTop: "5px",
                                }}
                            >
                                Join to create, edit & manage <br /> your
                                <span
                                    style={{
                                        color: "#53A697",
                                        fontSize: "25px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {" "}
                                    QR Code
                                </span>
                            </h1>
                        </div>
                        <div className="login-middle">
                            {!inputOtp ? (
                                <form
                                    onSubmit={(e) => handleLogin(e)}
                                    className="signup-form"
                                >
                                    <input
                                        minLength="3"
                                        type="text"
                                        placeholder="Name"
                                        required="true"
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        required="true"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <div className="position-relative">
                                        <input
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                            minLength="8"
                                            className="w-100"
                                            maxLength="16"
                                            type={
                                                showIcon ? "text" : "password"
                                            }
                                            required="true"
                                            placeholder="Password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                        <span
                                            className="position-absolute"
                                            style={{
                                                top: "10px",
                                                right: "10px",
                                                zIndex: 10,
                                                cursor: "pointer",
                                            }}
                                        >
                                            {showIcon ? (
                                                <AiOutlineEye
                                                    size="25"
                                                    color="#53a697"
                                                    onClick={() =>
                                                        handleClick()
                                                    }
                                                />
                                            ) : (
                                                <AiOutlineEyeInvisible
                                                    size="25"
                                                    color="#53a697"
                                                    onClick={() =>
                                                        handleClick()
                                                    }
                                                />
                                            )}
                                        </span>
                                    </div>
                                    {isLoading ? (
                                        <button type="submit">
                                            <CircularProgress size="20px" />
                                        </button>
                                    ) : (
                                        <button id="signUp" type="submit">
                                            Sign Up
                                        </button>
                                    )}
                                </form>
                            ) : (
                                <form
                                    onSubmit={(e) => verifyOtp(e)}
                                    className="signup-form"
                                >
                                    <input
                                        minLength="4"
                                        type="number"
                                        style={{ marginBottom: "5px" }}
                                        min="0"
                                        placeholder="Enter OTP"
                                        required="true"
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                    <span
                                        onClick={resendOtpHandler}
                                        id="resendOtpLoadingSignUp"
                                        style={{
                                            marginBottom: "30px",
                                            color: "#db9d48",
                                            textAlign: "right",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Resend OTP
                                    </span>
                                    {loading ? (
                                        <button
                                            id="verifyOtpLoadingSignUp"
                                            type="submit"
                                        >
                                            <CircularProgress size="20px" />
                                        </button>
                                    ) : (
                                        <button
                                            id="verifyOtpSignUp"
                                            type="submit"
                                        >
                                            Verify OTP
                                        </button>
                                    )}
                                </form>
                            )}
                        </div>
                        <div className="or">
                            <span></span>
                            &nbsp;OR&nbsp;
                            <span></span>
                        </div>
                        <div className="bottom">
                            <div className="iconContainer">
                                {/* <div className="icon icon-facebook">
                                    <i
                                        className="fab fa-facebook"
                                        id="facebookLoginSignup"
                                        onClick={handleFacebookLogin}
                                    ></i>
                                </div> */}
                                <div
                                    onClick={handleGoogleLogin}
                                    className=" icon-google"
                                >
                                    <div className="googleBox">
                                        <img  loading="lazy"   src={googleLogo} alt="google" />
                                    </div>

                                    <p>Sign In With Google</p>
                                </div>
                                {/* <div className="icon icon-twitter">
                                    <i
                                        className="fab fa-twitter"
                                        id="twitterLoginSignup"
                                        onClick={handleTwitterLogin}
                                    ></i>
                                </div> */}
                            </div>
                            <div
                                className="mb-2"
                                onClick={() => dispatch(clearError())}
                            >
                                Already have a account,{" "}
                                <Link id="login_signUp" to="/login">
                                    {" "}
                                    Login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
