import { useState } from "react";
import { useDispatch } from "react-redux";
import { setColorBg } from "../../redux/QrURL/qraction";
import "./ColorPlates.css";
const ColorPlates = ({ setColorPrimary, setColorSecondary }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState({
    islist1: "",
    isList2: "",
    isList3: "",
    isList4: "",
  });
  let color;


  const list1 = (color1, color2) => {
    setColorPrimary(color1);
    setColorSecondary(color2);
    setActive({
      islist1: "active-color",
      isList2: "",
      isList3: "",
      isList4: "",
    });
    color = { color1: "#608FFF", color2: "#C5FEFF" };
    dispatch(setColorBg(color));
  };
  const list2 = (color1, color2) => {
    setColorPrimary(color1);
    setColorSecondary(color2);
    setActive({
      islist1: "",
      isList2: "active-color",
      isList3: "",
      isList4: "",
    });
    color = { color1: "#FF731D", color2: "#FFE4C0" };
    dispatch(setColorBg(color));
  };
  const list3 = (color1, color2) => {
    setColorPrimary(color1);
    setColorSecondary(color2);
    setActive({
      islist1: "",
      isList2: "",
      isList3: "active-color",
      isList4: "",
    });
    color = { color1: "#D51A47", color2: "#FFB1DB" };
    dispatch(setColorBg(color));
  };
  const list4 = (color1, color2) => {
    setColorPrimary(color1);
    setColorSecondary(color2);
    setActive({
      islist1: "",
      isList2: "",
      isList3: "",
      isList4: "active-color",
    });
    color = { color1: "#B24080", color2: "#F9AA9E" };
    dispatch(setColorBg(color));
  };

  return (
    <ul className="borderbox qr_color_panel p-0">
      <li key="1" className={active.islist1}>
        <div
          className="qr_color_panel_wr"
          onClick={() => list1("#C5FEFF", "#608FFF")}
        >
          <div
            className="qr_color_panel_1"
            style={{ background: "#C5FEFF" }}
          ></div>
          <div
            className="qr_color_panel_2"
            style={{ background: "#608FFF" }}
          ></div>
        </div>
      </li>

      <li
        className={active.isList2}
        onClick={() => list2("#FFE4C0", "#FF731D")}
      >
        <div className="qr_color_panel_wr">
          <div
            className="qr_color_panel_1"
            style={{ background: "#FFE4C0" }}
          ></div>
          <div
            className="qr_color_panel_2"
            style={{ background: "#FF731D" }}
          ></div>
        </div>
      </li>

      <li
        className={active.isList3}
        onClick={() => list3("#FFB1DB", "#D51A47")}
      >
        <div className="qr_color_panel_wr">
          <div
            className="qr_color_panel_1"
            style={{ background: "#FFB1DB" }}
          ></div>
          <div
            className="qr_color_panel_2"
            style={{ background: "#D51A47" }}
          ></div>
        </div>
      </li>

      <li
        className={active.isList4}
        onClick={() => list4("#F9AA9E", "#B24080")}
      >
        <div className="qr_color_panel_wr">
          <div
            className="qr_color_panel_1"
            style={{ background: "#F9AA9E" }}
          ></div>
          <div
            className="qr_color_panel_2"
            style={{ background: "#B24080" }}
          ></div>
        </div>
      </li>
    </ul>
  );
};

export default ColorPlates;
