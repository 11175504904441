import "./footer.scss";
import logo1 from "../../asset/logo1.svg";

import { Link } from "react-router-dom";
import { publicRequest } from "../../requestMethod";
import { useEffect, useState } from "react";
export const Footer = ({ color }) => {
  const currentYear = new Date().getFullYear();
  const page = 1;
  const limit = 5;
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await publicRequest.get(
          `/blog/?page=${page}&limit=${limit}`
        );
        setBlog(res?.data?.data);
        console.log(res?.data?.data);
      } catch (error) {
        console.log("error");
      }
    };
    fetchBlogs();
  }, []);

  return (
    <>
      <div className={color ? "footerContainer color" : "footerContainer"}>
        <div className="footer--inside-container">
          <div className="footerleft">
            <div className="footerinside">
              <div className="footerLogoContainer">
                <img  loading="lazy"   src={logo1} alt="." className="footer-logo" />
              </div>
              <p className="footer-logotext">
                QR Code Generator lets you create memorable marketing campaigns
                with trackable QR Codes—designed by you.
              </p>
            </div>
            <div className="footericon-bar">
              <div className="footerheading">Connect with us</div>
              <div className="footericonContainer">
                <div className="footericon">
                  <i
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/profile.php?id=100093107334210",
                        "_blank"
                      )
                    }
                    className="fab fa-facebook"
                  ></i>
                </div>
                <div className="footericon">
                  <i
                    onClick={() =>
                      window.open("https://twitter.com/QCodebrew", "_blank")
                    }
                    className="fab fa-twitter"
                  ></i>
                </div>
                <div className="footericon">
                  <i
                    onClick={() =>
                      window.open(
                        "https://instagram.com/qrcodebrew?igshid=MzNlNGNkZWQ4Mg==",
                        "_blank"
                      )
                    }
                    className="fab fa-instagram"
                  ></i>
                </div>
                <div className="footericon">
                  <i
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/qrcodebrew/",
                        "_blank"
                      )
                    }
                    className="fab fa-linkedin"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="page-cont-footer">
            <div className="footercenter--left">
              <h3 className="footerh3">Pages</h3>
              <div className="footerinside">
                <Link className="footer--link" to="/">
                  Home
                </Link>
                <Link className="footer--link" to="/pricing">
                  Pricing
                </Link>
                <Link className="footer--link" to="/blogs">
                  Blogs
                </Link>
                <Link className="footer--link" to="/about">
                  About
                </Link>
                <Link className="footer--link" to="/help">
                  Support
                </Link>
              </div>
            </div>
            <div className="footercenter--right">
              <h3 className="footerh3">Resources</h3>
              <div className="footerinside">
                <Link className="footer--link" to="/qr-code-beginners">
                  QR Codes for Beginner's
                </Link>
                <Link className="footer--link" to="/qr-code-business-card">
                  QR Codes for Business Card
                </Link>
                <Link className="footer--link" to="/qr-code-digital-media">
                  QR Codes for Digital Media
                </Link>

                <Link className="footer--link" to="/qr-code-marketing-card">
                  QR Codes for Marketing Card
                </Link>
                <Link className="footer--link" to="/qr-code-solution-card">
                  QR Codes for Solution card
                </Link>
                <Link className="footer--link" to="/qr-code-educational-institutions">
                  QR Codes for Educational Institutes
                </Link>
                <Link className="footer--link" to="/qr-code-personal-use">
                  QR Codes for Personal Use
                </Link>
              </div>
            </div>
            <div className="footerright">
              <h3 className="footerh3">FROM OUR BLOG</h3>
              <div className="footerinside">
                {blog.map((b) => (
                  <Link
                    key={b?._id}
                    className="footer--link pointer"
                    // onClick={() => window.location.replace(`/blogs/${b?.shorturl ? b?.shorturl : b?._id}`)}
                    to={`/blogs/${b?.shorturl ? b?.shorturl : b?._id}`}
                  >
                    {b?.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            margin: "10px 0",
            fontSize: "15px",
            color: "white",
          }}
        >
          © qrcodebrew.com {currentYear},{" "}
          <Link style={{ color: "white" }} to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
    </>
  );
};
