import { useState } from "react";
import { Navbar } from "../../Component/navbar/Navbar";
import { UserNavbar } from "../../Component/user/UserNavbar";
import { Footer } from "../../Component/Footer/Footer";
import "./ThankYou.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "../../Component/AdminSidebar/AdminSidebar";
import { Helmet } from "react-helmet";

const ThankYou = () => {
  const userState = useSelector((state) => state.user);
  const currentUserId = userState.currentUserId;
  const [showSidebar, setShowSidebar] = useState(false);
  const [select, setSelect] = useState("");
  const navigate = useNavigate();

  return (
    <>
      {currentUserId ? (
        <UserNavbar
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
          admin={
            userState?.loggedBy === "Subadmin" ||
              userState?.loggedBy === "Admin"
              ? true
              : false
          }
        />
      ) : (
        <Navbar />
      )}
      <div className="userContainer">
        <Helmet>
          <title>QR Code Generator | QRcodebrew</title>
          <meta
            name="description"
            content="Get expert support for using our QR Code Generator at QRcodebrew. Create custom QR codes with ease and unlock new possibilities for your business."
          />
          <meta name="keywords" content="QR Code Generator" />
          <meta name="theme-color" content="#008f68" />
          <link rel="canonical" href="https://www.qrcodebrew.com/thank-you" />
        </Helmet>
        <div className="userWrapper">
          <div className={showSidebar ? "userLeft show" : "userLeft hide"}>
            <AdminSidebar admin={false} setSelect={setSelect} select={select} />
          </div>
          <div className="userRight">
            <div className="d-flex justify-content-center align-items-center" style={{ height: "95vh" }}>
              <div>
                <div className="mb-4 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="75"
                    height="75"
                    fill="#58a697"
                    className="bi bi-check-circle-fill text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                </div>
                <div className="text-center my-4">
                  <h1 className="my-5">Thank You for the donation!</h1>
                  <button
                    onClick={() => navigate("/")}
                    style={{ color: "white", background: "#58a697", border: "none", height: "50px" }}
                    className="btn btn-primary"
                  >
                    Back Home
                  </button>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
